/* @flow */

export type QueryValue = string | Array<string> | null;
export type QueryObject = { [string]: QueryValue };

const QUERY_START = /^[?#]/;
const ARRAY_END = /\[\]$/;

// Shorthand to make it smaller
const enc = encodeURIComponent;
const dec = decodeURIComponent;

/**
 * Parses a query-string, including the leading ?.
 */
export const parseParams = (query: string): QueryObject => {
  query = (QUERY_START.test(query) ? query.slice(1) : query);

  if (!query) {
    return {};
  }

  return query.split("&")
    .reduce((params, param) => {
      const [key, value] = param.split("=");
      const val = typeof value === "undefined" ?
        null :
        dec(String(value).replace(/\+/g, " "));
      const isArray = ARRAY_END.test(key);
      const k = dec(isArray ? key.replace(ARRAY_END, "") : key);

      if (k) {
        if (isArray && params[k]) {
          params[k] = Array.isArray(params[k]) ? params[k].concat([val]) : [params[k], val];
        }
        else {
          params[k] = val;
        }
      }

      return params;
    }, {});
};

/**
 * Converts an object to a query-string.
 */
export const stringifyParams = (object: QueryObject): string => {
  const parts = Object.keys(object).sort().map(k => {
    let val = object[k];
    const key = enc(k);

    if (Array.isArray(val)) {
      val = val.filter(i => i !== undefined);

      // Only emit brackets if we have more than one item
      if (val.length > 1) {
        return val.sort().map(i => i === null ? key + "[]=" : key + "[]=" + enc(i)).join("&");
      }

      // Flatten one level
      val = val[0];
    }

    if (val === undefined) {
      return null;
    }

    if (val === null) {
      return key + "=";
    }

    return key + "=" + enc(val);
  }).filter(x => x);

  return parts.length > 0 ? "?" + parts.join("&") : "";
};
