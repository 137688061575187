/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { CustomerResponse } from "state/customer";

import { removeExampleEmail } from "helpers/utils";
import {
  CUSTOMER_INIT_REQUEST,
  CUSTOMER_INIT_RESPONSE,
  CUSTOMER_LOGOUT_REQUEST,
  CUSTOMER_LOGOUT_RESPONSE,
} from "state/customer";

import {
  customer as customerQuery,
  logout,
} from "queries";

type History = {
  push: (path: string) => void,
};

const registerClient = (storage: Storage, client: Client<{}>, history: History) => {
  storage.addEffect({
    effect: async () => {
      try {
        const { customer } = await client(customerQuery);

        return ({
          tag: CUSTOMER_INIT_RESPONSE,
          data: removeExampleEmail(customer),
        }: CustomerResponse);
      }
      catch (e) {
        return ({
          tag: CUSTOMER_INIT_RESPONSE,
          data: undefined,
        }: CustomerResponse);
      }
    },
    subscribe: { [CUSTOMER_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({ redirectTo }: { redirectTo: string }) => {
      const { customer } = await client(logout);

      history.push(redirectTo);

      return ({
        tag: CUSTOMER_LOGOUT_RESPONSE,
        data: customer,
      }: CustomerResponse);
    },
    subscribe: { [CUSTOMER_LOGOUT_REQUEST]: true },
  });
};

export default registerClient;
