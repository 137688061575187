/* @flow */

import React from "react";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import styles from "./styles.scss";
import ChevronIcon from "icons/chevron.svg";

type Props = {
  open: boolean,
  setOpen: () => void,
  title: string,
  className?: string,
  children: React$Node,
};

const Accordion = ({ open, setOpen, title, children, className }: Props) => {
  return (
    <div className={cn(styles.block, className, { [styles.open]: open })}>
      <header className={styles.header} onClick={setOpen}>
        {title}
        <ChevronIcon />
      </header>
      <Foldable open={open} className={styles.body}>
        {children}
      </Foldable>
    </div>
  );
};

export default Accordion;
