/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { AllProductsRequest, AllProductsResponse } from "@crossroads/shop-state/all-products";

import { ALL_PRODUCTS_REQUEST, ALL_PRODUCTS_RESPONSE } from "@crossroads/shop-state/all-products";
import { products as productsQuery } from "queries";
import { PAGE_SIZE } from "./route";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: AllProductsRequest) => {
      const { products } = await client(productsQuery, {
        filter: msg.filters,
        sort: msg.sort,
        pageSize: PAGE_SIZE,
        page: msg.page !== null ? msg.page : 1,
      }, { cache: true });

      return ({
        tag: ALL_PRODUCTS_RESPONSE,
        data: {
          items: products.items,
          totalCount: products.totalCount,
          filterableBy: products.filterableBy,
          sortableBy: products.sortableBy,
        },
      }: AllProductsResponse);
    },
    subscribe: { [ALL_PRODUCTS_REQUEST]: true },
  });
};

export default registerClient;
