/* @flow */

import type { Quote, Customer } from "shop-state/types";

import { useEffect, useState } from "react";
import { pointsPriceByID } from "helpers/points";

type Error = {
  field: string,
  error: string,
};

type Category = {
  +name: string,
  +url: string,
  +products: { +totalCount: number },
};

type TopCategory = {
  +name: string,
  +url: string,
  +children: $ReadOnlyArray<Category>,
  +products: { +totalCount: number },
};

declare type DOMEvent<T> = {
  target: T,
} & Event;

export const focusInvalidField = (e: SyntheticEvent<HTMLFormElement>, errors: Array<Error>) => {
  const field = e.currentTarget.querySelector(`[name='${errors[0].field}']`);

  if (field) {
    field.focus();
  }
};

/*
export const useGetRemaining = (grandTotalPoints: number) => {
  const customerData = useData(CustomerData);

  if ((customerData.state !== "LOADED" && customerData.state !== "UPDATING") ||
  !customerData.data) {
    return { remainingPoints: null, remainingPrice: null };
  }

  const remainingPrice = (grandTotalPoints - points);

  const remainingPoints = points;

  return { remainingPoints, remainingPrice };
};
*/

export const loadScript = (isLoaded: () => boolean, src: string, cb: () => void): void => {
  if (!process.browser) {
    return;
  }

  if (isLoaded()) {
    cb();

    return;
  }

  const head = document.querySelector("head");
  const script = document.createElement("script");

  script.src = src;

  /* eslint-disable unicorn/prefer-add-event-listener */
  script.onload = () => {
    script.onload = null;

    cb();
  };
  /* eslint-enable unicorn/prefer-add-event-listener */

  if (head) {
    /* eslint-disable unicorn/prefer-dom-node-append */
    head.appendChild(script);
    /* eslint-enable unicorn/prefer-dom-node-append */
  }
};

export const useCurrentSrc = (ref: { current: ?HTMLImageElement }, defaultSrc: string): string => {
  const [image, setImage] = useState(defaultSrc);

  useEffect(() => {
    const { current } = ref;

    if (current && current.currentSrc) {
      setImage(current.currentSrc);
    }
  }, [ref]);

  return image;
};

export const isValidHex = (hex: string) => /^[0-9A-F]{6}$/i.test(hex);

export const sortCategories = (
  categories: $ReadOnlyArray<TopCategory>,
  locale: string): Array<TopCategory> => {
  return categories.slice().sort((a, b) => a.name.localeCompare(b.name, locale));
};

export const filterCategories = (
  categories: $ReadOnlyArray<TopCategory>): Array<TopCategory> => {
  return categories
    .filter(c => c.products.totalCount > 0)
    .map(c => ({ ...c, children: c.children.filter(c => c.products.totalCount > 0) }));
};

export const removeExampleEmail = <T: { email: ?string }>(object: T): T => {
  if (object && object.email && object.email.match(/@example.com$/)) {
    return {
      ...object,
      email: null,
    };
  }

  return object;
};

export const canAffordOrder = (quote?: ?Quote, customer?: ?Customer): boolean => {
  if (!quote || !customer) {
    return true;
  }

  const pointPayment = pointsPriceByID(quote.availablePointPayments, "sas_extrapoints");

  if (!pointPayment) {
    return false;
  }

  const customerPoints = !customer ? 0 : customer.sasExtraPoints;

  return pointPayment.points.min.incVat <= Math.min(pointPayment.points.max.incVat, customerPoints);
};
