/* @flow */

import type { Model, EffectErrorMessage } from "crustate";
import { updateData, updateNone } from "crustate";

type Data = | {
  state: "PENDING",
  canSendSMS: boolean,
} | {
  state: "SETTING",
  canSendSMS: boolean,
} | {
  state: "SET",
  canSendSMS: boolean,
};

export type OTPRequest = {
  tag: typeof SET_OTP_REQUEST,
  otp: string,
} | {
  tag: typeof SEND_OTP_REQUEST,
};

export type OTPResponse = {
  tag: typeof SET_OTP_RESPONSE,
} | EffectErrorMessage;

export const SEND_OTP_REQUEST: "request/send_otp" = "request/send_otp";

export const SET_OTP_RESPONSE: "response/set_otp" = "response/set_otp";
export const SET_OTP_REQUEST: "request/set_otp" = "request/set_otp";

export const RESET_OTP_REQUEST: "request/reset_otp" = "request/reset_otp";

export const SET_CAN_SEND_SMS_REQUEST: "response/can_send_sms" = "response/can_send_sms";

export const sendOTP = () => ({ tag: SEND_OTP_REQUEST });
export const setOTP = (otp: string) => ({ tag: SET_OTP_REQUEST, otp });
export const resetOTP = () => ({ tag: RESET_OTP_REQUEST });
export const setCanSendSMS = (value: boolean) => ({
  tag: SET_CAN_SEND_SMS_REQUEST,
  value,
});

export const OTPModel: Model<Data, {}, OTPRequest | OTPResponse> = {
  id: "otp",
  init: () => updateData({
    state: "PENDING",
    canSendSMS: true,
  }),
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case SET_CAN_SEND_SMS_REQUEST:
        return updateData({
          ...state,
          canSendSMS: msg.value,
        });
      case SEND_OTP_REQUEST:
        if (state.canSendSMS) {
          return updateData({
            ...state,
            state: "PENDING",
          }, msg);
        }

        return updateNone();
      case SET_OTP_REQUEST:
        return updateData({
          ...state,
          state: "SETTING",
        }, msg);
      case SET_OTP_RESPONSE:
        return updateData({
          ...state,
          state: "SET",
        });
      case RESET_OTP_REQUEST:
        return updateData({
          ...state,
          state: "PENDING",
        });
      default:
        break;
    }
  },
};
