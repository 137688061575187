/* @flow */

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Lightbox, Button, CodeInput } from "@crossroads/ui-components";
import { useTranslate } from "@awardit/react-use-translate";
import { useSendMessage, useData } from "crustate/react";
import { OTPData } from "data";
import { setOTP, sendOTP, setCanSendSMS } from "state/otp";
import { addMessage } from "@crossroads/shop-state/messages";
import { Form } from "@awardit/formaggio";

import styles from "./styles.scss";

type FormState = { otp: string };

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

const CodeInputLocal = props =>
  <CodeInput {...props} />;

const LightboxContent = () => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const [state, setState] = useState<FormState>({ otp: "" });
  const OTPState = useData(OTPData);
  const processing = OTPState.state === "SETTING";

  const submit = e => {
    e.preventDefault();
    if (state.otp.length > 0) {
      sendMessage(setOTP(state.otp));
    }
  };

  const requestNewCode = () => {
    sendMessage(addMessage("NEW_OTP_SMS_SENT", "success"));
    sendMessage(setCanSendSMS(true));
    sendMessage(sendOTP());
    sendMessage(setCanSendSMS(false));
  };

  return (
    <div className={styles.block}>
      <div className={styles.wrapper}>
        <h2>{t("OTP.TITLE")}</h2>
        <p>{t("OTP.INFO")}</p>
        <Form
          className={styles.form}
          value={(state: any)}
          onChange={x => {
            setState({ ...state, ...(x: any) });
          }}
          onSubmit={submit}
        >
          <div className={styles.codeInput}>
            <CodeInputLocal
              length={6}
              value={state.otp}
              setValue={x => setState({ otp: x })}
              boxWidth={44}
              className={styles.codeInput}
              pattern="[0-9]*"
              inputMode="numeric"
              autoComplete="one-time-code"
            />
          </div>
          <Button
            loading={processing}
            className={styles.button}
            variant="primary"
          >
            {t("OTP.SUBMIT")}
          </Button>
        </Form>

        <p className={styles.requestNewCodeTitle}>
          {t("OTP.REQUEST_NEW_CODE_TITLE")}
        </p>

        <p className={styles.requestNewCode} onClick={requestNewCode}>
          {t("OTP.REQUEST_NEW_CODE")}
        </p>

        <Link
          className={styles.customerService}
          to="/customer-service"
        >
          {t("OTP.CUSTOMER_SERVICE")}
        </Link>
      </div>
    </div>
  );
};

const OTPModal = ({ open, setOpen }: Props) => {
  const sendMessage = useSendMessage();

  // Send SMS on open
  useEffect(() => {
    if (open) {
      sendMessage(sendOTP());
      sendMessage(setCanSendSMS(false));
    }
  }, [open]);

  return (
    <Lightbox className={styles.modal} open={open} setOpen={setOpen}>
      <LightboxContent />
    </Lightbox>
  );
};

export default OTPModal;
