/* @flow */

import React, { useState, useContext } from "react";
import cn from "classnames";
import { useData } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { Button, Dialogue } from "@crossroads/ui-components";
import { StripeContext, StripeCardComponent, StripeButtonComponent } from "@crossroads/stripe";
import { CmsData } from "data";
import { CheckboxField } from "components/Field";
import CmsPageView from "components/CmsPageView";
import AmexLogo from "icons/payment_methods/amex.svg";
import MastercardLogo from "icons/payment_methods/mastercard.svg";
import VisaLogo from "icons/payment_methods/visa.svg";
import GooglePayIcon from "icons/payment_methods/google-pay.svg";
import MSPayIcon from "icons/payment_methods/ms-wallet.svg";
import ApplePayIcon from "icons/payment_methods/apple-dark.svg";
import CloseIcon from "icons/cross.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
  paymentMethodCode: string,
  processing: boolean,
  loading: boolean,
  disabled: boolean,
  terms: boolean,
  showHeader?: boolean,
  showAmexLogo?: boolean,
};

const stripeStyles = {
  style: {
    base: {
      color: styles.textColor,
      letterSpacing: "0.025em",
      fontSize: "16px",
      "::placeholder": {
        color: styles.colorMuted,
      },
    },
    invalid: {
      color: "#9e2146",
    },
  },
};

const PaymentMethods = ({
  className,
  paymentMethodCode,
  loading,
  processing,
  disabled,
  terms,
  showHeader = true,
  showAmexLogo = true,
}: Props) => {
  const t = useTranslate();
  const cmsData = useData(CmsData);
  const [showTerms, setShowTerms] = useState(false);
  const { paymentMode, setPaymentMode, canMakeButtonPayment } = useContext(StripeContext);

  const renderTerms = () => (
    <>
      {cmsData.state === "LOADED" &&
        <div className={styles.modalWrapper}>
          <Dialogue
            className={styles.modal}
            open={showTerms}
            setOpen={() => setShowTerms(false)}
          >
            <Button
              className={styles.close_button}
              onClick={() => setShowTerms(false)}
            >
              <CloseIcon />
            </Button>
            <div className={styles.container}>
              <CmsPageView cmsPage={cmsData.data} />
            </div>
          </Dialogue>
        </div>
      }

      <div className={styles.terms}>
        <CheckboxField
          id="terms"
          name="terms"
          value={terms}
        >
          <span className={styles.text}>{t("CHECKOUT.I_ACCEPT_THE")}{" "}</span>
          <span
            className={cn({ [styles.link]: cmsData.state === "LOADED" })}
            onClick={e => {
              e.preventDefault();
              setShowTerms(true);
            }}
          >
            {t("CHECKOUT.TERMS")}
          </span>
        </CheckboxField>
      </div>
    </>
  );

  if (paymentMethodCode === "free") {
    return (
      <div className={styles.block}>
        {showHeader &&
          <div className={styles.header}>
            <h2>{t("CHECKOUT.PAYMENT_TITLE")}</h2>
          </div>
        }
        <div className={styles.body}>
          {renderTerms()}
          <div className={styles.submitBlock}>
            <Button
              className={styles.button}
              variant="primary"
              loading={processing}
              disabled={disabled || !terms}
              type="submit"
            >
              {t("CHECKOUT.CONFIRM_ORDER")}
            </Button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className={cn(styles.block, className, { [styles.loading]: loading })}>
      {showHeader &&
        <div className={styles.header}>
          <h4>
            {paymentMode === "DEFAULT" ?
              t("CHECKOUT.PAYMENT_TITLE") :
              t(`CHECKOUT.${paymentMode}_PAYMENT`)
            }
          </h4>

          {paymentMode === "CARD" &&
            <Button
              className={styles.editButton}
              variant="blank"
              size="small"
              onClick={() => setPaymentMode("DEFAULT")}
            >
              <span>{t("CHECKOUT.EDIT")}</span>
            </Button>
          }
        </div>
      }
      <div>
        {renderTerms()}

        <div className={styles.submitBlock}>
          {paymentMode === "DEFAULT" &&
            <>
              <Button
                disabled={disabled || !terms}
                loading={loading}
                className={styles.button}
                variant="primary"
                onClick={() => setPaymentMode("CARD")}
              >
                {t("PAYMENT.USE_CARD")}
              </Button>

              {canMakeButtonPayment &&
                <div className={styles.orParagraph}>{t("OR")}</div>
              }

              <StripeButtonComponent
                Button={Button}
                disabled={disabled || !terms}
                processing={processing || loading}
                styles={styles}
                options={stripeStyles} />
            </>
          }

          {paymentMode === "CARD" &&
            <StripeCardComponent
              Button={Button}
              disabled={disabled || !terms}
              processing={processing || loading}
              styles={styles}
              options={stripeStyles} />
          }
        </div>

        <div className={styles.cards}>
          <div className={styles.cardsInner}>
            <div><MastercardLogo className={styles.mastercard} /></div>
            <div><VisaLogo className={styles.visa} /></div>
            {showAmexLogo &&
              <div><AmexLogo className={styles.amex} /></div>
            }
            <div><ApplePayIcon className={styles.apple} /></div>
            <div><MSPayIcon className={styles.microsoft} /></div>
            <div><GooglePayIcon className={styles.google} /></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethods;
