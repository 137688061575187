/* @flow */

export type PointsPrice = {
  // currencyRequired: boolean,
  +id: string,
  +points: {
    +value: { +incVat: number },
    +min: { +incVat: number },
    +max: { +incVat: number },
  },
  +currency: {
    +value: { +incVat: number },
    +min: { +incVat: number },
    +max: { +incVat: number },
  },
};

export const pointsPriceByID = <T: { +id: string }>(
  pointsPrices: $ReadOnlyArray<T>, id: string): T | null => {
  for (const pointPrice of pointsPrices) {
    if (pointPrice.id === id) {
      return pointPrice;
    }
  }

  return null;
};

export const isForcedSplitPayment = (points?: ?PointsPrice) => {
  if (!points) {
    return false;
  }

  return points.currency.min.incVat > 0;
};

export const isPointsOnly = (pointsPrice?: PointsPrice | null) => {
  if (!pointsPrice) {
    return false;
  }

  // TODO: check pointsRequire === true when API is updated
  const { currency } = pointsPrice;

  return currency.max.incVat === 0;
};

export const getPriceSplit = (pointPrices?: ?PointsPrice) => {
  if (!pointPrices) {
    return null;
  }

  const { points, currency } = pointPrices;
  const split = [];

  if (
    points.max.incVat >= points.value.incVat &&
    points.value.incVat > 0 && points.max.incVat > 0
  ) {
    split.push({
      type: "points",
      values: {
        points: points.value.incVat,
      },
    });
  }

  if (points.min.incVat > 0 && currency.max.incVat > 0) {
    split.push({
      type: "split",
      values: {
        points: points.min.incVat,
        currency: currency.max.incVat,
      },
    });
  }

  if (points.max.incVat > 0 && currency.min.incVat > 0) {
    split.push({
      type: "split",
      values: {
        points: points.max.incVat,
        currency: currency.min.incVat,
      },
    });
  }

  if (currency.max.incVat === currency.value.incVat && currency.max.incVat > 0) {
    split.push({
      type: "currency",
      values: {
        currency: currency.value.incVat,
      },
    });
  }

  return split;
};
