/* @flow */

import React, { useContext } from "react";
import { SuccessView as SuccessViewExt } from "@crossroads/shop-views";
import { AnalyticsContext } from "@crossroads/analytics";
import { useClient } from "entrypoint/shared";
import { OrderData } from "data";
import { useData } from "crustate/react";
import { load as loadOrder } from "state/order";
import useFormat from "helpers/use-format";
import CheckIcon from "icons/checkmark-success.svg";
import ChatIcon from "icons/chat.svg";
import PhoneIcon from "icons/phone.svg";

const SuccessView = (): React$Node => {
  const orderData = useData(OrderData);
  const { formatPrice } = useFormat();
  const gaContext = useContext(AnalyticsContext);

  return (
    <SuccessViewExt
      orderData={orderData}
      useClient={useClient}
      analytics={gaContext}
      formatPrice={formatPrice}
      loadOrder={loadOrder}
      hasVisiblePrices={false}
      additionalField="CUSTOMER_SERVICE"
      mapComponent={null}
      CheckIcon={<CheckIcon style={{ color: "#fff" }} />}
      ChatIcon={<ChatIcon style={{ height: "24px", width: "24px" }} />}
      PhoneIcon={<PhoneIcon style={{ height: "24px", width: "24px" }} />}
    />
  );
};

export default SuccessView;
