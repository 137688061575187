/* @flow */

import { useHistory } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { getQuoteData } from "state/quote";
import { useData } from "crustate/react";
import useCustomer from "helpers/use-customer";
import { QuoteData } from "data";
import { shouldShowStep2 } from "components/CheckoutView/Step2";

type Step = {
  id: string,
  key: number,
  link: string,
  title: string,
  titleLong: string,
  enabled: boolean,
};

const useCheckoutSteps = (): {
  steps: Array<Step>,
  currentStep: Step,
} => {
  const t = useTranslate();
  const history = useHistory();
  const quoteState = useData(QuoteData);
  const quote = getQuoteData(quoteState);
  const { customer } = useCustomer();
  const showStep2 = shouldShowStep2(quote, customer);
  const selectedPointPayment = quote ? quote.selectedPointPayment : null;

  const steps: Array<Step> = [
    {
      id: "cart",
      key: 1,
      link: "/checkout",
      title: t("CHECKOUT.STEPS.CART.SHORT"),
      titleLong: t("CHECKOUT.STEPS.CART.LONG"),
      enabled: true,
    },
    {
      id: "shipping",
      key: 2,
      link: "/checkout/1",
      title: t("CHECKOUT.STEPS.SHIPPING.SHORT"),
      titleLong: t("CHECKOUT.STEPS.SHIPPING.LONG"),
      enabled: true,
    },
    {
      id: "payment",
      key: 3,
      link: "/checkout/2",
      title: t("CHECKOUT.STEPS.PAYMENT.SHORT"),
      titleLong: t("CHECKOUT.STEPS.PAYMENT.LONG"),
      enabled: true,
    },
    {
      id: "overview",
      key: 4,
      link: "/checkout/3",
      title: t("CHECKOUT.STEPS.OVERVIEW.SHORT"),
      titleLong: t("CHECKOUT.STEPS.OVERVIEW.LONG"),
      enabled: Boolean(showStep2 && selectedPointPayment) || !showStep2,
    },
  ].filter((x: Step) => {
    if (x.id === "payment") {
      return showStep2;
    }

    return true;
  });
  const currentStep = steps.find(s => s.link === history.location.pathname) || {};

  return { steps, currentStep };
};

export default useCheckoutSteps;
