/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";

import styles from "./styles.scss";

const Banner = () => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <span className={styles.eurobonus}>{t("HEADER.SHOPNAME")}</span>
    </div>
  );
};

export default Banner;
