/* @flow */

import type { TransferOrder } from "shop-state/types";

import React, { useEffect, useState } from "react";
import { useTranslate } from "@awardit/react-use-translate";
import { Link } from "react-router-dom";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import useFormat from "helpers/use-format";
import { lastTransferOrder } from "queries";
import { useTransferClient } from "entrypoint/shared";
import useCustomer from "helpers/use-customer";
import CheckIcon from "icons/checkmark-success.svg";

import styles from "./styles.scss";

const Success = () => {
  const t = useTranslate();
  const { formatPoints, formatBasicPoints, formatPriceLong } = useFormat();
  const client = useTransferClient();
  const { customer } = useCustomer();
  const [orderData, setOrderData] = useState<?TransferOrder>(null);
  const cash = orderData?.grandTotal?.incVat || 0;
  const points = orderData?.selectedPointPayment?.points.incVat || 0;

  useEffect(() => {
    client(lastTransferOrder).then(response => setOrderData(response.lastOrder));
  }, []);

  if (!orderData) {
    return null;
  }

  return (
    <Wrapper>
      <h1 className={styles.title}>{t("PURCHASE_POINTS.SUCCESS.TITLE")}</h1>

      <header className={styles.header}>
        <div className={styles.mainTitle}>
          <div className={styles.checkIconBG}>
            <CheckIcon className={styles.checkIcon} />
          </div>
          <div>
            <h2 className={styles.orderNo}>
              {t("PURCHASE_POINTS.SUCCESS.ORDER_NUMBER")}: <strong>{orderData.id}</strong>
            </h2>
          </div>
        </div>
        <div>
          <Link className={styles.back} to="/">{t("SUCCESS.BACK")}</Link>
        </div>
      </header>

      <div className={styles.block}>
        <div className={styles.right}>
          <h2 className={styles.subHeading}>{t("PURCHASE_POINTS.SUCCESS.CONFIRMATION")}</h2>
          <p>
            {orderData.targetSasCustomer ? t("PURCHASE_POINTS.SUCCESS.CONFIRMATION_INFO", {
              member: `${orderData.targetSasCustomer.lastName}`,
            }) : t("PURCHASE_POINTS.SUCCESS.CONFIRMATION_INFO", {
              member: `${customer ? customer.firstname : ""} ${customer ? customer.lastname : ""}`,
            })}
          </p>
          <h2 className={styles.subHeading}>{t("PURCHASE_POINTS.SUCCESS.INFORMATION")}</h2>
          <p>{t("PURCHASE_POINTS.SUCCESS.INFORMATION_INFO")}</p>
          <h2 className={styles.subHeading}>{t("PURCHASE_POINTS.SUCCESS.CONTACT_US")}</h2>
          <p>{t("PURCHASE_POINTS.SUCCESS.CONTACT_US_INFO")}</p>

          <a target="_blank" rel="noopener noreferrer" href={t("META.HELPDESK_LINK")}><Button variant="primary">{t("PURCHASE_POINTS.SUCCESS.CUSTOMER_SERVICE")}</Button></a>
        </div>

        <div className={styles.left}>
          <h2 className={styles.subHeading}>{t("PURCHASE_POINTS.SUCCESS.ORDER_OVERVIEW")}</h2>

          <div className={styles.box}>
            <section className={styles.boxSection}>
              <h3>{t("PURCHASE_POINTS.SUCCESS.TRAVELER")}</h3>

              <div className={styles.tr}>
                <span>{t("PURCHASE_POINTS.SUCCESS.EUROBONUS_NUMBER")}</span>
                <span>
                  {
                    orderData.targetSasCustomer ?
                      orderData.targetSasCustomer.ebNo :
                      customer?.sasEbNo
                  }
                </span>
              </div>

              <div className={styles.tr}>
                <span>{t("PURCHASE_POINTS.SUCCESS.LAST_NAME")}</span>
                <span>
                  {
                    orderData.targetSasCustomer ?
                      orderData.targetSasCustomer.lastName :
                      customer?.lastname
                  }
                </span>
              </div>
            </section>
            <section className={styles.boxSection}>
              <h3>{t("PURCHASE_POINTS.SUCCESS.ORDER_CONTENTS")}</h3>

              <div className={styles.tr}>
                <span>{t("PURCHASE_POINTS.SUCCESS.PRODUCT")}</span>
                <span>
                  {orderData.item.product.sasPointsToDepositType === "Basic Points" ?
                    formatBasicPoints(
                      orderData.item.product.sasPointsToDeposit * orderData.item.qty
                    ) :
                    formatPoints(
                      orderData.item.product.sasPointsToDeposit * orderData.item.qty
                    )
                  }
                </span>
              </div>

              <div className={styles.tr}>
                <span>{t("PURCHASE_POINTS.SUCCESS.PAYMENT")}</span>
                {(cash === 0 && points > 0) &&
                  <span>{formatPoints(points)}</span>
                }
                {(cash > 0 && points === 0) &&
                  <span>{formatPriceLong(cash, orderData.currencyCode)}</span>
                }
                {(cash > 0 && points > 0) &&
                  <span>{formatPoints(points)}
                    <span className={styles.cash}>
                     &nbsp;+ {formatPriceLong(cash, orderData.currencyCode)}
                    </span>
                  </span>
                }
              </div>
            </section>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};

export default Success;
