/* @flow */

import type { ProductCardProduct, GAProduct } from "shop-state/types";
import type { BreadcrumbLink } from "@crossroads/ui-components";

import React, { useRef, useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";
import cn from "classnames";
import { pointsPriceByID, isPointsOnly } from "helpers/points";
import { AnalyticsContext, ProductLink } from "@crossroads/analytics";
import useWishlist from "helpers/use-wishlist";
import { Square } from "@crossroads/ui-components";
import { useCurrentSrc } from "helpers/utils";
import useCustomer from "helpers/use-customer";
import useFormat from "helpers/use-format";
import PriceSplit from "components/PriceSplit";
import WishlistIcon from "components/WishlistIcon";
import { formatConfigColors } from "components/ProductOptions/color";
import { getAccrual } from "helpers/accrual";

type ProductCardProps = {
  product: ProductCardProduct,
  className?: string,
  list?: string,
  position?: number,
  breadcrumbLinks?: $ReadOnlyArray<BreadcrumbLink>,
};

/* eslint-disable complexity */
const ProductCard = ({ product, className, breadcrumbLinks, position, list }: ProductCardProps) => {
  const t = useTranslate();
  const imageRef = useRef();
  const gaRef: { current: null | Element } = useRef(null);
  const gaContext = useContext(AnalyticsContext);
  // Default to smallest image
  const { tinyImage, smallImage, mediumImage, largeImage } = product.attributes;
  const defaultImage = tinyImage || smallImage || mediumImage || largeImage || { x1: "", x2: "" };
  const image = useCurrentSrc(imageRef, defaultImage.x1);
  const { loggedIn } = useCustomer();
  const { toggleWishlist, inWishlist } = useWishlist({ product });
  const { pathname } = useLocation();
  const { formatPoints } = useFormat();

  const onAdventCalendar = pathname.includes(t("ADVENT_CALENDAR.CATEGORY_URL"));
  const savePercetage = 1 - (product.price.incVat / product.originalPrice.incVat);

  // const isNew = Math.random() > 0.7;
  const isNew = false;
  const options = product.type === "configurable" ? product.options.items : [];
  const pointsPrice = pointsPriceByID(product.pointsPrices, "sas_extrapoints");
  const pointsOnly = isPointsOnly(pointsPrice);
  const brand = (product.attributeSetName === "ExternalExperience" || product.attributeSetName === "Experience") ?
    t("PRODUCT.EXTERNAL_EXPERIENCE") :
    product.attributes.manufacturer;

  const mapGaObject = (product: ProductCardProduct): GAProduct => {
    const gaObject: GAProduct = {
      item_id: product.sku, // eslint-disable-line camelcase
      item_name: product.name, // eslint-disable-line camelcase
      item_brand: product.attributes.manufacturer, // eslint-disable-line camelcase
      price: product.price.incVat,
      index: Number(position) + 1,
      item_list_name: list ?? "", // eslint-disable-line camelcase
    };

    if (product.categories === undefined || product.categories.length === 0) {
      return gaObject;
    }

    product.categories.forEach((c, i) => {
      if (i === 0) {
        gaObject.item_category = c.name; // eslint-disable-line camelcase
      }
      else {
        gaObject[`item_category${i + 1}`] = c.name;
      }
    });

    return gaObject;
  };

  useEffect(() => {
    const gaObject = mapGaObject(product);

    if (!gaRef.current) {
      return;
    }

    gaContext.register(gaRef.current, gaObject);
  }, [gaRef]);

  return (
    <ProductLink
      className={cn(styles.block, className)}
      list={list}
      position={Number(position) + 1}
      to={{
        pathname: product.url,
        state: {
          hint: {
            type: "product",
            product,
            image,
          },
          breadcrumbLinks,
          list,
          position: Number(position) + 1,
        },
      }}
      product={{
        name: product.name,
        sku: product.sku,
        price: product.price,
        qty: 1,
        attributes: {
          manufacturer: product.attributes.manufacturer,
        },
        categories: product.categories,
      }}
      innerRef={gaRef}
    >
      <div className={styles.hoverFG} />

      <div className={styles.colors}>
        {options
          .filter(x => Boolean(x.product.attrLabels.color))
          .map((x, i) => (
            <div
              key={i}
              style={{ background: `linear-gradient(${formatConfigColors(x.product.attrLabels.color?.title)})` }} />
          ))}
      </div>

      <div className={styles.header}>
        <picture className={styles.imageWrapper}>
          {largeImage &&
            <source
              srcSet={`${largeImage.x1} 1x, ${largeImage.x2} 2x`}
              media={`(min-width: ${styles.large}px)`}
            />
          }
          {mediumImage &&
            <source
              srcSet={`${mediumImage.x1} 1x, ${mediumImage.x2} 2x`}
              media={`(min-width: ${styles.medium}px)`}
            />
          }
          {smallImage &&
            <source
              srcSet={`${smallImage.x1} 1x, ${smallImage.x2} 2x`}
              media={`(min-width: ${styles.small}px)`}
            />
          }
          {tinyImage &&
            <source
              srcSet={`${tinyImage.x1} 1x, ${tinyImage.x2} 2x`}
              media="(min-width: 0px)"
            />
          }
          <img
            ref={imageRef}
            alt={product.name}
            src={defaultImage.x1}
            className={styles.image}
          />

          <Square />
        </picture>

        <div className={styles.badges}>
          {isNew && <div>{t("PRODUCT.NEW")}</div>}
          {pointsOnly && <span>{t("PRODUCT.POINTS_ONLY")}</span>}
          {(onAdventCalendar && savePercetage > 5) && <span>{t("ADVENT_CALENDAR.SAVE_PERCENTAGE", { percentage: savePercetage })}</span>}
        </div>
      </div>

      <div className={styles.body}>
        <div className={styles.top}>
          <div className={styles.brandRow}>
            <span className={styles.brand}>{brand}</span>
            {product.type === "configurable" && product.options.items.length > 0 && getAccrual(product) > 0 ?
              <span className={styles.accrual}>
                {t("PRODUCT.EARN_UP_TO", { points: formatPoints(getAccrual(product)) })}
              </span> :
              (product.attributes.accrual || 0) > 0 &&
                <span className={styles.accrual}>
                  {t("PRODUCT.EARN", { points: formatPoints(product.attributes.accrual || 0) })}
                </span>
            }
          </div>

          <span className={styles.name}>{product.name}</span>
          {loggedIn && product.type !== "configurable" &&
            <WishlistIcon
              className={styles.wishlistIcon}
              size="small"
              inWishlist={inWishlist}
              onClick={() => toggleWishlist()}
            />
          }
          {loggedIn && product.type === "configurable" &&
            <WishlistIcon
              className={styles.wishlistIcon}
              size="small"
              inWishlist={inWishlist}
            />
          }
        </div>

        <PriceSplit className={styles.price} pointsPrice={pointsPrice} />
      </div>
    </ProductLink>
  );
};

/* eslint-enable complexity */

export const DummyCard = ({ className }: { className?: string }) => {
  return (
    <div className={cn(className, styles.block, styles.dummy)}>
      <div className={styles.imageWrapper}>
        <div className={styles.image}>
          <Square />
        </div>
      </div>

      <div className={styles.body}>
        <p className={styles.brand}>&nbsp;</p>
        <span className={styles.name}>&nbsp;</span>

        <div>
          <p className={styles.price}>&nbsp;</p>
        </div>
      </div>
    </div>
  );
};

export default ProductCard;
