/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { CmsRequest, CmsResponse } from "state/cms";

import { CMS_REQUEST, CMS_RESPONSE } from "state/cms";
import { cms } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: CmsRequest) => {
      const { cms: { cmsPage } } = await client(cms, {
        url: msg.url,
      }, { cache: true });

      return ({
        tag: CMS_RESPONSE,
        data: cmsPage,
      }: CmsResponse);
    },
    subscribe: { [CMS_REQUEST]: true },
  });
};

export default registerClient;
