/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { Foldable } from "@crossroads/ui-components";
import ChevronIcon from "icons/chevron.svg";
import { QuoteData, CustomerData } from "data";
import { useData } from "crustate/react";
import useFormat from "helpers/use-format";
import { useTranslate } from "@awardit/react-use-translate";
import { Box, BoxHeader, BoxBody } from "components/Box";
import { getCustomerPoints } from "state/customer";
import { pointsPriceByID } from "helpers/points";
import PriceSplit from "components/PriceSplit";

type Props = {
  children?: React$Node,
  open: boolean,
  setOpen: boolean => void,
  className?: string,
  toPayPoints?: number,
  toPayPrice?: number,
};

const CartSummary = ({
  children,
  open,
  setOpen,
  className,
  toPayPoints,
  toPayPrice,
}: Props) => {
  const t = useTranslate();
  const { formatPoints, formatPrice } = useFormat();
  const quoteData = useData(QuoteData);
  const customerData = useData(CustomerData);
  const balance = customerData.data ? getCustomerPoints(customerData.data) : 0;

  if (!quoteData.data) {
    return null;
  }

  const { isVirtual, items, availablePointPayments } = quoteData.data;
  const pointPayment = pointsPriceByID(availablePointPayments, "sas_extrapoints");

  const subTotal = items.reduce((acc, curr) => {
    const payment = pointsPriceByID(curr.availablePointPayments, "sas_extrapoints");

    return (acc + (payment?.points.max.incVat || 0));
  }, 0);
  const toggleOpen = () => setOpen(!open);

  if (!pointPayment) {
    // return null;
  }

  const { shipping } = pointPayment || {};

  return (
    <div className={cn(
      styles.block,
      {
        [styles.open]: open,
        [styles.processing]: quoteData.state === "UPDATING_ITEM",
      },
      className
    )}
    >
      <Box>
        <BoxHeader className={styles.header} onClick={toggleOpen}>
          <span>{open ? t("CART.MINIMIZE_SUMMARY") : t("CART.VIEW_DETAILS")}</span>
          <ChevronIcon className={styles.closeIcon} />
        </BoxHeader>
        <div className={styles.body}>
          <Foldable open={open}>
            <BoxBody>
              <h4>{t("CART.SUMMARY")}</h4>
              <table>
                <tbody>
                  <tr>
                    <td>{t("CART.SUBTOTAL")}</td>
                    <td>{formatPoints(subTotal)}</td>
                  </tr>
                  {(!isVirtual && shipping) &&
                    <tr>
                      <td>{t("CART.SHIPPING")}</td>
                      <td>
                        {formatPoints(shipping.points.value.incVat || 0)}
                      </td>
                    </tr>
                  }
                  <tr>
                    <td className={styles.tableSection}><strong>{t("CART.GRANDTOTAL")}</strong></td>
                    <td className={styles.tableSection}>
                      {formatPoints(pointPayment?.points.max.incVat || 0)}
                    </td>
                  </tr>
                  <tr>
                    <td className={styles.tableSection}>{t("CART.YOUR_BALANCE")}</td>
                    <td className={styles.tableSection}>{formatPoints(balance)}</td>
                  </tr>
                </tbody>
              </table>
            </BoxBody>
            <hr />
          </Foldable>

          <BoxBody className={styles.footer}>
            <table>
              <tbody>
                <tr>
                  <td>
                    <h4>{t("CART.TO_PAY")}</h4>
                  </td>
                  {typeof toPayPoints === "number" && typeof toPayPrice === "number" ?
                    <td>
                      <h4>{formatPoints(toPayPoints)}</h4>
                      {toPayPrice > 0 &&
                        <span>+ {formatPrice(toPayPrice)}</span>
                      }
                    </td> :
                    <td>
                      <PriceSplit
                        className={styles.priceSplit}
                        pointsPrice={pointPayment}
                      />
                    </td>
                  }
                </tr>
              </tbody>
            </table>
          </BoxBody>
        </div>

        {children}
      </Box>
    </div>
  );
};

CartSummary.defaultProps = {
  className: "",
  toPayPoints: undefined,
  toPayPrice: undefined,
};

export default CartSummary;
