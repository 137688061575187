/* @flow */

import React, { useEffect, useState } from "react";
import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";
import { Input, Slider } from "@crossroads/ui-components";
import useFormat from "helpers/use-format";
import Button from "components/Button";

type Props = {
  stepSize: number,
  points: number,
  minValue: number,
  maxValue: number,
  processing: boolean,
  onNext: (points: number) => void,
};

const PointSelect = ({ stepSize, points, minValue, maxValue, processing, onNext }: Props) => {
  const t = useTranslate();
  const { formatPoints } = useFormat();
  const [localPoints, _setLocalPoints] = useState(points);
  const [input, setInput] = useState(localPoints);

  useEffect(() => {
    setInput(localPoints);
  }, [localPoints]);

  const setLocalPoints = (v: number) => {
    const value = Math.round(
      Math.min(
        Math.max(
          v,
          minValue
        ),
        maxValue
      )
    );

    _setLocalPoints(value);
    setInput(value);
  };

  return (
    <section className={styles.block}>
      <section className={styles.box}>
        <h2 className={styles.heading}>{formatPoints(localPoints)}</h2>
        <div className={styles.pointSelect}>
          <div className={styles.slider}>
            <Slider
              value={localPoints}
              minValue={minValue}
              maxValue={maxValue}
              stepSize={stepSize}
              disabled={processing}
              onChange={v => _setLocalPoints(v)}
            />

            <div className={styles.sliderLabels}>
              <span>{formatPoints(minValue)}</span>
              <span>{formatPoints(maxValue)}</span>
            </div>
          </div>
          <Input
            className={styles.input}
            label={t("CHECKOUT.POINTS")}
            variant="float"
            name="points"
            disabled={processing}
            value={input.toString()}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setInput(e.currentTarget.value);
            }}
            onBlur={e => {
              const val = parseInt(e.target.value, 10);
              const number = Number.isNaN(val) ? 0 : val;
              setLocalPoints(number);
            }}
          />
        </div>
      </section>
      <Button
        loading={processing}
        className={styles.button}
        variant="primary"
        onClick={() => onNext(localPoints)}
      >
        {t("PURCHASE_POINTS.SAVE_AND_PROCEED")}
      </Button>
    </section>
  );
};

export default PointSelect;
