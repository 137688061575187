/* @flow */

import type { Model } from "crustate";
import { updateData } from "crustate";

export type Mode =
  "normal" |
  "filter" |
  "categories" |
  "brands" |
  "search" |
  "cart" |
  "language" |
  "login" |
  "login_modal";

export type ViewModeRequest = {
  tag: typeof VIEW_MODE_REQUEST,
  mode: Mode,
};

export const MODE = {
  NORMAL: "normal",
  FILTER: "filter",
  CATEGORIES: "categories",
  BRANDS: "brands",
  SEARCH: "search",
  CART: "cart",
  LANGUAGE: "language",
  LOGIN: "login",
  LOGIN_MODAL: "login_modal",
};

export const VIEW_MODE_REQUEST: "request/view_mode" = "request/view_mode";

export const setMode = (mode: Mode) => ({
  tag: VIEW_MODE_REQUEST,
  mode,
});

const initialState = MODE.NORMAL;

export const ViewModeModel: Model<Mode, {}, ViewModeRequest> = {
  id: "view_mode",
  init: () => updateData(initialState),
  update: (state, msg) => {
    switch (msg.tag) {
      case VIEW_MODE_REQUEST:
        return updateData(msg.mode);
      default:
    }
  },
};
