/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { QuoteRequest, QuoteResponse } from "state/quote";
import type { Quote } from "shop-state/types";
import { addMessage } from "@crossroads/shop-state/messages";
import { removeExampleEmail } from "helpers/utils";

import {
  QUOTE_INIT_REQUEST,
  QUOTE_INIT_RESPONSE,
  QUOTE_ADD_ITEM_REQUEST,
  QUOTE_ADD_ITEM_RESPONSE,
  QUOTE_REMOVE_ITEM_REQUEST,
  QUOTE_REMOVE_ITEM_RESPONSE,
  QUOTE_UPDATE_ITEM_REQUEST,
  QUOTE_UPDATE_ITEM_RESPONSE,
  QUOTE_SET_ADDRESSES_REQUEST,
  QUOTE_SET_ADDRESSES_RESPONSE,
  QUOTE_SET_PAYMENT_METHOD_STRIPE_REQUEST,
  QUOTE_SET_PAYMENT_METHOD_STRIPE_RESPONSE,
  QUOTE_SET_EMAIL_REQUEST,
  QUOTE_SET_EMAIL_RESPONSE,
} from "@crossroads/shop-state/quote";

import {
  QUOTE_SET_DISCOUNT_CODE_REQUEST,
  QUOTE_SET_DISCOUNT_CODE_RESPONSE,
  QUOTE_REMOVE_DISCOUNT_CODE_REQUEST,
  QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE,
  QUOTE_SET_POINTS_PAYMENT_REQUEST,
  QUOTE_SET_POINTS_PAYMENT_RESPONSE,
} from "state/quote";

import {
  quote as quoteQuery,
  quotePointsRemove,
  addToCart,
  removeQuoteItem as removeQuoteItemMutation,
  updateQuoteItemQty as updateQuoteItemQtyMutation,
  setQuoteShippingAddress,
  setQuoteShippingMethodToCheapest,
  setQuoteBillingAddress,
  setQuoteBillingAddressAsShippingAddress,
  updateCustomerEmail as updateCustomerEmailQuery,
  setQuotePaymentMethodStripe,
  setQuoteDiscountCode as setQuoteDiscountCodeMutation,
  removeQuoteDiscountCode,
  quotePointsSet as quotePointsSetQuery,
  removeUnorderableQuoteItems,
} from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const [
        { removeUnorderableQuoteItems: removedItems },
        { quote },
      ] = await Promise.all([
        client(removeUnorderableQuoteItems),
        client(quoteQuery),
      ]);

      for (const item of removedItems) {
        storage.broadcastMessage(addMessage({
          translationKey: "ITEM_REMOVED_FROM_CART",
          variable: {
            name: item.product.name,
          },
        }, "success"));
      }

      return ({
        tag: QUOTE_INIT_RESPONSE,
        data: removeExampleEmail(quote),
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_INIT_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag !== QUOTE_ADD_ITEM_REQUEST) {
        return;
      }

      const params = msg.bundleOptions ? {
        buyRequest: msg.buyRequest,
        qty: msg.qty,
        bundleOptions: msg.bundleOptions,
      } : {
        buyRequest: msg.buyRequest,
        qty: msg.qty,
      };

      const [
        { addQuoteItem },
        ,
        { quotePointsRemove: { quote } },
      ] = await Promise.all([
        client(addToCart, params),
        client(setQuoteShippingMethodToCheapest),
        client(quotePointsRemove),
      ]);

      if (addQuoteItem.result !== "success") {
        storage.broadcastMessage(addMessage(addQuoteItem.result, "error"));
      }

      return ({
        tag: QUOTE_ADD_ITEM_RESPONSE,
        data: removeExampleEmail(quote),
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_ADD_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag !== QUOTE_UPDATE_ITEM_REQUEST) {
        return;
      }

      const [
        { updateQuoteItemQty },
        ,
        { quotePointsRemove: { quote } },
      ] = await Promise.all([
        client(updateQuoteItemQtyMutation, {
          itemBuyRequest: msg.itemBuyRequest,
          qty: msg.qty,
        }),
        client(setQuoteShippingMethodToCheapest),
        client(quotePointsRemove),
      ]);

      if (updateQuoteItemQty.result !== "success") {
        storage.broadcastMessage(addMessage(updateQuoteItemQty.result, "error"));
      }

      return ({
        tag: QUOTE_UPDATE_ITEM_RESPONSE,
        data: removeExampleEmail(quote),
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_UPDATE_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag !== QUOTE_REMOVE_ITEM_REQUEST) {
        return;
      }

      const [
        { removeQuoteItem },
        ,
        { quotePointsRemove: { quote } },
      ] = await Promise.all([
        client(removeQuoteItemMutation, { itemBuyRequest: msg.itemBuyRequest }),
        client(setQuoteShippingMethodToCheapest),
        client(quotePointsRemove),
      ]);

      if (removeQuoteItem.result !== "success") {
        // TODO: translate error!
        storage.broadcastMessage(addMessage(removeQuoteItem.result, "error"));
      }

      return ({
        tag: QUOTE_REMOVE_ITEM_RESPONSE,
        data: removeExampleEmail(quote),
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_REMOVE_ITEM_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_ADDRESSES_REQUEST) {
        const errors = {};
        client(setQuoteBillingAddress, { address: msg.billing });

        if (msg.shipToSameAddress) {
          client(setQuoteBillingAddressAsShippingAddress);
        }
        else {
          client(setQuoteShippingAddress, { address: msg.shipping });
        }

        const response = await client(updateCustomerEmailQuery, { email: msg.email });

        const emailError = !["success", "notModified"].includes(response.updateCustomerEmail.result) ?
          response.updateCustomerEmail.result : null;

        if (emailError) {
          errors.email = emailError;
          storage.broadcastMessage(addMessage(response.updateCustomerEmail.result, "error"));
        }

        client(setQuoteShippingMethodToCheapest);

        const { quote: data } = await client(quoteQuery);

        return ({
          tag: QUOTE_SET_ADDRESSES_RESPONSE,
          data: removeExampleEmail(data),
          errors,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_ADDRESSES_REQUEST]: true },
  });

  storage.addEffect({
    effect: async () => {
      client(setQuotePaymentMethodStripe);

      const { quote: data } = await client(quoteQuery);

      return ({
        tag: QUOTE_SET_PAYMENT_METHOD_STRIPE_RESPONSE,
        data,
      }: QuoteResponse);
    },
    subscribe: { [QUOTE_SET_PAYMENT_METHOD_STRIPE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_DISCOUNT_CODE_REQUEST) {
        const { setQuoteDiscountCode } = await client(setQuoteDiscountCodeMutation, {
          code: msg.code,
        });

        if (setQuoteDiscountCode.result !== "success") {
          storage.broadcastMessage(addMessage("DISCOUNT_CODE_FAILURE", "error"));
        }
        else {
          storage.broadcastMessage(addMessage("DISCOUNT_CODE_APPLIED", "success"));
        }

        const { quote: data } = await client(quoteQuery);

        return ({
          tag: QUOTE_SET_DISCOUNT_CODE_RESPONSE,
          data: (removeExampleEmail(data): Quote),
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_DISCOUNT_CODE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_REMOVE_DISCOUNT_CODE_REQUEST) {
        await client(removeQuoteDiscountCode);

        const { quote: data } = await client(quoteQuery);

        return ({
          tag: QUOTE_REMOVE_DISCOUNT_CODE_RESPONSE,
          data: (removeExampleEmail(data): Quote),
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_REMOVE_DISCOUNT_CODE_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_EMAIL_REQUEST) {
        const { updateCustomerEmail } = await client(updateCustomerEmailQuery, {
          email: msg.email,
        });

        if (updateCustomerEmail.result !== "success" &&
          updateCustomerEmail.result !== "notModified") {
          storage.broadcastMessage(addMessage(updateCustomerEmail.result, "error"));
        }

        const { quote: data } = await client(quoteQuery);

        return ({
          tag: QUOTE_SET_EMAIL_RESPONSE,
          data: removeExampleEmail(data),
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_EMAIL_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: QuoteRequest) => {
      if (msg.tag === QUOTE_SET_POINTS_PAYMENT_REQUEST) {
        const params = {
          id: msg.id,
          points: msg.points,
        };

        const { quotePointsSet: { quote } } =
          await client(quotePointsSetQuery, params);

        // TODO: check result for error on quotePointsSet.result

        return ({
          tag: QUOTE_SET_POINTS_PAYMENT_RESPONSE,
          data: quote,
        }: QuoteResponse);
      }
    },
    subscribe: { [QUOTE_SET_POINTS_PAYMENT_REQUEST]: true },
  });
};

export default registerClient;
