/* @flow */

import React from "react";
import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  children: React$Node,
  className?: string,
  onClick?: () => void,
};

export const Box = ({ children, className, ...props }: Props) =>
  <div {...props} className={cn(styles.box, className)}>{children}</div>;

export const BoxHeader = ({ children, className, ...props }: Props) => (
  <header
    {...props}
    className={cn(
      styles.boxHeader,
      { [styles.interactive]: Boolean(props.onClick) },
      className
    )}
  >
    {children}
  </header>
);

export const BoxBody = ({ children, className, ...props }: Props) =>
  <main {...props} className={cn(styles.boxBody, className)}>{children}</main>;
