/* @flow */

import type { PaginatedOrders } from "shop-state/types";
import type { Model } from "crustate";
import type { Response } from "./util";
import { updateData } from "crustate";

type Data =
  | { state: "LOADING", data: PaginatedOrders }
  | { state: "LOADED", data: PaginatedOrders }
  | { state: "ERROR", data: null, error: string };

export type OrderHistoryResponse = Response<typeof ORDER_HISTORY_RESPONSE, ?PaginatedOrders>;

export type OrderHistoryRequest = {
  tag: typeof ORDER_HISTORY_REQUEST,
  pageSize: number,
  page: number,
};

export const ORDER_HISTORY_REQUEST: "request/order_history" = "request/order_history";
export const ORDER_HISTORY_RESPONSE: "response/order_history" = "response/order_history";

export const PAGE_SIZE = 3;

export const getOrderHistory = (page: number, pageSize: number = PAGE_SIZE) => ({
  tag: ORDER_HISTORY_REQUEST,
  pageSize,
  page,
});

export const OrderHistoryModel: Model<Data, { pageSize: number, page: number },
OrderHistoryRequest | OrderHistoryResponse> = {
  id: "order_history",
  init: ({ pageSize, page }) =>
    updateData(
      { state: "LOADING", data: { items: [], totalCount: 0 }, error: "" },
      getOrderHistory(page, pageSize)
    ),
  update: (state, msg) => {
    switch (msg.tag) {
      case ORDER_HISTORY_REQUEST:
        if (state.state === "LOADING") {
          throw new Error("Invalid state transition");
        }

        if (state.state === "ERROR") {
          return updateData({ state: "LOADING", data: { items: [], totalCount: 0 }, error: "" });
        }

        return updateData({ state: "LOADING", data: state.data }, msg);
      case ORDER_HISTORY_RESPONSE:
        if (state.state !== "LOADING") {
          throw new Error("Invalid state transition");
        }

        if (msg.error) {
          return updateData({ state: "ERROR", data: null, error: msg.error });
        }

        if (msg.data) {
          return updateData({ state: "LOADED", data: msg.data });
        }

        break;
      default:
    }
  },
};
