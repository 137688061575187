/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { AdventCalendarResponse } from "state/advent-calendar";

import { ADVENT_CALENDAR_REQUEST, ADVENT_CALENDAR_RESPONSE } from "state/advent-calendar";
import { adventCalendar, adventCalendarTestDay } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async ({ testDate, password }) => {
      const data = (testDate && password) ?
        await client(adventCalendarTestDay, {
          testDate,
          password,
        }) :
        await client(adventCalendar);

      return ({
        tag: ADVENT_CALENDAR_RESPONSE,
        data: data.adventCalendar,
      }: AdventCalendarResponse);
    },
    subscribe: { [ADVENT_CALENDAR_REQUEST]: true },
  });
};

export default registerClient;
