/* @flow */

import React from "react";
import cn from "classnames";

import Button from "components/Button";
import { useTranslate } from "@awardit/react-use-translate";
import ChevronIcon from "icons/chevron.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
  currentPage: number,
  setPage: (pageNumber: number) => void,
  pageCount: number,
};

const OrderHistoryPagination = ({
  className,
  currentPage,
  setPage,
  pageCount }: Props) => {
  const t = useTranslate();
  return (
    <div className={cn(styles.pagination, className)}>
      <Button
        className={styles.pagination__button}
        disabled={currentPage === 1}
        onClick={() => setPage(currentPage - 1)}
      >
        <ChevronIcon />
      </Button>
      <span className={styles.pagination__text}>
        {t("ORDER_HISTORY.PAGINATION", { current: currentPage, total: pageCount })}
      </span>
      <Button
        className={styles.pagination__button}
        disabled={currentPage === pageCount}
        onClick={() => setPage(currentPage + 1)}
      >
        <ChevronIcon />
      </Button>
    </div>
  );
};

export default OrderHistoryPagination;
