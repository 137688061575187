/* @flow */

import React from "react";
import { Link } from "react-router-dom";
import { Button as ButtonComponent } from "@crossroads/ui-components";

import styles from "./styles.scss";

type Props = {
  children: React$Node,
  to?: string,
};

const Button = ({ to = "", children, ...props }: Props) => {
  if (to.length > 0) {
    return (
      <Link to={to} className={styles.link}>
        <ButtonComponent {...props}>{children}</ButtonComponent>
      </Link>
    );
  }

  return <ButtonComponent {...props}>{children}</ButtonComponent>;
};

export default Button;
