/* @flow */

import type { ParentItem } from "components/AppHeader/Drawer";

import React, { useContext, useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Route, Switch } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext } from "entrypoint/shared";
import { QuoteData } from "data";
import { useData } from "crustate/react";
import { CartMini } from "@crossroads/ui-components";
import cn from "classnames";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import Drawer from "components/AppHeader/Drawer";
import CartCounter from "components/AppHeader/CartCounter";
import useCustomer from "helpers/use-customer";
import useFormat from "helpers/use-format";
import useCheckoutSteps from "helpers/use-checkout-steps";
import SearchMenu from "../SearchMenu";
import Logo from "components/AppHeader/Logo";
import HamburgerMenu from "components/AppHeader/HamburgerMenu";
import AccountBadge from "components/AccountBadge";
import { MODE } from "state/view-mode";
import MobileNavHeader from "../MobileNavHeader";
import { useUi } from "helpers/ui";
import SearchIcon from "icons/search.svg";
import PhoneIcon from "icons/phone.svg";
import HamburgerIcon from "icons/hamburger.svg";
import CloseIcon from "icons/close.svg";
import { adventCalendarActive } from "state/advent-calendar";
import useAdventCalendarEnabled from "helpers/use-advent-calendar-enabled";
import Spinner from "components/Spinner";

import styles from "./styles.scss";

const CheckoutSteps = () => {
  const { steps, currentStep } = useCheckoutSteps();

  return (
    <div className={styles.steps}>
      {steps.map((step, id) => (
        <Link
          key={step.id}
          className={cn(styles.step, { [styles.active]: step.id === currentStep.id })}
          to={step.link}
        >
          {`${id + 1}. ${step.title}`}
        </Link>
      ))}
    </div>
  );
};

const NavLinks = () => {
  const t = useTranslate();
  const {
    viewMode,
    setViewMode,
  } = useUi();
  const { loggedIn, redirectToLogin } = useCustomer();

  const navLinkIsActive = (match: boolean) => {
    if (!match) {
      return false;
    }

    if (viewMode !== MODE.NORMAL) {
      return false;
    }

    return true;
  };

  const toggleCategories = () =>
    setViewMode(viewMode === MODE.CATEGORIES ? MODE.NORMAL : MODE.CATEGORIES);
  const toggleBrands = () =>
    setViewMode(viewMode === MODE.BRANDS ? MODE.NORMAL : MODE.BRANDS);

  const { date } = useContext(StoreInfoContext);
  const now = new Date(date);
  const showAdventCalendar = adventCalendarActive(now);
  const adventCalendarEnabled = useAdventCalendarEnabled();

  return (
    <>
      <NavLink
        to="/all-products?sort=created_at&sortOrder=DESC"
        activeClassName={styles.active}
        className={cn(
          styles.item,
          styles.item__allproducts
        )}
        isActive={navLinkIsActive}
        onClick={() => setViewMode(MODE.NORMAL)}
      >
        <span>{t("ALL_PRODUCTS.TITLE")}</span>
      </NavLink>
      <Button
        className={
          cn(
            styles.item,
            styles.item__categories,
            { [styles.active]: viewMode === MODE.CATEGORIES }
          )
        }
        onClick={toggleCategories}
      >
        <span>{t("HEADER.CATEGORIES")}</span>
      </Button>

      <Button
        className={
          cn(
            styles.item,
            styles.item__brands,
            { [styles.active]: viewMode === MODE.BRANDS }
          )
        }
        onClick={toggleBrands}
      >
        <span>{t("HEADER.BRANDS")}</span>
      </Button>

      <NavLink
        to="/gift-cards-vouchers"
        activeClassName={styles.active}
        className={cn(styles.item, styles.item__points)}
        isActive={navLinkIsActive}
        onClick={() => setViewMode(MODE.NORMAL)}
      >
        <span>{t("HEADER.GIFTCARDS")}</span>
      </NavLink>

      {!loggedIn ?
        <Button
          className={cn(styles.item, styles.item__buyandtransfer)}
          onClick={() => redirectToLogin()}
        >
          <span>{t("PURCHASE_POINTS.TITLE")}</span>
        </Button> :
        <NavLink
          to="/account/buy-and-transfer-points"
          activeClassName={styles.active}
          className={cn(styles.item, styles.item__points)}
          isActive={navLinkIsActive}
          onClick={() => setViewMode(MODE.NORMAL)}
        >
          <span>{t("PURCHASE_POINTS.TITLE")}</span>
        </NavLink>
      }

      {adventCalendarEnabled && showAdventCalendar &&
        <NavLink
          to={t("ADVENT_CALENDAR.LINK")}
          activeClassName={styles.active}
          className={cn(styles.item, styles.item__customerService)}
          isActive={navLinkIsActive}
        >
          <span>{t("ADVENT_CALENDAR.TEXT")}</span>
        </NavLink>
      }
    </>
  );
};

const DesktopNavHeader = ({ heading, onClose }: { heading: string, onClose: () => void }) => (
  <Wrapper className={styles.desktopNavHeader}>
    <h3>{heading}</h3>
    <Button onClick={onClose}><CloseIcon /></Button>
  </Wrapper>
);

const mapBrands = (brands: $ReadOnlyArray<string>): Array<ParentItem> => {
  const grouped = {};

  brands.forEach(brand => {
    const firstLetter = brand[0].toLowerCase();
    const array = (grouped[firstLetter] || (grouped[firstLetter] = []));

    array.push({
      name: brand,
      url: `/brand/${encodeURIComponent(brand)}`,
    });
  });

  return Object.keys(grouped)
    .sort((a, b) => a.localeCompare(b))
    .map(letter => ({
      name: letter.toUpperCase(),
      url: null,
      children: grouped[letter]
        .sort((a, b) => a.name.localeCompare(b.name, { caseInsensitive: true })),
    }));
};

const MobileSubNavigations = ({ onClose }: {
  onClose: () => void,
}) => {
  const t = useTranslate();
  const { categories, brands } = useContext(StoreInfoContext);

  const {
    setSubNavOpen,
    viewMode,
    setViewMode,
  } = useUi();

  const closeMenu = () => setViewMode(MODE.NORMAL);

  return (
    <>
      {viewMode === MODE.SEARCH &&
        <nav className={cn(styles.mobile, styles.subNav, styles.search)}>
          <SearchMenu heading={t("HEADER.SEARCH")} onClose={onClose} onItemClick={closeMenu} onBack={() => setSubNavOpen(false)} />
        </nav>
      }

      {viewMode === MODE.CATEGORIES &&
        <nav className={cn(styles.mobile, styles.subNav, styles.categories)}>
          <MobileNavHeader
            heading={t("HEADER.CATEGORIES")}
            onBack={() => setSubNavOpen(false)}
            onClose={onClose}
          />

          <Drawer items={categories} />
        </nav>
      }

      {viewMode === MODE.BRANDS &&
        <nav className={cn(styles.mobile, styles.subNav, styles.brands)}>
          <MobileNavHeader
            heading={t("HEADER.BRANDS")}
            onBack={() => setSubNavOpen(false)}
            onClose={onClose}
          />

          <Drawer items={mapBrands(brands)} />
        </nav>
      }
    </>
  );
};

const DesktopSubNavigations = ({ onClose }: {
  onClose: () => void,
}) => {
  const t = useTranslate();
  const { categories, brands } = useContext(StoreInfoContext);

  const {
    setHamburgerOpen,
    setSubNavOpen,
    viewMode,
    setViewMode,
  } = useUi();

  const closeMenu = () => setViewMode(MODE.NORMAL);

  return (
    <>
      {viewMode === MODE.CATEGORIES &&
        <nav className={cn(styles.desktop, styles.subNav, styles.categories)}>
          <DesktopNavHeader heading={t("HEADER.CATEGORIES")} onClose={onClose} />
          <Drawer
            setHamburgerOpen={setHamburgerOpen}
            heading={t("HEADER.CATEGORIES")}
            items={categories}
            onClose={onClose}
            onItemClick={closeMenu}
            onBack={() => setSubNavOpen(false)}
          />
        </nav>
      }

      {viewMode === MODE.BRANDS &&
        <nav className={cn(styles.desktop, styles.subNav, styles.brands)}>
          <DesktopNavHeader heading={t("HEADER.BRANDS")} onClose={onClose} />
          <Drawer
            setHamburgerOpen={setHamburgerOpen}
            heading={t("HEADER.BRANDS")}
            items={mapBrands(brands)}
            onClose={onClose}
            onItemClick={closeMenu}
            onBack={() => setSubNavOpen(false)}
          />
        </nav>
      }
    </>
  );
};

const Nav = ({
  onCheckout,
}: { onCheckout: boolean }) => {
  const t = useTranslate();
  const location = useLocation();
  const quoteData = useData(QuoteData);
  const { formatPrice } = useFormat();
  const { customer, loggedIn, redirectToLogin } = useCustomer();
  const [redirectingToLogin, setRedirectingToLogin] = useState(false);
  const {
    setHamburgerOpen,
    toggleHamburger,
    setSubNavOpen,
    viewMode,
    setViewMode,
    closeHamburger,
  } = useUi();

  const toggleSearch = () =>
    setViewMode(viewMode === MODE.SEARCH ? MODE.NORMAL : MODE.SEARCH);

  const navLinkIsActive = (match: boolean) => {
    if (!match) {
      return false;
    }

    if (viewMode !== MODE.NORMAL) {
      return false;
    }

    return true;
  };

  // Close menus when switching page
  useEffect(() => {
    setViewMode(MODE.NORMAL);
    setSubNavOpen(false);
    setHamburgerOpen(false);
  }, [setViewMode, setSubNavOpen, setHamburgerOpen, location.pathname]);

  return (
    <div className={cn(styles[`mode__${viewMode}`], { [styles.hide]: onCheckout })}>
      <div className={styles.block}>
        <Wrapper className={styles.wrapper}>
          {viewMode === MODE.SEARCH &&
            <div className={cn(styles.desktop, styles.subNav, styles.search)}>
              <SearchMenu onClose={() => toggleSearch()} />
            </div>
          }
          <div className={styles.left}>
            <Link className={cn(styles.item, styles.item__logo)} to="/">
              <Logo
                className={styles.logo}
                onClick={() => setViewMode(MODE.NORMAL)}
              />
            </Link>

            {viewMode !== MODE.SEARCH ? (
              <div className={styles.links}>
                <Switch>
                  <Route path="/checkout/success" component={NavLinks} />
                  <Route path="/checkout" component={CheckoutSteps} />
                  <Route component={NavLinks} />
                </Switch>
              </div>
            ) : null}
          </div>
          <div className={styles.right}>
            <nav className={styles.nav}>
              {!onCheckout && viewMode !== MODE.SEARCH &&
                <Button
                  className={cn(
                    styles.item,
                    styles.item__search,
                    { [styles.active]: viewMode === MODE.SEARCH }
                  )}
                  onClick={toggleSearch}
                >
                  <SearchIcon />
                </Button>
              }

              <NavLink
                to={t("CUSTOMER_SERVICE.LINK")}
                activeClassName={styles.active}
                className={cn(styles.item, styles.item__customerService)}
                isActive={navLinkIsActive}
              >
                <span className="sr-only">{t("CUSTOMER_SERVICE.TEXT")}</span>
                <PhoneIcon aria-hidden="true" focusable="false" />
              </NavLink>

              {!onCheckout && (loggedIn && customer) &&
                <Link
                  to="/account"
                  className={styles.item}
                >
                  <AccountBadge
                    className={styles.accountBadge}
                    customer={customer}
                  />
                </Link>
              }

              {!loggedIn &&
                <Button
                  className={cn(
                    styles.item,
                    styles.loginLink,
                    { [styles.active]: viewMode === MODE.LOGIN }
                  )}
                  onClick={() => redirectToLogin(setRedirectingToLogin)}
                >
                  {redirectingToLogin ?
                    <Spinner /> :
                    <span>{t("HEADER.LOGIN")}</span>
                  }
                </Button>
              }

              {!onCheckout && (loggedIn && customer?.sasRedemptionEnabled) &&
              <CartCounter
                className={cn(styles.item, styles.item__cartCounter)}
                isOpen={viewMode === MODE.CART}
                openMiniCart={() =>
                  setViewMode(viewMode === MODE.CART ? MODE.NORMAL : MODE.CART)
                } />}
            </nav>
          </div>

          <Button
            className={cn(styles.item, styles.hamburger, styles.padding)}
            onClick={toggleHamburger}
          >
            <HamburgerIcon />
          </Button>

          {viewMode === MODE.CART &&
            <CartMini
              quoteData={quoteData}
              paymentId="sas_extrapoints"
              ctaUrl="/checkout/1"
              altUrl="/checkout"
              formatPriceExt={formatPrice}
              className={cn(styles.desktop, styles.miniCart)}
              onClose={() => setViewMode(MODE.NORMAL)}
            />
          }
        </Wrapper>

        <HamburgerMenu>
          <MobileSubNavigations
            className={styles.mobile}
            onClose={() => closeHamburger(styles.animationDuration)}
          />
        </HamburgerMenu>
      </div>

      <DesktopSubNavigations
        onClose={() => setViewMode(MODE.NORMAL)}
      />

      {viewMode === MODE.CART &&
        <CartMini
          quoteData={quoteData}
          paymentId="sas_extrapoints"
          ctaUrl="/checkout/1"
          altUrl="/checkout"
          formatPriceExt={formatPrice}
          className={cn(styles.mobile, styles.miniCart)}
          onClose={() => setViewMode(MODE.NORMAL)}
        />
      }

      {viewMode !== MODE.NORMAL &&
        <div className={styles.dim} onClick={() => setViewMode(MODE.NORMAL)} />
      }
    </div>
  );
};

export default Nav;
