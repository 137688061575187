/* @flow */

import type { Model } from "crustate";
import { WISHLIST_REMOVE_PRODUCT_RESPONSE } from "./wishlist";
import { updateData } from "crustate";

export type WishlistItem = {
  addedAt: string,
  itemId: string,
  selected: boolean,
};

export type WishlistToggle = {
  [key: string]: addToWishlist.WishlistItem,
};

type Data =
  | { state: "INIT", data: {} }
  | { state: "LOADING", data: WishlistToggle }
  | { state: "LOADED", data: WishlistToggle }
  | { state: "ERROR", data: WishlistToggle, error: string };

export type WishlistToggleAddProductResponse =
  {|
    tag: typeof WISHLIST_TOGGLE_ADD_PRODUCT_RESPONSE,
    data: {
      wishlistItem: WishlistItem,
    },
    sku: string,
  |} | {| tag: typeof WISHLIST_TOGGLE_ADD_PRODUCT_RESPONSE, error: string |};

export type WishlistToggleRemoveProductResponse =
  {|
    tag: typeof WISHLIST_TOGGLE_REMOVE_PRODUCT_RESPONSE,
    itemId: string,
    sku: string,
  |} | {| tag: typeof WISHLIST_TOGGLE_REMOVE_PRODUCT_RESPONSE, error: string |};

export type WishlistToggleAddProductRequest = {
  tag: typeof WISHLIST_TOGGLE_ADD_PRODUCT_REQUEST,
  buyRequest: string,
  sku: string,
};

export type WishlistToggleRemoveProductRequest = {
  tag: typeof WISHLIST_TOGGLE_REMOVE_PRODUCT_REQUEST,
  itemId: string,
  sku: string,
};

export const WISHLIST_TOGGLE_ADD_PRODUCT_RESPONSE: "wishlist_toggle/add_product/response" = "wishlist_toggle/add_product/response";
export const WISHLIST_TOGGLE_ADD_PRODUCT_REQUEST: "wishlist_toggle/add_product/request" = "wishlist_toggle/add_product/request";
export const WISHLIST_TOGGLE_REMOVE_PRODUCT_RESPONSE: "wishlist_toggle/remove_product/response" = "wishlist_toggle/remove_product/response";
export const WISHLIST_TOGGLE_REMOVE_PRODUCT_REQUEST: "wishlist_toggle/remove_product/request" = "wishlist_toggle/remove_product/request";

export const addToWishlist = (buyRequest: string, sku: string) => ({
  tag: WISHLIST_TOGGLE_ADD_PRODUCT_REQUEST,
  buyRequest,
  sku,
});

export const removeFromWishlist = (itemId: string, sku: string) => ({
  tag: WISHLIST_TOGGLE_REMOVE_PRODUCT_REQUEST,
  itemId,
  sku,
});

export const WishlistToggleModel: Model<Data, {},
  | WishlistToggleAddProductRequest
  | WishlistToggleAddProductResponse
  | WishlistToggleRemoveProductRequest
  | WishlistToggleRemoveProductResponse> = {
    id: "wishlist-toggle",
    init: () =>
      updateData(
        { state: "INIT", data: {} }
      ),
    update: (state: Data, msg) => {
      switch (msg.tag) {
        case WISHLIST_TOGGLE_ADD_PRODUCT_REQUEST:
        case WISHLIST_TOGGLE_REMOVE_PRODUCT_REQUEST:
          return updateData(
            { state: "LOADING", data: {
              ...state.data,
            } },
            msg
          );

        case WISHLIST_TOGGLE_ADD_PRODUCT_RESPONSE:
          if (msg.error) {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.data) {
            return updateData({ state: "LOADED", data: {
              ...state.data,
              [msg.sku]: msg.data.wishlistItem,
            } });
          }

          break;
        case WISHLIST_TOGGLE_REMOVE_PRODUCT_RESPONSE:
          if (typeof msg.error === "string") {
            return updateData({ state: "ERROR", error: msg.error });
          }

          if (msg.sku) {
            return updateData({ state: "LOADED", data: {
              ...state.data,
              [msg.sku]: null,
            } });
          }

          break;

        case WISHLIST_REMOVE_PRODUCT_RESPONSE:
          if (msg.sku) {
            return updateData({ state: "LOADED", data: {
              ...state.data,
              [msg.sku]: null,
            } });
          }

          break;
        default:
      }
    },
  };
