/* @flow */

import type { Storage, Message, EffectErrorMessage } from "crustate";

import { EFFECT_ERROR } from "crustate";
import { addMessage } from "@crossroads/shop-state/messages";
import { logout } from "state/customer";

const registerClient = (storage: Storage) => {
  storage.addListener("messageQueued", (msg: Message) => {
    if (msg.tag === EFFECT_ERROR) {
      const { error } = ((msg: any): EffectErrorMessage);
      const errors = typeof error === "object" && error !== null ? error.errors : [];
      const firstError = Array.isArray(errors) ? errors[0] : null;

      if (firstError == null || typeof firstError !== "object") {
        return;
      }

      const { extensions } = firstError;

      if (extensions == null || typeof extensions !== "object") {
        return;
      }

      if (extensions.category === "sas") {
        switch (extensions.code) {
          case "invalid_sso_ticket":
            storage.broadcastMessage(addMessage(extensions.code, "error"));
            return storage.broadcastMessage(logout("/"));
          default:
            break;
        }
      }
    }
  });
};

export default registerClient;
