/* @flow */

import { useEffect } from "react";
import { useData, useSendMessage } from "crustate/react";
import { getQuoteData } from "state/quote";
import { useClient } from "entrypoint/shared";
import { quotePointsSetToMaximum } from "queries";
import { syncQuote } from "@crossroads/shop-state/quote";
import { QuoteData } from "data";

const useQuotePointsSetToMaximum = () => {
  const quoteData = useData(QuoteData);
  const client = useClient();
  const sendMessage = useSendMessage();
  const { selectedPointPayment } = getQuoteData(quoteData) || {};

  useEffect(() => {
    if (quoteData.state === "LOADED" && !selectedPointPayment) {
      (async () => {
        const { quotePointsSetToMaximum: { quote } } =
          await client(quotePointsSetToMaximum, { id: "sas_extrapoints" });

        if (quote) {
          sendMessage(syncQuote(quote));
        }
      })();
    }
  }, [selectedPointPayment, quoteData.state]);
};

export default useQuotePointsSetToMaximum;
