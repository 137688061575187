/* @flow */

// import type { Customer } from "shop-state/types";

import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { useTranslate } from "@awardit/react-use-translate";
import Wrapper from "components/Wrapper";
import Wishlist from "components/AccountView/Wishlist";
import CountryModal from "components/CountryModal";
import useCustomer from "helpers/use-customer";
import useFormat from "helpers/use-format";
import { StoreInfoContext } from "entrypoint/shared";
import { useSendMessage } from "crustate/react";
// Import OrderHistory from "./OrderHistory";
import { logout } from "@crossroads/shop-state/customer";
import { languages } from "components/LanguageSwitcher";
// Import WishlistItem from "components/WishlistItem";

// import ShippingAddress from "./ShippingAddress";
// import ElapsingPoints from "./ElapsingPoints";
import Breadcrumbs from "components/Breadcrumbs";
import LinkIcon from "icons/link.svg";

import IconLangSE from "icons/lang/SE.svg";
import IconLangDK from "icons/lang/DK.svg";
import IconLangNO from "icons/lang/NO.svg";
import IconLangOther from "icons/lang/Other.svg";

// Type Props = {
//   customer: Customer,
// };

const iconByLang = (lang: string) => {
  switch (lang) {
    case "se":
      return <IconLangSE className={styles.langIcon} />;
    case "no":
      return <IconLangNO className={styles.langIcon} />;
    case "dk":
      return <IconLangDK className={styles.langIcon} />;
    default:
      return <IconLangOther className={styles.langIcon} />;
  }
};

import styles from "./styles.scss";

const AccountView = () => {
  const { formatPoints, formatDate } = useFormat();
  const sendMessage = useSendMessage();
  const t = useTranslate();
  const { customer } = useCustomer();
  const { info: { locale } } = useContext(StoreInfoContext);
  const [countryOpen, setCountryOpen] = useState(false);

  if (!customer) {
    return null;
  }

  const primaryAddress = customer.addresses.filter(x => x.isDefaultShipping === true)[0];
  const lang = languages.find(x => x.lang === locale.replace(/_/, "-")) || languages[0];
  const { expiringSasPoints, sasExtraPoints } = customer;

  const expiringPointsData =
    expiringSasPoints
      .filter(p => Date.parse(p.expirationDate) > new Date())
      .sort((a, b) => Date.parse(a.expirationDate) - Date.parse(b.expirationDate))
      .filter((p, i, a) => p.expirationDate === a[0].expirationDate);

  const expiringPointsDataTotal = expiringPointsData.reduce((a, b) => a + b.points, 0);

  return (
    <div className={styles.block}>
      <Helmet
        title={t("ACCOUNT.MY_ACCOUNT")}
      />
      <Wrapper>
        <div className={styles.top}>
          <Breadcrumbs
            className={styles.breadcrumbs}
            current={t("ACCOUNT.MY_ACCOUNT")}
          />

          <span
            className={styles.logout}
            onClick={() => sendMessage(logout())}
          >
            {t("ACCOUNT.LOGOUT")}
          </span>
        </div>

        <h1 className={styles.heading}>{t("ACCOUNT.MY_ACCOUNT")}</h1>

        <div className={styles.split}>
          <div>
            <h3 className={styles.boxHeading}>{t("ACCOUNT.ACCOUNT_INFORMATION")}</h3>
            <div className={styles.box}>
              <table>
                <tbody>
                  <tr>
                    <td>{t("ACCOUNT.MEMBERSHIP_LEVEL")}</td>
                    <td>{t(`ACCOUNT.TIER.${customer.sasIncentiveLevel}`)}</td>
                  </tr>
                  <tr>
                    <td>{t("ACCOUNT.MEMBER_NUMBER")}</td>
                    <td>EB{customer.sasEbNo}</td>
                  </tr>
                  <tr>
                    <td>{t("ACCOUNT.OWNER")}</td>
                    <td>{customer.firstname}&nbsp;{customer.lastname[0]}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div>
            <h3 className={styles.boxHeading}>{t("ACCOUNT.BALANCE")}</h3>
            <div className={styles.box}>
              <table>
                <tbody>
                  <tr>
                    <td>{t("ACCOUNT.TOTAL_POINTS")}</td>
                    <td>{formatPoints(sasExtraPoints)}</td>
                  </tr>
                  { expiringPointsData && expiringPointsData.length > 1 &&
                    <>
                      <tr>
                        <td>{t("ACCOUNT.ELAPSED_POINTS")}</td>
                        <td>{formatPoints(expiringPointsDataTotal)}</td>
                      </tr>
                      <tr>
                        <td>{t("ACCOUNT.DATE")}</td>
                        <td>{formatDate(expiringPointsData[0].expirationDate)}</td>
                      </tr>
                    </>
                  }
                </tbody>
              </table>
            </div>
            <div className={styles.box}>
              <div className={styles.split2}>
                <span>{t("ACCOUNT.BUY_AND_TRANSFER_POINTS")}</span>
                <Link to="/account/buy-and-transfer-points">
                  {t("ACCOUNT.READ_MORE")}
                </Link>
              </div>
            </div>
          </div>
          <div>
            <h3 className={styles.boxHeading}>{t("ACCOUNT.SHIPPING_ADDRESS")}</h3>
            <div className={styles.box}>
              <div className={styles.split2}>
                <div>
                  <p><strong>{customer.firstname}&nbsp;{customer.lastname}</strong></p>
                  <p>{primaryAddress.street[0]}</p>
                  <p>{primaryAddress.postcode}, {primaryAddress.city}</p>
                  <p>{primaryAddress.country.name}</p>
                  <p>{primaryAddress.telephone}</p>
                  <p>{customer.email}</p>
                </div>
                <div>
                  <a href={t("ACCOUNT.EDIT_URL")} target="_blank" rel="noopener noreferrer">
                    {t("ACCOUNT.EDIT")}
                    <LinkIcon />
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.box}>
              <div className={styles.split2}>
                <div>
                  {iconByLang(lang.code)}
                  <span>{lang.title}</span>
                  <span className={styles.currency}>{lang.currency}</span>
                </div>
                <span className="link" onClick={() => setCountryOpen(true)}>{t("ACCOUNT.EDIT")}</span>
              </div>
            </div>
          </div>
        </div>

        { /* <OrderHistory className={styles.history} /> */ }
        <h3 className={styles.boxHeading}>{t("WISHLIST.TITLE")}</h3>

        <Wishlist className={styles.wishlist} />
      </Wrapper>

      <CountryModal open={countryOpen} setOpen={setCountryOpen} />
    </div>
  );
};

export default AccountView;
