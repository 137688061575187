/* @flow */

import React from "react";
import { useLocation } from "react-router-dom";
import useCookie from "helpers/use-cookie";
import cn from "classnames";
import styles from "./styles.scss";
import TickIcon from "icons/checkmark.svg";
import IconSE from "icons/lang/SE.svg";
import IconDK from "icons/lang/DK.svg";
import IconNO from "icons/lang/NO.svg";
import IconOther from "icons/lang/Other.svg";

export type Language = {
  url: string,
  icon: any,
  title: string,
  code: string,
  lang: string,
  currency: string,
};

type Props = {
  currentLang: Language,
  setCurrentLang: (lang: Language) => void,
  className?: string,
};

export const languages: Array<Language> = [
  { url: "/se", icon: IconSE, title: "Sweden", code: "se", lang: "sv-SE", currency: "SEK" },
  { url: "/dk", icon: IconDK, title: "Denmark", code: "dk", lang: "da-DK", currency: "DKK" },
  { url: "/no", icon: IconNO, title: "Norway", code: "no", lang: "nb-NO", currency: "NOK" },
  { url: "/en", icon: IconOther, title: "Other", code: "en", lang: "en-GB", currency: "EUR" },
];

const LanguageSwitcher = ({ currentLang, setCurrentLang, className }: Props) => {
  const cookieOpts = { domain: `.${typeof window !== "undefined" ? window.location.host : ""}`, expires: 3650 };
  const [cookieLang, setCookieLang] = useCookie("lang", currentLang.lang, cookieOpts);
  const { pathname } = useLocation();

  const setNewLang = (e: Event, lang: Language) => {
    if (lang.code === currentLang.code) {
      e.preventDefault();
      return;
    }

    if (cookieLang !== lang.lang) {
      setCookieLang(lang.lang);
    }

    setCurrentLang(lang);
  };

  return (
    <div className={cn(styles.block, className)}>
      {languages
        .slice()
        .sort((a, b) => a.title > b.title ? 1 : -1)
        .map(x => {
          const Icon = x.icon;

          return (
            <a
              key={x.code}
              className={cn(
                styles.item,
                { [styles.itemCurrent]: x.code === currentLang.code }
              )}
              href={`${x.url}${pathname}`}
              onClick={e => setNewLang(e, x)}
            >
              <Icon className={styles.flag} />
              <span>{x.title} ({x.currency})</span>
              {x.code === currentLang.code &&
              <TickIcon className={styles.tick} />}
            </a>
          );
        })}
    </div>
  );
};

export default LanguageSwitcher;
