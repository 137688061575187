/* @flow */

import React, { useState, useEffect } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { Input, Slider } from "@crossroads/ui-components";
import useFormat from "helpers/use-format";

type Props = {
  title: string,
  subTitle: string,
  footerTitle: string,
  points: number,
  setPoints: number => void,
  minValue: number,
  maxValue: number,
  TooltipSlot?: React$Node,
  remainingPrice?: number,
  processing?: boolean,
};

const PointSelection = ({
  title, subTitle, footerTitle, TooltipSlot, remainingPrice,
  points, setPoints, minValue, maxValue, processing = false,
}: Props
) => {
  const t = useTranslate();
  const { formatPoints, formatPrice } = useFormat();
  const [input, setInput] = useState(points.toString());

  useEffect(() => {
    setInput(points.toString());
  }, [points]);

  return (
    <>
      <header className={styles.header}>
        <h3 className={styles.heading}>{title}</h3>
        {TooltipSlot !== undefined && TooltipSlot}
      </header>

      {subTitle &&
        <p className={styles.subHeading}>{subTitle}</p>
      }

      <section className={styles.body}>

        <div className={styles.pointSelect}>
          <div className={styles.slider}>
            <Slider
              value={points}
              minValue={minValue}
              maxValue={maxValue}
              onChange={v => setPoints(v)}
            />

            <div className={styles.sliderLabels}>
              <span>{formatPoints(minValue)}</span>
              <span>{formatPoints(maxValue)}</span>
            </div>
          </div>
          <Input
            label={t("CHECKOUT.POINTS")}
            variant="float"
            name="points"
            value={input.toString()}
            onChange={(e: SyntheticEvent<HTMLInputElement>) => {
              setInput(e.currentTarget.value);
            }}
            onBlur={(e: SyntheticEvent<HTMLInputElement>) => {
              const value = Math.min(
                Math.max(
                  parseInt(e.currentTarget.value, 10) || 0,
                  minValue
                ),
                maxValue
              );
              setPoints(value);
              setInput(value);
            }}
          />
        </div>
      </section>

      <hr />

      <footer className={cn(styles.footer, { [styles.processing]: processing })}>
        <h3>{footerTitle}</h3>
        <h3>
          {formatPoints(points)}
          {(remainingPrice !== undefined && remainingPrice > 0) &&
            <span className={styles.remainder}>+ {formatPrice(remainingPrice)}</span>
          }
        </h3>
      </footer>
    </>
  );
};

export default PointSelection;
