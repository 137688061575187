/* @flow */

import type { ProductCardProduct } from "shop-state/types";

import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useData, useSendMessage } from "crustate/react";
import { useCurrentSrc } from "helpers/utils";
import { useTranslate } from "@awardit/react-use-translate";
import { removeFromWishlist } from "state/wishlist";
import { pointsPriceByID } from "helpers/points";
import PriceSplit from "components/PriceSplit";
import {
  WishlistData,
  // CustomerData,
} from "data";
import PaginationBar from "components/AccountView/PaginationBar";
import LoadingView from "components/LoadingView";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const ITEMS_PER_PAGE = 4;

const filterItems = (currentPage: number) =>
  (_, idx) =>
    idx >= (currentPage - 1) * ITEMS_PER_PAGE && idx < currentPage * ITEMS_PER_PAGE;

const Item = ({ product }: { product: ProductCardProduct }) => {
  const t = useTranslate();
  const wishlistData = useData(WishlistData);
  const sendMessage = useSendMessage();
  const imageRef = useRef();

  // Default to smallest image
  const { tinyImage, smallImage, mediumImage, largeImage } = product.attributes;
  const defaultImage = tinyImage || smallImage || mediumImage || largeImage || { x1: "", x2: "" };
  const image = useCurrentSrc(imageRef, defaultImage.x1);
  const pointsPrice = pointsPriceByID(product.pointsPrices, "sas_extrapoints");

  if (pointsPrice === null || typeof pointsPrice === "undefined") {
    return null;
  }

  return (
    <div className={styles.col}>
      <div className={cn(styles.item, { [styles.loading]: wishlistData.state === "UPDATING" })}>
        <div className={styles.left}>
          <img src={product.attributes.tinyImage?.x1} alt={product.name} className={styles.image} />
        </div>
        <div className={styles.right}>
          <header className={styles.info}>
            <Link
              key={product.sku}
              className={styles.name}
              to={{
                pathname: product.url,
                state: { hint: { type: "product", image, product } },
              }}
            >
              {product.name}
            </Link>
            <span className={styles.brand}>{product.attributes.manufacturer}</span>
            <PriceSplit className={styles.price} pointsPrice={pointsPrice} />
          </header>
          <footer className={styles.links}>
            <span
              onClick={() => sendMessage(removeFromWishlist(product.wishlist.itemId, product.sku))}
            >
              {t("WISHLIST.REMOVE_FROM_WISHLIST")}
            </span>
          </footer>
        </div>
      </div>
    </div>
  );
};

const Wishlist = ({ className }: Props) => {
  const t = useTranslate();
  const itemsContainer = useRef();
  const wishlistData = useData(WishlistData);
  // const customerData = useData(CustomerData);
  // const balance = customerData.data ? customerData.data.scandic.points : 0;
  // const affordable = points => points < balance;
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageCount, setPageCount] = useState<number>(1);
  const [containerHeight, setContainerHeight] = useState<number>(0);
  const items = wishlistData.state === "LOADED" || wishlistData.state === "UPDATING" ?
    wishlistData.data.items.filter(filterItems(currentPage)) : [];

  useEffect(() => {
    if (wishlistData.state === "LOADED") {
      const _pageCount = Math.ceil(wishlistData.data.totalCount / ITEMS_PER_PAGE);
      setPageCount(_pageCount);

      if (_pageCount < currentPage) {
        setCurrentPage(_pageCount);
      }
    }
  }, [wishlistData]);

  if (itemsContainer.current &&
    (wishlistData.state === "LOADED" || wishlistData.state === "UPDATING") &&
    wishlistData.data.items.length >= ITEMS_PER_PAGE) {
    const { height } = itemsContainer.current.getBoundingClientRect();

    if (height > containerHeight) {
      setContainerHeight(height);
    }
  }
  else if (containerHeight > 0) {
    setContainerHeight(0);
  }

  return (
    <div className={cn(styles.block, className)}>
      <div
        ref={itemsContainer}
        style={containerHeight > 0 ? { minHeight: containerHeight + "px" } : {}}
        className={styles.items}
      >
        {wishlistData.state !== "LOADED" &&
          <LoadingView />
        }

        {(wishlistData.state === "LOADED" || wishlistData.state === "UPDATING") &&
          wishlistData.data.items.length > 0 &&
          items.map(x => <Item key={x.sku} product={x} />)
        }

        {(wishlistData.state === "LOADED" || wishlistData.state === "UPDATING") && wishlistData.data.items.length === 0 &&
          <div>
            <p>{t("WISHLIST.EMPTY")}</p>
            <Link className={styles.explore} to="/">{t("WISHLIST.EXPLORE_ASSORTMENT")}</Link>
          </div>
        }
      </div>

      {pageCount > 1 &&
        <PaginationBar
          className={styles.paginationBar}
          currentPage={currentPage}
          setPage={setCurrentPage}
          pageCount={pageCount}
        />
      }
    </div>
  );
};

export default Wishlist;
