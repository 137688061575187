/* @flow */

import React, { useState, useEffect, useContext } from "react";
import cn from "classnames";
import { Helmet } from "react-helmet-async";
import { Route, Switch, useHistory } from "react-router";
import { AnalyticsContext } from "@crossroads/analytics";
import { useTranslate } from "@awardit/react-use-translate";
import { StoreInfoContext, useClient } from "entrypoint/shared";
import { confirmStripePaymentIntent, createStripePaymentIntent } from "queries";
import Redirect from "@crossroads/react-router-redirect";
import { setQuotePaymentMethodStripe } from "@crossroads/shop-state/quote";
import { getQuoteData } from "state/quote";
import { useData, useSendMessage } from "crustate/react";
import { canAffordOrder } from "helpers/utils";
import { QuoteData, OTPData } from "data";
import { StripePaymentProvider, STRIPE_PAYMENT_METHOD_CODE } from "@crossroads/stripe";
import Wrapper from "components/Wrapper";
import Header from "components/AppHeader/StepHeader/checkout-header";
import { Button } from "@crossroads/ui-components";
import useCustomer from "helpers/use-customer";
import useCheckoutSteps from "helpers/use-checkout-steps";
import ArrowIcon from "icons/arrow.svg";

import Cart from "components/CheckoutView/Cart";
import Step1 from "components/CheckoutView/Step1";
import Step2, { shouldShowStep2 } from "components/CheckoutView/Step2";
import Step3 from "components/CheckoutView/Step3";

import styles from "./styles.scss";

type Props = {
  match: {
    params: {
      step?: string,
    },
  },
};

const CheckoutView = ({ match }: Props) => {
  const t = useTranslate();
  const gaContext = useContext(AnalyticsContext);
  const sendMessage = useSendMessage();
  const history = useHistory();
  const storeInfo = useContext(StoreInfoContext);
  const quoteData = useData(QuoteData);
  const [open, setOpen] = useState(true);
  const [visited, setVisited] = useState([]);
  const client = useClient();
  const quoteState = useData(QuoteData);
  const quote = getQuoteData(quoteState);
  const { customer } = useCustomer();
  const showStep2 = shouldShowStep2(quote, customer);
  const [OTPModalOpen, setOTPModalOpen] = useState(false);
  const onCart = typeof match.params.step === "undefined";

  const { currentStep } = useCheckoutSteps();
  const step = currentStep.key;

  useEffect(() => {
    setVisited([...visited, step]);
  }, []);

  useEffect(() => {
    if (step === 0) {
      return;
    }

    if (step === 1) {
      if (quote) {
        gaContext.registerBeginCheckoutProcess(
          quote.items,
          quote.grandTotal.incVat
        );
      }
    }

    gaContext.registerCheckoutStep(
      step,
      currentStep.id
    );
  }, [step]);

  // Set Stripe as payment method if grandTotal > 0
  useEffect(() => {
    if (quote && quote.grandTotal.incVat > 0) {
      const { payment } = quote;
      const paymentCode = payment && payment.code;
      const stripeKey = payment && payment.code === STRIPE_PAYMENT_METHOD_CODE ?
        payment.publishableKey :
        "";

      // Set payment method to stripe if selected
      if (paymentCode !== STRIPE_PAYMENT_METHOD_CODE && !stripeKey) {
        sendMessage(setQuotePaymentMethodStripe());
      }
    }
  }, [quote]);

  if (quoteData.state === "LOADING" || !quote || !customer) {
    return null;
  }

  if (quoteData.state === "LOADED" && quoteData.data.items.length === 0) {
    return (
      <div className={styles.block}>
        <Header />

        <Wrapper>
          <header className={styles.header}>
            <h1 className={styles.heading}>{t("CART.EMPTY")}</h1>
            <Button
              className={cn(styles.back, "link")}
              slotLeft={<ArrowIcon style={{ transform: "rotate(180deg)" }} />}
              onClick={() => history.push("/")}
            >
              {t("CHECKOUT.CONTINUE_SHOPPING")}
            </Button>
          </header>
        </Wrapper>
      </div>
    );
  }

  const stripeKey = quote.payment && quote.payment.code === STRIPE_PAYMENT_METHOD_CODE ?
    quote.payment.publishableKey :
    "";

  const canAfford = canAffordOrder(quote, customer);

  if (!onCart && !canAfford) {
    history.push("/checkout");
  }

  return (
    <div className={styles.block}>
      <Helmet
        title={currentStep ? currentStep.title : t("CHECKOUT.STEPS.CART.SHORT")}
      />

      <Header />

      <Wrapper>
        <header className={styles.header}>
          <h1 className={styles.heading}>
            {currentStep ? currentStep.titleLong : t("CHECKOUT.STEPS.CART.LONG")}
          </h1>
          <Button
            className={cn("link", styles.back)}
            onClick={() => history.push("/")}
          >
            {t("CHECKOUT.CONTINUE_SHOPPING")}
          </Button>
        </header>

        <Switch>
          <Route
            exact
            path="/checkout/3"
            render={() => (
              <OTPData.Provider>
                <StripePaymentProvider
                  client={client}
                  amountToPay={quote.grandTotal.incVat}
                  stripeKey={stripeKey}
                  confirmStripePaymentIntentQuery={confirmStripePaymentIntent}
                  createStripePaymentIntentQuery={createStripePaymentIntent}
                  storeInfo={storeInfo.info}
                >
                  <Step3
                    step={3}
                    open={open}
                    setOpen={setOpen}
                    quoteData={quoteData}
                    OTPModalOpen={OTPModalOpen}
                    setOTPModalOpen={setOTPModalOpen}
                  />
                </StripePaymentProvider>
              </OTPData.Provider>
            )}
          />

          <Route
            exact
            path="/checkout/1"
            render={() => canAfford ?
              <Step1 step={1} open={open} setOpen={setOpen} quoteData={quoteData} /> :
              <Redirect to="/checkout" />
            } />
          <Route
            exact
            path="/checkout/2"
            render={() => canAfford && showStep2 ?
              <Step2 step={2} open={open} setOpen={setOpen} quoteData={quoteData} /> :
              <Redirect to="/checkout" />
            } />
          <Route path="/checkout" render={() => <Cart step={0} open={open} setOpen={setOpen} quoteData={quoteData} />} />
        </Switch>
      </Wrapper>
    </div>
  );
};

export default CheckoutView;
