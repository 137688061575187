/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type { OrderHistoryResponse, OrderHistoryRequest } from "state/order-history";

import { ORDER_HISTORY_REQUEST, ORDER_HISTORY_RESPONSE } from "state/order-history";
import { orderHistory } from "queries";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async (msg: OrderHistoryRequest) => {
      const { customer } = await client(
        orderHistory,
        { pageSize: msg.pageSize, page: msg.page },
        { cache: true }
      );

      return ({
        tag: ORDER_HISTORY_RESPONSE,
        data: customer && customer.orders,
      }: OrderHistoryResponse);
    },
    subscribe: { [ORDER_HISTORY_REQUEST]: true },
  });
};

export default registerClient;
