/* @flow */

import type { QuoteResponse } from "@crossroads/shop-state/quote";

import React, { useEffect, useState, useContext } from "react";
import cn from "classnames";
import styles from "./styles.scss";
import { Link, useHistory } from "react-router-dom";
import { useClient } from "entrypoint/shared";
import { QUOTE_PLACE_ORDER_RESPONSE, syncQuote } from "@crossroads/shop-state/quote";
import { resetOTP, SET_OTP_RESPONSE } from "state/otp";
import { StripeContext, STRIPE_PAYMENT_METHOD_CODE, useInitStripeQuotePaymentMethod } from "@crossroads/stripe";
import { Form, rules, isRequired } from "@awardit/formaggio";
import { getQuoteData } from "state/quote";
import { syncCustomer } from "@crossroads/shop-state/customer";
import { useTranslate } from "@awardit/react-use-translate";
import useFormat from "helpers/use-format";
import useCustomer from "helpers/use-customer";
import { QuoteData, OTPData } from "data";
import { useSendMessage, useData } from "crustate/react";
import { addMessage, addMessageTranslated } from "@crossroads/shop-state/messages";
import Container from "components/CheckoutView/Container";
import { shouldShowStep2 } from "components/CheckoutView/Step2";
import LoadingView from "components/LoadingView";
import CustomerServiceLink from "components/CheckoutView/CustomerServiceLink";
import { Foldable } from "@crossroads/ui-components";
import PaymentMethods from "components/PaymentMethods";
import ChevronIcon from "icons/chevron.svg";
import CartSummary from "components/CartSummary";
import OTPModal from "components/OTPModal";
import Button from "components/Button";
import useQuotePointsSetToMaximum from "helpers/use-quote-points-set-to-maximum";
import { useUi } from "helpers/ui";
import { MODE as VIEW_MODE } from "state/view-mode";
import { getCustomerPoints, logout } from "state/customer";
import {
  placeOrder,
  quote as quoteQuery,
  customer as customerQuery,
  hasSasOtp,
} from "queries";

type Props = {
  open: boolean,
  setOpen: boolean => void,

  OTPModalOpen: boolean,
  setOTPModalOpen: boolean => void,
};

const validation = rules([
  isRequired("terms"),
]);

// eslint-disable-next-line complexity
const Step3 = ({ open, setOpen, OTPModalOpen, setOTPModalOpen }: Props) => {
  const t = useTranslate();
  const client = useClient();
  const { push } = useHistory();
  const sendMessage = useSendMessage();
  const { formatPoints, formatPrice } = useFormat();
  const { customer, redirectToLogin } = useCustomer();
  const customerBalance = getCustomerPoints(customer);
  const OTPState = useData(OTPData);
  const quoteData = useData(QuoteData);
  const [state, setState] = useState({ terms: false });
  const [processing, setProcessing] = useState(false);
  const { stripe, stripePaymentReq, payWithStripe } = useContext(StripeContext);
  const quote = getQuoteData(quoteData);
  const showStep2 = shouldShowStep2(quote, customer);
  const formErrors = Array.isArray(quote?.validationErrors) && quote.validationErrors.length > 0;
  const errors = validation((state: any));
  const [openAccordion, setOpenAccordion] = useState("");
  const [checkingHasOtp, setCheckingHasOtp] = useState(false);
  const { viewMode } = useUi();

  useInitStripeQuotePaymentMethod(quoteData.data || null);
  useQuotePointsSetToMaximum();

  const handleResize = () => {
    setOpenAccordion("");
  };

  // Open summary when this route renders
  useEffect(() => {
    setOpen(true);
  }, [setOpen]);

  useEffect(() => {
    if (process.browser) {
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  if (!quoteData.data) {
    return null;
  }

  const { items, payment, addresses, selectedPointPayment } = quoteData.data;

  const showOTPModal = selectedPointPayment ? selectedPointPayment.points.value.incVat > 0 : true;
  const pointsPrice = quote && quote.selectedPointPayment;
  const minimumPoints = pointsPrice?.points.min.incVat;
  const address = addresses.find(x => x.type === "shipping" || (x.type === "billing" && x.isUsedAsShipping));

  if (!address) {
    return null;
  }

  const addressLabel = [
    address.firstname, address.lastname, address.street[0], address.postcode, address.city,
  ].join(" ");

  const checkHasOtp = async () => {
    setCheckingHasOtp(true);

    try {
      const result = await client(hasSasOtp);

      if (result.hasSasOtp) {
        sendMessage({ tag: SET_OTP_RESPONSE });
      }
      else {
        setOTPModalOpen(true);
      }
    }
    catch (e) {
      setOTPModalOpen(true);
    }
    finally {
      setCheckingHasOtp(false);
    }
  };

  const submit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!payment) {
      return;
    }

    setProcessing(true);

    try {
      if (payment.code === STRIPE_PAYMENT_METHOD_CODE) {
        await payWithStripe();
      }

      const placeOrderData = await client(placeOrder);
      const newQuoteData = await client(quoteQuery);
      const newQuote = newQuoteData.quote;

      if (placeOrderData && placeOrderData.placeOrder.result !== "success") {
        sendMessage(addMessage(placeOrderData.placeOrder.result, "error"));

        // Sync quote and return
        if (newQuote) {
          sendMessage(syncQuote(newQuote));
        }

        return;
      }

      // Sync new customer data to get the new point balance etc
      await client(customerQuery).then(({ customer }) => {
        sendMessage(syncCustomer(customer));
      });

      // Sync customer to get the new point balance
      sendMessage(syncQuote(newQuote));

      if (newQuote) {
        sendMessage(({
          tag: QUOTE_PLACE_ORDER_RESPONSE,
          data: newQuote,
        }: QuoteResponse));
      }

      push("/checkout/success");
    }
    catch (e_) {
      sendMessage(addMessageTranslated(e_.message, "error"));
      const error = e_.errors[0];

      if (error) {
        if (error.extensions.category === "sas") {
          switch (error.extensions.code) {
            case "invalid_otp":
              sendMessage(resetOTP());
              setOTPModalOpen(true);
              break;
            case "invalid_sso_ticket":
              sendMessage(logout("/"));
              redirectToLogin();
              break;
            default:
              break;
          }
        }
      }
    }
    finally {
      setProcessing(false);
    }
  };

  if (!selectedPointPayment) {
    return <LoadingView />;
  }

  return (
    <Container
      right={
        <div>
          <CartSummary
            open={open}
            setOpen={setOpen}
            toPayPoints={selectedPointPayment.points.selected.incVat}
            toPayPrice={selectedPointPayment.currency.remaining.incVat}
            allowBackNavigation={false}
          >
            <Form
              value={(state: any)}
              errors={errors}
              onSubmit={submit}
              onChange={x => {
                setState({ ...state, ...(x: any) });
              }}
            >

              {showOTPModal && viewMode !== VIEW_MODE.LOGIN_MODAL &&
                <>
                  {OTPState.state === "PENDING" &&
                    <div className={styles.otp}>
                      <Button
                        variant="primary"
                        loading={checkingHasOtp}
                        onClick={checkHasOtp}
                      >
                        {t("CHECKOUT.CHOOSE_PAYMENT_METHOD")}
                      </Button>
                    </div>
                  }

                  {OTPState.state !== "SET" && OTPState.state !== "SETTING" &&
                    <OTPModal open={OTPModalOpen} setOpen={setOTPModalOpen} />
                  }
                </>
              }

              {OTPState.state === "SETTING" && <LoadingView />}

              {(!showOTPModal || OTPState.state === "SET") &&
                <div className={styles.paymentMethods}>
                  <PaymentMethods
                    terms={state.terms}
                    disabled={formErrors}
                    paymentMethodCode={payment?.code || ""}
                    loading={!stripe || !stripePaymentReq}
                    processing={processing} />
                </div>
              }
            </Form>

          </CartSummary>
          <CustomerServiceLink />
        </div>
      }
    >

      <div className={styles.accordion}>
        <header
          className={styles.accordionHeader}
          onClick={() => setOpenAccordion(openAccordion === "products" ? "" : "products")}
        >
          <div>
            <p className={styles.title}>
              {t("CHECKOUT.ACCORDION_HEADER_CART")}
              <span>
              &nbsp;({items.length})
              </span>
            </p>
          </div>
          <ChevronIcon
            className={cn(styles.closeIcon, { [styles.open]: openAccordion === "products" })}
          />
        </header>
        <Foldable open={openAccordion === "products"}>
          <div className={styles.items}>
            {items.map(x => {
              const product = x.configOption ? {
                ...x.product,
                ...x.configOption.product,
              } : x.product;

              if (!x.selectedPointPayment) {
                return null;
              }

              return (
                <div key={x.itemBuyRequest} className={styles.item}>
                  <div className={styles.left}>
                    <img
                      className={styles.image}
                      alt={product.name}
                      src={product.attributes.image?.x1} />
                    <div className={styles.info}>
                      <div>
                        <p className={styles.name}>{product.name}</p>
                        <p className={styles.brand}>{product.attributes.manufacturer}</p>
                      </div>
                      <span className={styles.price}>
                        {formatPoints(x.selectedPointPayment.points.max.incVat || 0)}
                      </span>
                    </div>
                  </div>

                  <div className={styles.right}>
                    <Link className={styles.link} to="/checkout">
                      {t("CHECKOUT.EDIT")}
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </Foldable>
      </div>

      <h3 className={styles.heading}>{t("CHECKOUT.PAYMENT_AND_DELIVERY")}</h3>

      <div className={styles.summary}>
        <div className={styles.summaryRow}>
          <div className={styles.left}>
            <div className={styles.info}>
              <div>
                <p className={styles.title}>{t("CHECKOUT.DISTRIBUTION_POINTS")}</p>
                <p className={styles.subTitle}>
                  {formatPoints(selectedPointPayment.points.selected.incVat)}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            {(minimumPoints !== customerBalance && customerBalance !== 0 && showStep2) &&
              <Link className={styles.link} to="/checkout/2">
                {t("CHECKOUT.EDIT")}
              </Link>
            }
          </div>
        </div>

        <div className={styles.summaryRow}>
          <div className={styles.left}>
            <div className={styles.info}>
              <div>
                <p className={styles.title}>{t("CHECKOUT.DISTRIBUTION_CASH")}</p>
                <p className={styles.subTitle}>
                  {formatPrice(selectedPointPayment.currency.remaining.incVat)}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            {(minimumPoints !== customerBalance && customerBalance !== 0 && showStep2) &&
              <Link className={styles.link} to="/checkout/2">
                {t("CHECKOUT.EDIT")}
              </Link>
            }
          </div>
        </div>

        <div className={styles.summaryRow}>
          <div className={styles.left}>
            <div className={styles.info}>
              <div>
                <p className={styles.title}>{t("CHECKOUT.SENT_TO")}</p>
                <p className={styles.subTitle}>
                  {addressLabel}
                </p>
              </div>
            </div>
          </div>
          <div className={styles.right}>
            <Link className={styles.link} to="/checkout/1">
              {t("CHECKOUT.EDIT")}
            </Link>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default Step3;
