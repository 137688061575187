/* @flow */

import type { StatePath, Message } from "crustate";

/* eslint-disable import/no-unassigned-import */
import "core-js/features/object/assign";
import "core-js/features/map";
import "what-input";
import "whatwg-fetch";
/* eslint-enable import/no-unassigned-import */

// We need to initialize this early before we import any of the fetch wrappers
// or other resources to ensure RUM installs correctly:
import { datadogRum } from "@datadog/browser-rum";
import { googleAnalytics, datadog } from "../config";

const datadogIsEnabled = false;

if (datadogIsEnabled && datadog) {
  datadogRum.init({
    ...datadog,
    trackInteractions: true,
  });
}

import { APP_KEY, StoreInfoContext, ClientProvider, TransferClientProvider, createRunQuery } from "entrypoint/shared";
import { registerClient } from "../effects";
import registerTransferPointsClient from "../effects/transfer";

import React from "react";
import { hydrate } from "react-dom";
import { Router } from "react-router";
import { WithAnalytics } from "@crossroads/analytics";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";
import { watch } from "@crossroads/dev-server";
import { Storage } from "crustate";
import { StorageProvider } from "crustate/react";
import { createClient } from "@awardit/graphql-ast-client";
import { TranslationProvider } from "@awardit/react-use-translate";
import translation from "translation";
import Routes from "components/Routes";
import {
  QuoteData, CustomerData, RouteData, HomeData, OrderData, CheckoutStepData,
  MessagesData, BrandData, SearchData, OrderHistoryData, ViewModeData, PopularData, WishlistData,
  WishlistToggleData, AllProductsData, CmsData, AdventCalendarData, OTPData, TransferData,
} from "../data";
import { markHydrated } from "@crossroads-loyalty-solutions/react-use-browser";

import "theme/_generic/_fonts.scss";
import "theme/_generic/_normalize.scss";
import "theme/_generic/_base.scss";
import "theme/_generic/_typography.scss";

const history = createBrowserHistory({ basename: "/" + window.shop.country });

const storage = new Storage();

if (window.snapshot) {
  storage.addModel(QuoteData.model);
  storage.addModel(CustomerData.model);
  storage.addModel(OrderData.model);
  storage.addModel(RouteData.model);
  storage.addModel(HomeData.model);
  storage.addModel(CheckoutStepData.model);
  storage.addModel(MessagesData.model);
  storage.addModel(BrandData.model);
  storage.addModel(PopularData.model);
  storage.addModel(CmsData.model);
  storage.addModel(SearchData.model);
  storage.addModel(OrderHistoryData.model);
  storage.addModel(ViewModeData.model);
  storage.addModel(WishlistData.model);
  storage.addModel(WishlistToggleData.model);
  storage.addModel(AllProductsData.model);
  storage.addModel(AdventCalendarData.model);
  storage.addModel(OTPData.model);
  storage.addModel(TransferData.model);
  storage.restoreSnapshot(window.snapshot);

  delete window.snapshot;
}

const client = createClient({
  runQuery: createRunQuery(fetch, `/${window.shop.country}`),
  debounce: 5,
});

const transferClient = createClient({
  runQuery: createRunQuery(fetch, `/${window.shop.country}/`, "/transfer"),
  debounce: 5,
});

registerClient(storage, client, history);
registerTransferPointsClient(storage, transferClient);

window.storage = storage;
window.getState = storage.getSnapshot.bind(storage);

const events = {
  unhandledMessage: "warn",
  // stateCreated: "info",
  // stateRemoved: "info",
  // stateNewData: "info",
  // snapshotRestore: "info",
  // messageQueued: "info",
  // messageMatched: "debug",
  // snapshotRestored: "debug",
};

Object.keys(events).forEach(eventName => {
  const level = events[eventName];
  storage.addListener((eventName: any), (...data) => console[level](eventName, ...data));
});

const root = document.getElementById(APP_KEY);

if (!root) {
  throw new Error("Missing app root");
}

// Boot up watch-process in dev mode to make sure we reload
if (process.env.NODE_ENV !== "production") {
  watch();
}

if (datadogIsEnabled && datadog) {
  storage.addListener("updateError", (
    e: mixed,
    stateData: mixed,
    statePath: StatePath,
    message: Message
  ) => datadogRum.addError(e, { statePath, message }));

  storage.addListener("unhandledMessage", (
    message: Message,
    statePath: StatePath
  ) => datadogRum.addError(new Error(`Unhandled message '${message.tag}' from ${statePath.join(", ")}`), { statePath, message }));
}

const currencyCode = window.shop.info.baseCurrencyCode;

// history.listen((...args) => console.log(JSON.parse(JSON.stringify(args))));

hydrate(
  <StoreInfoContext.Provider value={window.shop}>
    <ClientProvider value={client}>
      <TransferClientProvider value={transferClient}>
        <HelmetProvider>
          <TranslationProvider translations={translation || {}}>
            <StorageProvider storage={storage}>
              <MessagesData.Provider>
                <QuoteData.Provider>
                  <CustomerData.Provider>
                    <WishlistToggleData.Provider>
                      <Router history={history}>
                        <WithAnalytics
                          currencyCode={currencyCode}
                          accounts={
                            googleAnalytics
                              .filter(x => x.store === window.shop.country)
                              .filter(x => x.currencyCode === currencyCode)
                              .map(x => x.accounts)[0] || []
                          }
                        >
                          <Routes />
                        </WithAnalytics>
                      </Router>
                    </WishlistToggleData.Provider>
                  </CustomerData.Provider>
                </QuoteData.Provider>
              </MessagesData.Provider>
            </StorageProvider>
          </TranslationProvider>
        </HelmetProvider>
      </TransferClientProvider>
    </ClientProvider>
  </StoreInfoContext.Provider>,
  root,
  () => {
    // console.log(storage.getSnapshot());
    markHydrated();
  }
);
