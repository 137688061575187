/* @flow */

import type { TransferProduct, Recipient, TransferResponse } from "state/transfer";
import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import {
  TRANSFER_LOAD_REQUEST, TRANSFER_LOAD_RESPONSE,
  TRANSFER_SET_POINTS_REQUEST, TRANSFER_SET_POINTS_RESPONSE,
  TRANSFER_SET_POINTS_AMOUNT_REQUEST, TRANSFER_SET_POINTS_AMOUNT_RESPONSE,
} from "state/transfer";
import { loadTransferQuote, setPointProduct, setPointProductWithEb, setPointsAmount } from "queries";
import { addMessage } from "@crossroads/shop-state/messages";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      try {
        const data = await client(loadTransferQuote);

        return ({
          tag: TRANSFER_LOAD_RESPONSE,
          data: data.quote,
        }: TransferResponse);
      }
      catch (e) {
        console.log(e);
      }
    },
    subscribe: { [TRANSFER_LOAD_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (
      {
        product,
        qty,
        recipient,
      }: {
        product: TransferProduct,
        qty: number,
        recipient: Recipient | null,
      }
    ) => {
      try {
        let data;
        if (recipient) {
          data = await client(setPointProductWithEb, { id: product.id, qty, eb: recipient.ebNo });
        }
        else {
          data = await client(setPointProduct, { id: product.id, qty });
        }

        if (data.setPointProduct.result !== "success") {
          storage.broadcastMessage(addMessage("TRANSFER_POINTS." + data.setPointProduct.result, "error"));

          return ({
            tag: TRANSFER_SET_POINTS_RESPONSE,
            error: "Cannot transfer to",
          }: TransferResponse);
        }

        return ({
          tag: TRANSFER_SET_POINTS_RESPONSE,
          points: qty,
          data: data.setPointProduct.quote,
        }: TransferResponse);
      }
      catch (e) {
        console.log(e);
      }
    },
    subscribe: { [TRANSFER_SET_POINTS_REQUEST]: true },
  });

  storage.addEffect({
    effect: async ({ points }: { points: number }) => {
      try {
        const data = await client(setPointsAmount, { points });

        return ({
          tag: TRANSFER_SET_POINTS_AMOUNT_RESPONSE,
          data: data ? data.setPointsAmount.quote : null,
        }: TransferResponse);
      }
      catch (e) {
        console.log(e);
      }
    },
    subscribe: { [TRANSFER_SET_POINTS_AMOUNT_REQUEST]: true },
  });
};

export default registerClient;
