/* @flow */

import type { BreadcrumbLink } from "@crossroads/ui-components";

import React from "react";
import cn from "classnames";
import Breadcrumbs from "components/Breadcrumbs";
import Wrapper from "components/Wrapper";

import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";

type Props = {
  title: string,
  description?: string,
  image?: {
    small?: {
      x1: string,
      x2: string,
    },
    medium?: {
      x1: string,
      x2: string,
    },
    large?: {
      x1: string,
      x2: string,
    },
  },
  totalCount: number,
  className?: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
};

type HintProps = {
  title: string,
  className?: string,
  breadcrumbLinks: $ReadOnlyArray<BreadcrumbLink>,
};

type PrepareImageSourcesProps = {
  small?: {
    x1: string,
    x2: string,
  },
  medium?: {
    x1: string,
    x2: string,
  },
  large?: {
    x1: string,
    x2: string,
  },
};

const prepareImageSources = ({ small, medium, large }: PrepareImageSourcesProps) => {
  const images = [
    {
      media: (medium || large) ? "(max-width: 550px)" : "",
      srcSet: small ? `${small.x1}, ${small.x2} 2x` : "",
    },
    {
      media: large ? "(max-width: 1057px)" : "",
      srcSet: medium ? `${medium.x1}, ${medium.x2} 2x` : "",
    },
    {
      media: "",
      srcSet: large ? `${large.x1}, ${large.x2} 2x` : "",
    },
  ].filter(img => img.srcSet);

  return images;
};

const ProductListHero = ({
  title,
  description,
  totalCount,
  image,
  className,
  breadcrumbLinks }: Props) => {
  const t = useTranslate();

  const { small: smallImage, medium: mediumImage, large: largeImage } = image || {};
  const gotImage = smallImage || mediumImage || largeImage || false;
  const imageSources = image ? prepareImageSources(image) : [];

  return (
    <div
      className={cn(
        styles.block,
        { [styles.block__full]: gotImage },
        className
      )}
    >
      <Wrapper className={styles.wrapper}>
        <div className={styles.body}>
          <div className={cn(styles.body__left, { [styles.absolute]: gotImage })}>
            <Breadcrumbs
              className={cn(styles.breakcrumbs, { [styles.fullWidth]: !gotImage })}
              links={breadcrumbLinks}
              current={title} />

            <div className={cn(styles.text, { [styles.fullWidth]: !gotImage })}>
              <div className={styles.titleWrapper}>
                <span>
                  {totalCount === 1 && t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_SINGULAR")}
                  {totalCount > 1 && t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_PLURAL", { itemsCount: totalCount })}
                  &nbsp;
                </span>
                <span className={styles.title}>{title}</span>
              </div>
              {description &&
                <p className={styles.description}>
                  {description}
                </p>
              }
            </div>
          </div>

          {gotImage &&
            <picture
              key={`${title}_heroimage`}
              className={styles.image}
              alt={`Logo ${title}`}
            >
              {imageSources.map(({ srcSet, media }) => (
                <source
                  key={srcSet}
                  srcSet={srcSet}
                  media={media}
                />
              ))}
              <img src={smallImage?.x1 || mediumImage?.x1 || largeImage?.x1 || ""} alt={`Logo ${title}`} />
            </picture>
          }
        </div>
      </Wrapper>
    </div>
  );
};

export const ProductListHeroHint = ({
  title,
  className,
  breadcrumbLinks }: HintProps) => {
  const t = useTranslate();

  return (
    <div className={cn(styles.block, className)}>
      <Wrapper className={styles.wrapper}>
        <div className={styles.body}>
          <div className={styles.body__left}>
            <Breadcrumbs
              className={styles.breakcrumbs}
              links={breadcrumbLinks}
              current={title} />
            <div className={styles.text}>
              <div className={styles.titleWrapper}>
                <span className={styles.total_count}>
                  {t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_EMPTY")}
                </span>
                <span className={styles.title}>&nbsp;{title}</span>
              </div>
              <p className={styles.description} />
            </div>
          </div>
        </div>
      </Wrapper>
    </div>
  );
};

export default ProductListHero;
