/* @flow */

import React, { useRef, useState, useEffect } from "react";
import cn from "classnames";
import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";

import PlusIcon from "icons/plus.svg";
import MinusIcon from "icons/minus.svg";

type Props = {
  value: number,
  setValue: number => void,
  min?: number,
  max?: number,
  className?: string,
};

const QtyPicker = ({ value, setValue, min = 0, max = 99, className }: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const t = useTranslate();
  const [displayQty, setDisplayQty] = useState(value);
  useEffect(() => {
    setDisplayQty(value);
  }, [value]);

  const setQty = (qty: number) => {
    const { current } = inputRef;

    if (isNaN(qty) && current !== null) {
      current.value = value.toString();
      return;
    }

    const newValue = Math.min(
      max,
      Math.max(
        qty,
        min
      )
    );

    setValue(newValue);
  };

  return (
    <div className={cn(styles.block, className)}>
      <div className={styles.wrapper}>
        <button
          type="button"
          aria-label={t("CART.DECREMENT")}
          className={styles.decrement}
          disabled={value <= min}
          onClick={() => setQty(value - 1)}
        >
          <MinusIcon />
        </button>

        <input
          ref={inputRef}
          className={styles.input}
          type="text"
          aria-label={t("CART.QUANTITY")}
          value={displayQty}
          onChange={e => setDisplayQty(e.target.value)}
          onBlur={() => setQty(displayQty)}
          onFocus={() => {
            if (inputRef.current) {
              inputRef.current.setSelectionRange(0, inputRef.current.value.length);
            }
          }}
        />

        <button
          type="button"
          aria-label={t("CART.INCREMENT")}
          className={styles.increment}
          disabled={value >= max}
          onClick={() => setQty(value + 1)}
        >
          <PlusIcon />
        </button>
      </div>
    </div>
  );
};

export default QtyPicker;
