/* @flow */

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  className?: string,
};

const Spinner = ({ className }: Props) => {
  const delay = 200;

  return (
    <div className={cn(styles.block, className)}>
      <span className={styles.dot} />
      <span className={styles.dot} style={{ animationDelay: delay + "ms" }} />
      <span className={styles.dot} style={{ animationDelay: (delay * 2) + "ms" }} />
    </div>
  );
};

export default Spinner;
