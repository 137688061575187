/* @flow */

import type { Model, EffectErrorMessage } from "crustate";
import type { Response } from "./util";
import { updateData, EFFECT_ERROR } from "crustate";

export type Recipient = {
  ebNo: string,
  lastName: string,
  extra: {
    limit: number,
    used: number,
  },
};

export type TransferProduct = {
  id: string,
  name: string,
  description: string,
  url: string,
  price: { incVat: number },
  sasPointsToWithdraw: number,
  sasPointsToDeposit: number,
  sasPointsToDepositType: "Basic Points" | "Extra Points",
  canBuyForSelf: {
    purchasable: boolean,
    basicPointsBuyable: number,
    failedConditions: $ReadOnlyArray<{
      code: string,
    }>,
  },
  canBuyForOther: {
    purchasable: boolean,
    failedConditions: $ReadOnlyArray<{
      code: string,
    }>,
  },
  maximumQty: number,
  pointsPrices: $ReadOnlyArray<{
    +id: string,
    +label: string,
    +points: {
      +value: { +incVat: number },
      +min: { +incVat: number },
      +max: { +incVat: number },
    },
    +currency: {
      +value: { +incVat: number },
      +min: { +incVat: number },
      +max: { +incVat: number },
    },
    // maximumCurrency: { incVat: number },
  }>,
};

export type TransferQuote = {
  currencyCode: string,
  grandTotal: { incVat: number },
  payment: ?{
    code: string,
    stripePublishableKey: string,
  },
  item: ?{
    qty: number,
    product: TransferProduct,
  },
  targetSasCustomer: ?{
    ebNo: string,
    lastName: string,
  },
  availablePointPayments: $ReadOnlyArray<{
    id: string,
    label: string,
    minimum: { incVat: number },
    maximum: { incVat: number },
    maximumAvailable: { incVat: number },
    points: { incVat: number },
  }>,
  selectedPointPayment: ?{
    id: string,
    label: string,
    points: {
      value: { incVat: number },
      min: { incVat: number },
      max: { incVat: number },
      selected: { incVat: number },
      available: { +incVat: number },
    },
    currency: {
      value: { incVat: number },
      min: { incVat: number },
      max: { incVat: number },
      remaining: { incVat: number },
    },
  },
};

export type Step = "" | "find-recipient" | "point-select" | "payment-split" | "payment";

type Data =
  | { state: "LOADING", product: TransferProduct, step: Step, recipient: ?Recipient, points: number | null }
  | { state: "LOADED", data: TransferQuote, product: TransferProduct, step: Step, recipient: Recipient | null, points: number | null }
  | { state: "TRANSFERRED", data: TransferQuote, product: TransferProduct, step: Step, recipient: Recipient | null, points: number | null }
  | { state: "TRANSFERRING", data: TransferQuote, product: TransferProduct, step: Step, recipient: Recipient | null, points: number | null }
  | { state: "SETTING_POINTS", data: TransferQuote, product: TransferProduct, step: Step, recipient: Recipient | null, points: number | null }
  | { state: "SETTING_POINTS_AMOUNT", data: TransferQuote, product: TransferProduct, step: Step, recipient: Recipient | null, points: number | null }
  | { state: "PLACING_ORDER", data: TransferQuote, product: TransferProduct, step: Step, recipient: Recipient | null, points: number | null }
  | { state: "ERROR", data: TransferQuote, product: TransferProduct, step: Step, recipient: Recipient | null, points: number | null };

export type TransferRequest =
{ tag: typeof TRANSFER_LOAD_REQUEST } |
{ tag: typeof TRANSFER_SET_POINTS_REQUEST } |
{ tag: typeof TRANSFER_SET_POINTS_AMOUNT_REQUEST } |
{ tag: typeof TRANSFER_PLACE_ORDER_REQUEST } |
{ tag: typeof TRANSFER_SET_STEP_REQUEST, step: Step } |
{ tag: typeof TRANSFER_SET_RECIPIENT_REQUEST, recipient: Recipient | null };

type SetPointsResponse<T, D> = {| tag: T, data: D, points: number |} | {| tag: T, error: string |};

export type TransferResponse =
  Response<typeof TRANSFER_LOAD_RESPONSE, TransferQuote> | EffectErrorMessage |
  SetPointsResponse<typeof TRANSFER_SET_POINTS_RESPONSE, TransferQuote> | EffectErrorMessage |
  Response<typeof TRANSFER_SET_POINTS_AMOUNT_RESPONSE, ?TransferQuote> | EffectErrorMessage |
  Response<typeof TRANSFER_PLACE_ORDER_RESPONSE, TransferQuote> | EffectErrorMessage;

export const stepEnabled = (d: Data, step: Step, purchaseFor: string): boolean => {
  if (d.state === "LOADING") {
    return false;
  }

  switch (step) {
    case "find-recipient":
      return d.product.canBuyForOther.purchasable;
    case "point-select":
      return purchaseFor === "self" || Boolean(d.recipient);
    case "payment-split":
      return (d.points || 0) > 0 &&
        (purchaseFor === "self" || Boolean(d.data.targetSasCustomer));
    case "payment":
      return (d.points || 0) > 0 &&
        (purchaseFor === "self" || Boolean(d.data.targetSasCustomer)) && d.data.selectedPointPayment !== null;
    default:
      return false;
  }
};

export const getSteps = (p: TransferProduct): $ReadOnlyArray<Step> => {
  const steps = [];

  if (p.canBuyForOther.purchasable) {
    steps.push("find-recipient");
  }

  steps.push("point-select");

  if (p.pointsPrices.length > 0) {
    steps.push("payment-split");
  }

  steps.push("payment");

  return steps;
};

export const TRANSFER_LOAD_REQUEST: "request/transfer_load" = "request/transfer_load";
export const TRANSFER_LOAD_RESPONSE: "response/transfer_load" = "response/transfer_load";

export const TRANSFER_SET_POINTS_REQUEST: "request/transfer_set_points" = "request/transfer_set_points";
export const TRANSFER_SET_POINTS_RESPONSE: "response/transfer_set_points" = "response/transfer_set_points";

export const TRANSFER_SET_POINTS_AMOUNT_REQUEST: "request/transfer_set_points_amount" = "request/transfer_set_points_amount";
export const TRANSFER_SET_POINTS_AMOUNT_RESPONSE: "response/transfer_set_points_amount" = "response/transfer_set_points_amount";

export const TRANSFER_PLACE_ORDER_REQUEST: "request/transfer_place_order" = "request/transfer_place_order";
export const TRANSFER_PLACE_ORDER_RESPONSE: "response/transfer_place_order" = "response/transfer_place_order";

export const TRANSFER_SET_STEP_REQUEST: "request/transfer_set_step" = "request/transfer_set_step";
export const TRANSFER_SET_RECIPIENT_REQUEST: "request/transfer_set_recipient" = "request/transfer_set_recipient";

export const setPoints = (qty: number, eb?: string) => ({
  tag: TRANSFER_SET_POINTS_REQUEST,
  qty,
  eb,
});

export const setPointsAmount = (points: number) => ({
  tag: TRANSFER_SET_POINTS_AMOUNT_REQUEST,
  points,
});

export const placeOrder = (): TransferRequest => ({
  tag: TRANSFER_PLACE_ORDER_REQUEST,
});

export const setStep = (step: Step): TransferRequest => ({
  tag: TRANSFER_SET_STEP_REQUEST,
  step,
});

export const setRecipient = (recipient: Recipient | null): TransferRequest => ({
  tag: TRANSFER_SET_RECIPIENT_REQUEST,
  recipient,
});

export const TransferModel: Model<
  Data,
  { product: TransferProduct },
  TransferRequest | TransferResponse
> = {
  id: "transfer-points",
  init: ({ product }) => {
    const firstStep = getSteps(product)[0] || "";

    return updateData(
      { state: "LOADING", product, step: firstStep, recipient: null, points: null },
      { tag: TRANSFER_LOAD_REQUEST }
    );
  },
  update: (state: Data, msg) => {
    switch (msg.tag) {
      case EFFECT_ERROR:
        throw new Error("Route load failed");
      case TRANSFER_LOAD_REQUEST:
        return updateData({ ...state, state: "LOADING" }, msg);
      case TRANSFER_SET_STEP_REQUEST:
        return updateData({ ...state, step: msg.step }, msg);
      case TRANSFER_SET_RECIPIENT_REQUEST:
        const steps = getSteps(state.product);

        let nextStep: Step = "";
        for (let i = 0; i < steps.length; i++) {
          if (steps[i] === "find-recipient") {
            nextStep = steps[i + 1] || ("": Step);
          }
        }

        return updateData(
          { ...state, recipient: msg.recipient, step: nextStep, points: null }, msg
        );
      case TRANSFER_SET_POINTS_REQUEST:
        // Allow error only because it's triggered by setPointProduct
        if (state.state === "LOADED" || state.state === "ERROR") {
          return updateData({ ...state, state: "SETTING_POINTS", data: state.data }, { ...msg, product: state.product, recipient: state.recipient });
        }

        break;
      case TRANSFER_SET_POINTS_AMOUNT_REQUEST:
        if (state.state === "LOADED") {
          return updateData({ ...state, state: "SETTING_POINTS_AMOUNT", data: state.data }, { ...msg, recipient: state.recipient });
        }

        break;
      case TRANSFER_PLACE_ORDER_REQUEST:
        if (state.state === "LOADED") {
          return updateData({ ...state, state: "PLACING_ORDER", data: state.data }, msg);
        }

        break;
      case TRANSFER_LOAD_RESPONSE:
        if (state.state === "LOADING") {
          if (!msg.data) {
            throw new Error("Couldn't load quote");
          }

          return updateData({ ...state, state: "LOADED", recipient: null, data: msg.data });
        }

        break;
      case TRANSFER_SET_POINTS_RESPONSE:
        if (state.state === "SETTING_POINTS") {
          const steps = getSteps(state.product);
          const nextStep = (steps[steps.findIndex(x => x === "point-select") + 1]) || "";

          if (msg.error) {
            return updateData({ ...state, state: "ERROR", data: state.data, step: nextStep, points: msg.error === undefined ? msg.points : 0 });
          }

          return updateData({ ...state, state: "LOADED", data: msg.data || state.data, step: nextStep, points: msg.error === undefined ? msg.points : 0 });
        }

        break;
      case TRANSFER_SET_POINTS_AMOUNT_RESPONSE:
        if (state.state === "SETTING_POINTS_AMOUNT") {
          return updateData({ ...state, state: "LOADED", data: msg.data || state.data });
        }

        break;
      case TRANSFER_PLACE_ORDER_RESPONSE:
        if (state.state === "PLACING_ORDER") {
          return updateData({ ...state, state: "LOADED", data: state.data });
        }

        break;
      default:
        break;
    }
  },
};
