/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";

import bindGlobalErrors from "./global-errors";
import bindHomeEffects from "./home";
import bindCustomerEffects from "./customer";
import bindQuoteEffects from "./quote";
import bindRouteEffects from "./route";
import bindOrderEffects from "./order";
import bindBrandEffects from "./brand";
import bindPopularEffects from "./popular";
import bindCmsEffects from "./cms";
import bindWishlistEffects from "./wishlist";
import bindWishlistToggleEffects from "./wishlist-toggle";
import bindSearchEffects from "./search";
import bindOrderHistoryEffects from "./order-history";
import bindAllProductsEffects from "./all-products";
import bindAdventCalendarEffects from "./advent-calendar";
import bindOTPEffects from "./otp";

type History = {
  push: (path: string) => void,
};

export const registerClient = (storage: Storage, client: Client<{}>, history: History) => {
  bindGlobalErrors(storage);
  bindRouteEffects(storage, client);
  bindHomeEffects(storage, client);
  bindCustomerEffects(storage, client, history);
  bindQuoteEffects(storage, client);
  bindOrderEffects(storage, client);
  bindBrandEffects(storage, client);
  bindPopularEffects(storage, client);
  bindCmsEffects(storage, client);
  bindWishlistEffects(storage, client);
  bindWishlistToggleEffects(storage, client);
  bindSearchEffects(storage, client, history);
  bindOrderHistoryEffects(storage, client);
  bindAllProductsEffects(storage, client);
  bindAdventCalendarEffects(storage, client);
  bindOTPEffects(storage, client);
};
