/* @flow */

import React from "react";
import cn from "classnames";
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import useCheckoutSteps from "helpers/use-checkout-steps";
import Button from "components/Button";
import Wrapper from "components/Wrapper";
import Logo from "components/AppHeader/Logo";
import SystemMessages from "components/SystemMessages";
import ChevronIcon from "icons/chevron.svg";
import PhoneIcon from "icons/phone.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
};

const CheckoutHeader = ({ className }: Props) => {
  const t = useTranslate();
  const history = useHistory();
  const { steps, currentStep } = useCheckoutSteps();

  let stepIndex = 0;

  for (const [index, step] of steps.entries()) {
    if (step === currentStep) {
      stepIndex = index;
      break;
    }
  }

  return (
    <>
      <SystemMessages />
      <div className={cn(className, styles.block)}>
        <Wrapper>
          <div className={styles.content}>
            <div className={styles.left}>
              <Link className={styles.logo} to="/">
                <Logo />
              </Link>

              <div className={styles.steps__desktop}>
                {steps.map((x, i) => (
                  <Link
                    key={x.id}
                    to={x.enabled ? x.link : "#"}
                    className={cn(styles.step, { [styles.current]: x.id === currentStep.id })}
                  >
                    {`${i + 1}. ${x.title}`}
                  </Link>
                ))}
              </div>
            </div>

            <div className={styles.right}>
              <Link to="/customer-service">
                <PhoneIcon />
              </Link>
            </div>
          </div>

          <div className={styles.steps__mobile}>
            <div className={styles.step}>
              <div className={styles.counter}>
                {stepIndex + 1} {t("CART.OF")} {steps.length}
              </div>
              <div className={styles.title}>
                {currentStep.id ?
                  t(`CHECKOUT.STEPS.${currentStep.id.toUpperCase()}.SHORT`) :
                  ""
                }
              </div>
            </div>

            <Button className={styles.back} onClick={() => history.goBack()}>
              <ChevronIcon />
            </Button>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default CheckoutHeader;
