/* @flow */

import type { Customer } from "shop-state/types";

import React from "react";
import styles from "./styles.scss";
import cn from "classnames";

type Props = {
  customer: Customer,
  className?: string,
};

const AccountBadge = ({ customer, className }: Props) => {
  const { sasIncentiveLevel } = customer;
  return (
    <div className={cn(className, styles.block, styles[`level__${sasIncentiveLevel}`])}>
      {customer.firstname.slice(0, 1)}{customer.lastname.slice(0, 1)}
    </div>
  );
};

export default AccountBadge;
