/* @flow */

import React from "react";
import { withRouter } from "react-router";
import styles from "./styles.scss";
import cn from "classnames";
import CartIcon from "icons/cart.svg";
import { QuoteData } from "data";
import { useData } from "crustate/react";

type Props = {
  className: string,
  location: { pathname: string },
  isOpen: boolean,
  openMiniCart: () => void,
  history: {
    push: (path: string) => void,
    goBack: () => void,
  },
};

const CartCounter = ({ className = "", location, history, isOpen, openMiniCart }: Props) => {
  const data = useData(QuoteData);
  const onCheckout = location.pathname.includes("/checkout");
  const cartCount =
    data.data ?
      data.data.items.reduce((a, { qty }) => a + qty, 0) :
      0;

  if (cartCount > 0) {
    return (
      <span
        className={cn(
          styles.block,
          { [styles.active]: onCheckout || isOpen },
          className
        )}
        onClick={() => {
          if (onCheckout) {
            history.goBack();
          }
          else if (cartCount) {
            openMiniCart();
          }
          else {
            history.push("/checkout");
          }
        }}
      >
        <div>
          <span className={styles.count}>
            <span>
              {cartCount}
            </span>
          </span>
          <CartIcon />
        </div>
      </span>
    );
  }

  return (
    <div className={cn(styles.block, className)}>
      <CartIcon />
    </div>
  );
};

export default withRouter(CartCounter);
