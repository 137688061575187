/* @flow */

import type { History } from "react-router";

import type { Node as ReactNode } from "react";
import React, { useContext, useRef, useState } from "react";
import { usePreserveScrollPosition } from "react-router-on-navigation";
import { useTranslate } from "@awardit/react-use-translate";
import AppHeader from "components/AppHeader";
import AppFooter from "components/AppFooter";
import CountryModal from "components/CountryModal";
import { NotificationBar } from "@crossroads/ui-components";
import { Helmet } from "react-helmet-async";
import { StoreInfoContext } from "entrypoint/shared";
import { ViewModeData, CmsData } from "data";
import { UiProvider } from "helpers/ui";
import useCookie from "helpers/use-cookie";
import useShowCountryModal from "helpers/use-show-country-modal";
import CookieConsent from "components/CookieConsent";

import cn from "classnames";
import styles from "./styles.scss";

type Props = {
  children: ReactNode,
  location: Location,
  history: History,
};

const MATCH_BUY_AND_TRANSFER = /buy-and-transfer-points\/(?!success)[^/]+\/?$/g;

const App = ({ children, location, history }: Props) => {
  const t = useTranslate();
  const container = useRef(null);
  const onCheckout = location.pathname.includes("checkout") && location.pathname !== "/checkout/success";
  const onCountry = location.pathname.includes("account/country");
  const { info } = useContext(StoreInfoContext);

  const cookieOptsConsent = { domain: `.${typeof window !== "undefined" ? window.location.host.replace(/:\d+$/, "") : ""}`, expires: 3650 };
  const [consent, setConsent, consentRead] = useCookie("cookie-consent", "initial", cookieOptsConsent);

  const onPurchasePoints = location.pathname.match(MATCH_BUY_AND_TRANSFER);
  const onLogin = location.pathname.replace("/", "") === "login";
  const showCountryModal = useShowCountryModal();
  const [toggleCookieDialogue, setToggleCookieDialogue] = useState(false);
  const [hideNotificationBar, setHideNotificationBar] = useState(false);

  usePreserveScrollPosition(history);

  return (
    <ViewModeData.Provider>
      <CmsData.Provider url="cookie-consent">
        <UiProvider>
          <div
            ref={container} className={cn(
              styles.block,
              { [styles.onCheckout]: onCheckout },
              { [styles.onCountry]: onCountry },
              { [styles.onPurchasePoints]: onPurchasePoints }
            )}
          >
            <Helmet
              titleTemplate={`${info.titlePrefix || ""} %s ${info.titleSuffix || ""}`}
              link={[
                { rel: "apple-touch-icon", sizes: "57x57", href: "/assets/browser/apple-touch-icon-57x57.png" },
                { rel: "apple-touch-icon", sizes: "60x60", href: "/assets/browser/apple-touch-icon-60x60.png" },
                { rel: "apple-touch-icon", sizes: "72x72", href: "/assets/browser/apple-touch-icon-72x72.png" },
                { rel: "apple-touch-icon", sizes: "76x76", href: "/assets/browser/apple-touch-icon-76x76.png" },
                { rel: "apple-touch-icon", sizes: "114x114", href: "/assets/browser/apple-touch-icon-114x114.png" },
                { rel: "apple-touch-icon", sizes: "120x120", href: "/assets/browser/apple-touch-icon-120x120.png" },
                { rel: "apple-touch-icon", sizes: "144x144", href: "/assets/browser/apple-touch-icon-144x144.png" },
                { rel: "apple-touch-icon", sizes: "152x152", href: "/assets/browser/apple-touch-icon-152x152.png" },
                { rel: "apple-touch-icon", sizes: "180x180", href: "/assets/browser/apple-touch-icon-180x180.png" },
                { rel: "apple-touch-icon", href: "/assets/browser/apple-touch-icon-120x120-precomposed.png" },
                { rel: "icon", type: "image/png", size: "16x16", href: "/assets/browser/favicon-16x16.png" },
                { rel: "icon", type: "image/png", size: "32x32", href: "/assets/browser/favicon-32x32.png" },
                { rel: "icon", type: "image/png", size: "96x96", href: "/assets/browser/favicon-96x96.png" },
                { rel: "manifest", href: "/manifest.json" },
                { rel: "mask-icon", href: "/assets/browser/safari-pinned-tab.svg", color: "#009" },
              ]}
              meta={[
                { name: "msapplication-TileColor", content: "#ffffff" },
                { name: "theme-color", content: "#ffffff" },
              ]}
              htmlAttributes={{ lang: info.locale.slice(0, 2) }}
            />
            <div className={styles.top}>
              {(!onCountry && !onPurchasePoints && !onCheckout) &&
                <AppHeader
                  className={styles.header}
                  location={location}
                  onCheckout={onCheckout}
                />
              }
              {(!onPurchasePoints && !onCheckout && !onLogin) &&
                <div className={cn(styles.height, { [styles.onCheckout]: onCheckout })} />
              }
              <div>
                {children}
              </div>
            </div>

            {consentRead &&
              <div className={styles.notices}>
                <CookieConsent
                  isVisible={consentRead && (consent === "initial" || toggleCookieDialogue)}
                  consent={consent}
                  setConsent={setConsent}
                  consentRead={consentRead}
                  setToggleCookieDialogue={setToggleCookieDialogue}
                />
              </div>
            }

            {(!onLogin && !onCheckout && !onCountry) && (
              <AppFooter
                className={styles.footer}
                setToggleCookieDialogue={setToggleCookieDialogue}
              />
            )}

            <CountryModal
              setOpen={showCountryModal.setOpen}
              open={showCountryModal.open}
              message={t("APP.COUNTRY_MODAL", { country: showCountryModal.country })}
            />

            {info.popUp && info.popUp.body !== "" && !hideNotificationBar &&
              <NotificationBar
                className={styles.notificationBar}
                text={info.popUp.body}
                onClose={() => setHideNotificationBar(true)}
              />
            }
          </div>
        </UiProvider>
      </CmsData.Provider>
    </ViewModeData.Provider>
  );
};

export default App;
