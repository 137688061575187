/* @flow */

import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import { useTransferClient } from "entrypoint/shared";
import { transferTypes } from "queries";
import useCustomer from "helpers/use-customer";
import LoadingView from "components/LoadingView";
import { TransferData, OrderData } from "data";
import LandingView from "./Landing";
import FormView from "./FormView";
import Success from "./Success";

const Router = () => {
  const client = useTransferClient();
  const { customer, redirectToLogin } = useCustomer();
  const [types, setTypes] = useState();

  useEffect(() => {
    if (!customer) {
      redirectToLogin();
    }
  }, []);

  useEffect(() => {
    client(transferTypes).then(x => {
      // TODO: Remove the filter when we are going to show all options and
      // indicate which conditions are not fulfilled for the customer to
      // purchase them.
      // $FlowFixMe[incompatible-call]
      setTypes(x.products.filter(p => p.canBuyForSelf.purchasable || p.canBuyForOther.purchasable));
    });
  }, [client, customer]);

  if (!types || !customer) {
    return <LoadingView />;
  }

  return (
    <Switch>
      <Route exact path="/account/buy-and-transfer-points" render={() => <LandingView types={types} />} />
      {types.map(x => (
        <Route
          key={x.url}
          path={`/account/buy-and-transfer-points/${x.url}`}
          render={() => (
            <TransferData.Provider name={x.url} product={x}>
              <FormView pointsProduct={x} />
            </TransferData.Provider>
          )} />
      ))}
      <OrderData.Provider>
        <Route exact path="/account/buy-and-transfer-points/success" component={Success} />
      </OrderData.Provider>
    </Switch>
  );
};

export default Router;
