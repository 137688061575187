/* @flow */

import React, { useState, useRef, useEffect } from "react";
import cn from "classnames";
import styles from "./styles.scss";
import { debounce } from "diskho";
import { useTranslate } from "@awardit/react-use-translate";
import { Input, Slider } from "@crossroads/ui-components";
import useFormat from "helpers/use-format";

type Props = {
  points: number,
  setPoints: number => void,
  minValue: number,
  maxValue: number,
  grandTotal: number,
  processing: boolean,
};

const PaymentSplit = ({
  points, setPoints, grandTotal, minValue, maxValue, processing }: Props
) => {
  const t = useTranslate();
  const { formatPoints, formatPriceLong } = useFormat();
  const [localPoints, _setLocalPoints] = useState(points);
  const [input, setInput] = useState(localPoints);

  useEffect(() => {
    setInput(localPoints);
  }, [localPoints]);

  const setPointsDebounced = useRef(debounce(x => {
    setPoints(x);
  }, 100)).current;

  useEffect(() => {
    _setLocalPoints(points);
  }, [points]);

  useEffect(() => {
    setPointsDebounced(Math.round(localPoints));
  }, [localPoints, setPointsDebounced]);

  const clampPoints = x =>
    Math.round(Math.min(Math.max(x, minValue), maxValue));

  const setLocalPoints = (v: number) => {
    const value = clampPoints(v);
    _setLocalPoints(clampPoints(value));
    setInput(value);
  };

  return (
    <section className={styles.block}>
      <section className={styles.box}>
        <h2 className={cn(styles.heading, { [styles.processing]: processing })}>
          {formatPriceLong(grandTotal)} & {formatPoints(localPoints)}
        </h2>
        <div className={styles.pointSelect}>
          <div className={styles.slider}>
            <Slider
              minValue={minValue}
              maxValue={maxValue}
              value={localPoints}
              onChange={v => _setLocalPoints(v)}
            />

            <div className={styles.sliderLabels}>
              <span>{formatPoints(minValue)}</span>
              <span>{formatPoints(maxValue)}</span>
            </div>
          </div>
          <div className={styles.inputs}>
            <Input
              label={t("CHECKOUT.CASH")}
              variant="float"
              name="points"
              value={processing ? t("PURCHASE_POINTS.CALCULATING") : formatPriceLong(grandTotal)}
              disabled="disabled"
            />
            <Input
              label={t("CHECKOUT.POINTS")}
              variant="float"
              name="points"
              disabled={processing}
              value={input.toString()}
              onChange={(e: SyntheticEvent<HTMLInputElement>) => {
                setInput(e.currentTarget.value);
              }}
              onBlur={e => {
                const val = parseInt(e.target.value, 10);
                const number = Number.isNaN(val) ? 0 : val;
                setLocalPoints(number);
              }}
            />
          </div>
        </div>
      </section>
    </section>
  );
};

export default PaymentSplit;
