/* @flow */

import type { Storage } from "crustate";
import type { Client } from "@awardit/graphql-ast-client";
import type {
  WishlistLoadResponse,
  WishlistRemoveProductRequest,
  WishlistRemoveProductResponse,
} from "state/wishlist";

import { addMessage } from "@crossroads/shop-state/messages";
import {
  wishlist as wishlistQuery,
  removeFromWishlist as removeFromWishlistQuery } from "queries";
import {
  WISHLIST_LOAD_REQUEST,
  WISHLIST_LOAD_RESPONSE,
  WISHLIST_REMOVE_PRODUCT_REQUEST,
  WISHLIST_REMOVE_PRODUCT_RESPONSE,
} from "state/wishlist";

const registerClient = (storage: Storage, client: Client<{}>) => {
  storage.addEffect({
    effect: async () => {
      const { wishlist } = await client(wishlistQuery, undefined, { cache: false });

      return ({
        tag: WISHLIST_LOAD_RESPONSE,
        data: wishlist,
      }: WishlistLoadResponse);
    },
    subscribe: { [WISHLIST_LOAD_REQUEST]: true },
  });

  storage.addEffect({
    effect: async (msg: WishlistRemoveProductRequest) => {
      const { removeFromWishlist } = await client(removeFromWishlistQuery, {
        itemId: msg.itemId,
      });

      if (removeFromWishlist.result !== "success") {
        return storage.broadcastMessage(addMessage(removeFromWishlist.result, "error"));
      }

      const { wishlist } = await client(wishlistQuery);

      return storage.broadcastMessage(({
        tag: WISHLIST_REMOVE_PRODUCT_RESPONSE,
        data: wishlist,
        sku: msg.sku,
      }: WishlistRemoveProductResponse));
    },
    subscribe: { [WISHLIST_REMOVE_PRODUCT_REQUEST]: true },
  });
};

export default registerClient;
