/* @flow */

import type { Language } from "components/LanguageSwitcher";

import React, { useContext, useEffect, useRef } from "react";
import styles from "./styles.scss";
import cn from "classnames";
import { StoreInfoContext } from "entrypoint/shared";
import { useTranslate } from "@awardit/react-use-translate";
import LanguageSwitcher, { languages } from "components/LanguageSwitcher";
import { useModal, Lightbox } from "@crossroads/ui-components";
import CloseIcon from "icons/close2.svg";

type Props = {
  className?: string,
  open: boolean,
  setOpen: boolean => void,
  message?: string,
};

const LightboxContent = (
  {
    className,
    setOpen,
    message,
  }: { className?: string, setOpen: boolean => void, message?: string }
) => {
  const t = useTranslate();
  const { info: { locale } } = useContext(StoreInfoContext);
  const lang = useRef<Language>(languages.find(x => x.lang === locale.replace("_", "-")) || languages.find(x => x.code === "en") || languages[0]);

  const setLang = (l: Language) => {
    lang.current = l;
  };

  useEffect(() => {
    const _locale = locale.replace(/_/, "-");
    const language = languages.find(language => language.lang === _locale);

    if (language && language !== lang.current) {
      lang.current = language;
    }
  }, [locale]);

  return (
    <div className={cn(styles.block, className)}>
      <CloseIcon className={styles.closeIcon} onClick={() => setOpen(false)} />
      <div className={styles.wrapper}>
        <div className={styles.headings}>
          <h2>{t("COUNTRY.HEADING")}</h2>
          {message &&
            <p>{message}</p>
          }
        </div>

        <div className={styles.languageSwitcher}>
          <LanguageSwitcher currentLang={lang.current} setCurrentLang={setLang} />
        </div>
      </div>
    </div>
  );
};

const CountryModal = ({ className, open, setOpen, message }: Props) => {
  const { Modal } = useModal(open, setOpen);

  return (
    <Modal className={className}>
      <Lightbox className={styles.lightbox} open={open} setOpen={setOpen}>
        <LightboxContent setOpen={setOpen} message={message} />
      </Lightbox>
    </Modal>
  );
};

export default CountryModal;
