/* @flow */

import React from "react";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";

const CustomerServiceLink = () => {
  const t = useTranslate();

  return (
    <div className={styles.block}>
      <div className={styles.text}>
        <span>{t("CHECKOUT.NEED_HELP")}</span>
        <br />
        <a href={t("CUSTOMER_SERVICE.LINK")} target="_blank" rel="noopener noreferrer">
          {t("CHECKOUT.CONTACT_CUSTOMER_SERVICE")}
        </a>
      </div>
    </div>
  );
};

export default CustomerServiceLink;
