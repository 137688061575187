module.exports = {
  host: "localhost",
  port: 9134,
  apiHost: {
    server: "http://127.0.0.1",
    client: "",
    headers: ["SSL", "Ssl-Offload", "X-Forward-Proto", "Host", "X-Real-Ip", "X-Forwarded-For", "Cookie"],
  },
  googleAnalytics: [
    { store: "se", currencyCode: "SEK", accounts: ["UA-70699212-1", "UA-70699212-2", "G-4YXJ91MHYT", "G-WTSBQQ7SS0"] },
    { store: "no", currencyCode: "NOK", accounts: ["UA-70699212-1", "UA-70699212-3", "G-4YXJ91MHYT", "G-GL7VRWT653"] },
    { store: "dk", currencyCode: "DKK", accounts: ["UA-70699212-1", "UA-70699212-4", "G-4YXJ91MHYT", "G-1XGS4J7ZK6"] },
    { store: "en", currencyCode: "EUR", accounts: ["UA-70699212-1", "UA-70699212-5", "G-4YXJ91MHYT", "G-BSPSD37MZM"] },
  ],
  isProduction: true,
  proxyHost: null,
  datadog: {
    applicationId: 'cec4da83-3eeb-4f2c-b9b5-287b9251abac',
    clientToken: 'pubb819ce60ac9dab0a3e1ac05798648cd8',
    site: 'datadoghq.eu',
    service:'sas-eurobonusshop',
    env:'production',
    sampleRate: 100,
    trackInteractions: true,
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingOrigins: [/https:\/\/.*\.saseurobonusshop\.com/],
    cspUri: "https://csp-report.browser-intake-datadoghq.eu/api/v2/logs?dd-api-key=pub552607268219ad5b8d2647030dd2f46f&dd-evp-origin=content-security-policy&ddsource=csp-report&ddtags=service%3Asaseurobonusshop.com%2Cenv%3Aproduction",
  },
};
