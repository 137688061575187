/* @flow */

import React, { useState } from "react";
import styles from "./styles.scss";
import { HashLink } from "react-router-hash-link";
import Box from "../../Box";
import Button from "components/Button";
import Accordion from "components/Accordion";
import PhoneIcon from "icons/phone.svg";
import ChatIcon from "icons/chat.svg";
import { useTranslate } from "@awardit/react-use-translate";

const scrollWidthOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = Number.parseInt(styles.headerOffset, 10) * -1;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

const TicketBox = () => {
  const t = useTranslate();
  return (
    <Box className={styles.box}>
      <h3><ChatIcon />{t("SUCCESS.CONTACT.TITLE")}</h3>
      <p dangerouslySetInnerHTML={{ __html: t("SUCCESS.CONTACT.INFO") }} />
      <a href={t("META.HELPDESK_LINK")} target="_blank" rel="noopener noreferrer"><Button variant="primary">{t("SUCCESS.CONTACT.TITLE")}</Button></a>
    </Box>
  );
};

const CallBox = () => {
  const t = useTranslate();
  return (
    <Box className={styles.box}>
      <h3><PhoneIcon />{t("SUCCESS.CONTACT.CALL_US")}</h3>
      <p dangerouslySetInnerHTML={{ __html: t("SUCCESS.CONTACT.CALL_US_HOURS") }} />
      <a className="link" href={`tel:${t("SUCCESS.CONTACT.CALL_US_PHONENUMBER")}`}>{t("SUCCESS.CONTACT.CALL_US")}</a>
    </Box>
  );
};

const Help = () => {
  const [openSection, setOpenSection] = useState(null);
  const t = useTranslate();

  const toggleSection = (s: string) =>
    openSection === s ? setOpenSection(null) : setOpenSection(s);

  return (
    <div className={styles.block}>
      <h3>{t("PURCHASE_POINTS.FAQ.TITLE")}</h3>

      <div className={styles.container}>
        <main className={styles.left}>
          <div className={styles.left}>
            <Accordion
              title={t("PURCHASE_POINTS.FAQ.1.Q")}
              open={openSection === "first"}
              setOpen={() => toggleSection("first")}
            >
              <p>{t("PURCHASE_POINTS.FAQ.1.A")}</p>
            </Accordion>

            <Accordion
              title={t("PURCHASE_POINTS.FAQ.2.Q")}
              open={openSection === "second"}
              setOpen={() => toggleSection("second")}
            >
              <p>{t("PURCHASE_POINTS.FAQ.2.A")}</p>
            </Accordion>

            <Accordion
              title={t("PURCHASE_POINTS.FAQ.3.Q")}
              open={openSection === "third"}
              setOpen={() => toggleSection("third")}
            >
              <p>{t("PURCHASE_POINTS.FAQ.3.A")}</p>
            </Accordion>

            <Accordion
              title={t("PURCHASE_POINTS.FAQ.4.Q")}
              open={openSection === "forth"}
              setOpen={() => toggleSection("forth")}
            >
              <p>{t("PURCHASE_POINTS.FAQ.4.A")}</p>
            </Accordion>
          </div>
          <p>
            <HashLink
              smooth
              scroll={el => scrollWidthOffset(el)}
              to={`${t("CUSTOMER_SERVICE.LINK")}#buy-and-transfer`}
            >
              {t("PURCHASE_POINTS.FAQ.VIEW_MORE")}
            </HashLink>
          </p>
        </main>
        <aside className={styles.right}>
          <TicketBox />
          <CallBox />
        </aside>
      </div>
    </div>
  );
};

export default Help;
