/* @flow */

import { useState, useEffect, useContext } from "react";
import { useBrowser } from "@awardit/react-use-browser";
import useCookie from "helpers/use-cookie";
import { useData } from "crustate/react";
import { CustomerData } from "data";
import { StoreInfoContext } from "entrypoint/shared";

const ROTW_LOCALE = "en_GB";
const ROTW_SHOW_MODAL_FOR_COUNTRIES = ["SE", "NO", "DK"];

const useShowCountryModal = () => {
  const browser = useBrowser();
  const customerData = useData(CustomerData);
  const { info } = useContext(StoreInfoContext);
  const [customerCountry, setCustomerCountry] = useState<string>("");
  const [countryModalOpen, setCountryModalOpen] = useState<boolean>(false);
  const cookieOpts = { domain: `.${typeof window !== "undefined" ? window.location.host : ""}`, expires: 3650 };
  const [cookieCountryModalShown, setCookieCountryModalShown] = useCookie("cm_shown", false, cookieOpts);

  useEffect(() => {
    if (browser && !cookieCountryModalShown && customerData.state === "LOGGED_IN") {
      const billingAddress = customerData.data.addresses.find(a => a.isDefaultBilling);

      if (billingAddress) {
        let showModal = !info.countries.some(c => c.code === billingAddress.country.code);

        if (!showModal && info.locale === ROTW_LOCALE) {
          showModal = ROTW_SHOW_MODAL_FOR_COUNTRIES.includes(billingAddress.country.code);
        }

        setCustomerCountry(billingAddress.country.name);
        setCountryModalOpen(showModal);
      }
    }
  }, [browser, customerData, cookieCountryModalShown, info]);

  return {
    open: countryModalOpen && !cookieCountryModalShown,
    setOpen: (open: boolean) => {
      if (!open) {
        setCookieCountryModalShown(true);
      }

      setCountryModalOpen(open);
    },
    country: customerCountry,
  };
};

export default useShowCountryModal;
