/* @flow */

import type { Quote, Customer } from "shop-state/types";

import React, { useState, useRef, useEffect } from "react";
import usePrevious from "helpers/use-previous";
import { debounce } from "diskho";
import { useTranslate } from "@awardit/react-use-translate";
import { getQuoteData, setPointsPayment } from "state/quote";
import useCustomer from "helpers/use-customer";
import { QuoteData } from "data";
import { useSendMessage, useData } from "crustate/react";
import useFormat from "helpers/use-format";
import Button from "components/Button";
import PointSelection from "components/PointSelection";
import Container from "components/CheckoutView/Container";
import CustomerServiceLink from "components/CheckoutView/CustomerServiceLink";
import CartSummary from "components/CartSummary";
import LoadingView from "components/LoadingView";
import { getCustomerPoints } from "state/customer";
import InfoIcon from "icons/info.svg";
import { Tooltip } from "@crossroads/ui-components";
import useQuotePointsSetToMaximum from "helpers/use-quote-points-set-to-maximum";
import styles from "./styles.scss";

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

export const shouldShowStep2 = (quote?: ?Quote, customer?: ?Customer) => {
  if (!quote) {
    return true;
  }

  if (!customer || customer.sasExtraPoints === 0) {
    return false;
  }

  const pointPayment = quote.selectedPointPayment;

  if (!pointPayment) {
    return true;
  }

  return pointPayment.points.min.incVat < pointPayment.points.max.incVat;
};

const Step2 = ({ open, setOpen }: Props) => {
  const t = useTranslate();
  const { formatPoints } = useFormat();
  const sendMessage = useSendMessage();
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(quoteData) || {};
  const { customer } = useCustomer();
  const balance = getCustomerPoints(customer);
  const { selectedPointPayment } = quote;
  const previousSelectedPointPayment = usePrevious(selectedPointPayment);
  const selectedPoints = selectedPointPayment?.points.selected.incVat || 0;
  const [sliderValue, setSliderValueReal] = useState<number>(selectedPoints);

  const setQuotePointsDebounced = useRef(debounce(x => {
    sendMessage(setPointsPayment("sas_extrapoints", x));
  }, 100)).current;

  useQuotePointsSetToMaximum();

  useEffect(() => {
    if (selectedPointPayment && !previousSelectedPointPayment) {
      setSliderValueReal(selectedPointPayment.points.selected.incVat);
    }
  }, [selectedPointPayment]);

  if (!selectedPointPayment) {
    return <LoadingView />;
  }

  const minimumPoints = selectedPointPayment.points.min.incVat;
  const maximumPoints = Math.min(
    selectedPointPayment.points.max.incVat,
    balance
  );
  const remainingCash = selectedPointPayment.currency.remaining.incVat;

  const limitPoints = (p: number): number => {
    if (isNaN(p)) {
      return selectedPoints;
    }

    return Math.max(minimumPoints, Math.min(maximumPoints, p));
  };

  const setSliderValue = (v: number) => {
    const value = limitPoints(v);
    setSliderValueReal(value);
    setQuotePointsDebounced(value);
  };

  return (
    <Container
      right={
        <div>
          <CartSummary
            open={open}
            setOpen={setOpen}
            toPayPoints={sliderValue}
            toPayPrice={remainingCash}
          >
            <div className={styles.submitButtonContainer}>
              <Button
                className={styles.submitButton}
                to="/checkout/3" variant="primary"
                loading={quoteData.state !== "LOADED"}
              >
                {t("CHECKOUT.CHOOSE_PAYMENT_METHOD")}
              </Button>
            </div>
          </CartSummary>

          <CustomerServiceLink />
        </div>
      }
    >
      <div className={styles.box}>
        <PointSelection
          title={t("CHECKOUT.POINTS_TITLE")}
          subTitle={t("CHECKOUT.POINTS_BALANCE", { points: formatPoints(balance) })}
          footerTitle={t("CHECKOUT.AMOUNT_TO_PAY")}
          points={sliderValue}
          setPoints={setSliderValue}
          minValue={minimumPoints}
          maxValue={maximumPoints}
          remainingPrice={remainingCash}
          processing={quoteData.state !== "LOADED"}
          TooltipSlot={
            <Tooltip
              direction="bottom"
              title={t("CHECKOUT.TOOLTIP_TITLE")}
              text={t("CHECKOUT.TOOLTIP_DESCRIPTION")}
              width={400}
            >
              <InfoIcon />
            </Tooltip>
          }
        />
      </div>
    </Container>
  );
};

export default Step2;
