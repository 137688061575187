/* @flow */

import type { Recipient } from "state/transfer";

import React, { useState } from "react";
import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";
import Button from "components/Button";
import Field from "components/Field";
import { useSendMessage } from "crustate/react";
import { focusInvalidField } from "helpers/utils";
import useFormat from "helpers/use-format";
import { Form, rules, isRequired } from "@awardit/formaggio";
import { addMessage } from "@crossroads/shop-state/messages";
import { useTransferClient } from "entrypoint/shared";
import { findSasUser } from "queries";
import SuccessIcon from "icons/success.svg";

type FormState = {
  ebNo: string,
  lastName: string,
};

type Props = {
  recipient: ?{
    ebNo: string,
    lastName: string,
    extra: {
      used: number,
      limit: number,
    },
  },
  onNext: Recipient => void,
};

type Status = "fetching" | "error" | "success" | null;

const validation = rules([
  isRequired("ebNo"),
]);

const FindRecipient = ({ recipient, onNext }: Props) => {
  const t = useTranslate();
  const { formatPoints } = useFormat();
  const sendMessage = useSendMessage();
  const client = useTransferClient();
  const [status, setStatus] = useState<Status>(recipient ? "success" : null);
  const [state, setState] = useState<FormState>(recipient || { ebNo: "", lastName: "" });

  const errors = validation((state: any));

  const canReceiveAmount = recipient ?
    formatPoints(recipient.extra.limit) : null;

  return (
    <Form
      value={(state: any)}
      errors={errors}
      onError={focusInvalidField}
      onChange={x => {
        setState({ ...state, ...(x: any) });
      }}
      onSubmit={e => {
        e.preventDefault();
        setStatus("fetching");
        client(findSasUser, { ebNo: state.ebNo }).then(x => {
          const { user } = x.findSasUser;

          if (user !== null) {
            setStatus("success");

            setState(user);

            setTimeout(() => {
              onNext(user);
            }, 350);
          }
          else {
            const errorMessage = t("STATUS_CODE.TRANSFER_POINTS." + x.findSasUser.result) !== "" ? "TRANSFER_POINTS." + x.findSasUser.result : "TRANSFER_POINTS.noSuccess";
            sendMessage(addMessage(errorMessage, "error"));
            setStatus(null);
          }
        });
      }}
    >
      <div className={styles.fields}>
        <div>
          <Field disabled={status === "fetching"} variant="float" name="ebNo" label={t("PURCHASE_POINTS.FIND_RECIPIENT.EB")} />
        </div>
        <div style={{ visibility: state.lastName ? "visible" : "hidden" }}>
          <Field disabled variant="float" name="lastName" label={t("PURCHASE_POINTS.FIND_RECIPIENT.LAST_NAME")} />
        </div>
      </div>
      <footer className={styles.footer}>
        {recipient !== null ?
          <span className={styles.info}>
            <SuccessIcon />
            <span>{t("PURCHASE_POINTS.FIND_RECIPIENT.SUCCESS", { points: canReceiveAmount })}</span>
          </span> : <span />
        }
        <Button
          loading={status === "fetching"}
          variant="primary"
          type="submit"
        >
          {t("PURCHASE_POINTS.FIND_RECIPIENT.FIND_TRAVELER")}
        </Button>
      </footer>
    </Form>
  );
};

export default FindRecipient;
