/* @flow */

import type { SearchResult } from "shop-state/types";

import React from "react";
import { Helmet } from "react-helmet-async";
import { search } from "@crossroads/shop-state/search";
import FilterableProductList from "components/FilterableProductList";
import { useData, useSendMessage } from "crustate/react";
import { useTranslate } from "@awardit/react-use-translate";
import { SearchData } from "data";
import ProductListHero from "components/ProductListHero";
import LoadingView from "components/LoadingView";
import { useLocation } from "react-router-dom";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
// import { useBreakpoints } from "helpers/use-breakpoints";
// import BrandList from "../BrandList";
// import CategoryList from "../CategoryList";
import styles from "./styles.scss";

type SearchProps = {
  data: SearchResult,
  query: string,
  updating: boolean,
};

type WrapperProps = {
  query: string,
};

const SearchViewWrapper = ({ query }: WrapperProps) => {
  const search = useData(SearchData);

  if (search.state === "LOADED" || search.state === "UPDATING") {
    return (
      <SearchView
        data={search.data}
        query={query}
        updating={search.state === "UPDATING"}
      />
    );
  }

  return <LoadingView />;
};

const SearchView = ({ data, query, updating }: SearchProps) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const location = useLocation();
  const breadcrumbLinks = useBreadcrumbLinks();

  const load = location => sendMessage(search(query, location, true));

  return (
    <div className={styles.block}>
      <Helmet title={`${t("SEARCH.TITLE_PREFIX")}${query}`} />

      <ProductListHero
        title={t("SEARCH.RESULTS", { query })}
        totalCount={data.totalCount}
        breadcrumbLinks={breadcrumbLinks}
      />

      <FilterableProductList
        updating={updating}
        productList={data}
        load={load}
        breadcrumbLinks={[{
          pathname: location.pathname,
          search: location.search,
          title: t("SEARCH.RESULTS", { query }),
          hint: {
            name: t("SEARCH.RESULTS", { query }),
          },
        }]}
        category={`${t("SEARCH.TITLE_PREFIX")}${query}`}
      />

    </div>
  );
};

export default SearchViewWrapper;
