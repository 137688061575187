/* @flow */

import React from "react";

import useFormat from "helpers/use-format";
import { useTranslate } from "@awardit/react-use-translate";
import { Tooltip } from "@crossroads/ui-components";
import type { Product } from "shop-state/types";

import InfoIcon from "icons/info.svg";

type Props = {
  className?: string,
  accrual: number,
  product: Product,
  loggedIn: boolean,
};

const Accrual = ({
  accrual,
  className,
  product,
  loggedIn,
}: Props) => {
  const { formatPoints } = useFormat();
  const t = useTranslate();

  return (
    <div>
      {accrual > 0 &&
        <div className={className}>
          {!loggedIn && product.type === "configurable" ?
            <span>
              {t("PRODUCT.EARN_UP_TO", { points: formatPoints(accrual) })}
            </span> :
            <span>
              {t("PRODUCT.EARN", { points: formatPoints(accrual) })}
            </span>
          }
          <Tooltip
            direction="bottom"
            title={t("PRODUCT.ACCRUAL_TOOLTIP_TITLE")}
            text={t("PRODUCT.ACCRUAL_TOOLTIP_DESCRIPTION")}
            width={330}
          >
            <InfoIcon />
          </Tooltip>
        </div>
      }
    </div>
  );
};

export default Accrual;
