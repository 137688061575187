/* @flow */

import type {
  ProductSort,
  ProductSortInput,
  ProductFilter,
  ProductFilterBucket,
  ProductFilterBoolean,
  ProductFilterPrice,
  ProductFilterRange,
  FilterLocation as Location,
} from "shop-state/types";

import { useEffect } from "react";
import { useHistory } from "react-router-dom";

import {
  getInputFilters,
  getInputSort,
  getPage,
  getSearchFromFilter,
} from "@crossroads/shop-state/filter";

import usePrevious from "helpers/use-previous";

export const constructSortableValues = (values: Array<ProductSort>, t: string => string) => {
  return values.reduce((acc, curr) => {
    acc.push({
      code: `${curr.code}_asc`,
      label: `${curr.label} - ${t("FILTER.SORT_UP")}`,
    });

    acc.push({
      code: `${curr.code}_desc`,
      label: `${curr.label} - ${t("FILTER.SORT_DOWN")}`,
    });

    return acc;
  }, []);
};

export const sortValueToInput = (value: string): ProductSortInput => {
  const values = value.split("_");
  const order = values.pop();
  const code = values.join("_");

  return {
    code,
    order: order === "desc" ? "DESC" : "ASC",
  };
};

export const getAvailableFilters = (filters: Array<ProductFilter>) => {
  const buckets: Array<ProductFilterBucket> = filters.reduce((acc, curr) => {
    if (curr.__typename === "ProductFilterBucket") {
      return [...acc, {
        ...curr,
        values: curr.values.sort((a, b) => a.value.localeCompare(b.value)),
      }];
    }

    return acc;
  }, []);

  const booleans: Array<ProductFilterBoolean> = filters.reduce((acc, curr) => {
    if (curr.__typename === "ProductFilterBoolean") {
      return [...acc, {
        ...curr,
      }];
    }

    return acc;
  }, []);

  const prices: Array<ProductFilterPrice> = filters.reduce((acc, curr) => {
    if (curr.__typename === "ProductFilterPrice" && curr.code.startsWith("points:")) {
      return [...acc, curr];
    }

    return acc;
  }, []);

  const ranges: Array<ProductFilterRange> = filters.reduce((acc, curr) => {
    if (curr.__typename === "ProductFilterRange") {
      return [...acc, curr];
    }

    return acc;
  }, []);

  return {
    buckets,
    booleans,
    prices,
    ranges,
  };
};

export const locationWithDefaults = (location: Location): Location => {
  const productFilters = getInputFilters(location).reduce((acc, curr) => {
    if (curr.code.startsWith("points:")) {
      const price = {
        ...curr,
        minValue: typeof curr.minValue === "number" ? Math.floor(curr.minValue) : undefined,
        maxValue: typeof curr.maxValue === "number" ? Math.floor(curr.maxValue) : undefined,
      };

      return [...acc, price];
    }

    return [...acc, curr];
  }, []);
  const productSort = getInputSort(location);
  const page = getPage(location);

  return {
    ...location,
    search: getSearchFromFilter(productFilters, productSort, page),
  };
};

export const useUpdateProductList = (load: Location => void) => {
  const { location } = useHistory();
  const prevLocation = usePrevious(location);

  useEffect(() => {
    if (prevLocation && location.search !== prevLocation.search) {
      load(locationWithDefaults(location));
    }
  }, [location, prevLocation]);
};
