/* @flow */

import React from "react";
import cn from "classnames";
import styles from "./styles.scss";

const Box = ({ children, className }: { children: React$Node, className?: string }) =>
  <div className={cn(styles.block, className)}>{children}</div>;

export default Box;
