/* @flow */

type MinimalProductAccrual = {
  +type: string,
  +attributes: { accrual?: number },
  +options?: {
    +items: $ReadOnlyArray<{
      +product: {
        +attributes: {
          +accrual?: number,
        },
      },
    }>,
  },
};

// TODO: handle bundles here in the future
export const getAccrual = <P: MinimalProductAccrual>(product: P) => {
  if (product.options) {
    return Math.max(
      ...(product.options.items || []).map(i => parseFloat(i.product.attributes.accrual) || 0)
    );
  }

  return product.attributes.accrual || 0;
};
