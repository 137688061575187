/* @flow */

import type { History } from "react-router";

import React, { useContext } from "react";
import { Route, Switch } from "react-router";
import Redirect from "@crossroads/react-router-redirect";
import CmsPageView from "components/CmsPageView";
import {
  RouteData, CustomerData, QuoteData, OrderData, HomeData, BrandData, SearchData,
  OrderHistoryData, WishlistData, CmsData, PopularData, AdventCalendarData, AllProductsData,
} from "data";
import { useTranslate } from "@awardit/react-use-translate";
import { PAGE_SIZE } from "effects/route";
import { useData } from "crustate/react";
import App from "components/App";
import LoadingView from "components/LoadingView";
import SearchView from "components/SearchView";
import { ProductListViewWrapper, ProductListView, HintProductListView, AdventCalendarView } from "@crossroads/shop-views";
import ProductView, { HintProductView } from "components/ProductView";
import HomeView from "components/HomeView";
import AccountView from "components/AccountView";
import SuccessView from "components/SuccessView";
import CheckoutView from "components/CheckoutView";
import BuyAndTransferView from "components/BuyAndTransferView";
import NotFoundView from "components/NotFoundView";
import CountrySelection from "components/CountrySelection";
import ProductCard from "components/ProductCard";
import ProductList from "components/ProductList";
import { PAGE_SIZE as ORDER_HISTORY_PAGE_SIZE } from "state/order-history";
import { parseParams } from "helpers/location-search-string";
import { useUi } from "helpers/ui";
import { locationWithDefaults } from "helpers/use-update-product-list";
import { StoreInfoContext } from "entrypoint/shared";
import useAdventCalendarEnabled from "helpers/use-advent-calendar-enabled";
import FilterIcon from "icons/filter_icon.svg";
import SortIcon from "icons/sort_icon.svg";

type RoutesProps = {
  location: Location,
  history: History,
};

type RouteViewProps = {
  location: Location,
  history: {
    replace: ({}) => void,
  },
};

const RouteView = ({ location, history }: RouteViewProps) => {
  const t = useTranslate();
  const data = useData(RouteData);
  const homeData = useData(HomeData);
  const onAdventCalendarCategoryPath = location.pathname.includes(t("ADVENT_CALENDAR.CATEGORY_URL"));
  const { info: { locale } } = useContext(StoreInfoContext);
  const gitCardsRoute = "/gift-cards-vouchers";

  if (data.state === "LOADING") {
    if (data.hint) {
      switch (data.hint.type) {
        case "product":
          return <HintProductView product={data.hint.product} />;
        case "category":
          return (
            <HintProductListView
              title={data.hint.category.name}
              StoreInfoContext={StoreInfoContext}
              loadingText={t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_EMPTY")}
            />
          );
        case "cms_page":
          return <CmsPageView cmsPage={data.hint.cmsPage} />;
        default:
      }
    }

    return <LoadingView />;
  }

  if (!data.route) {
    return <NotFoundView />;
  }

  switch (data.route.type) {
    case "product":
      return <ProductView product={data.route.product} />;
    case "category":
      if (location.pathname === gitCardsRoute && locale === "en_GB") {
        return <CountrySelection locale={locale} />;
      }

      const title = onAdventCalendarCategoryPath &&
      data.route.category.heroHeading !== null &&
      data.route.category.heroHeading !== undefined ?
        data.route.category.heroHeading : data.route.category.name;
      return (
        <ProductListView
          hasFilters
          hasPagination
          hasPoints
          hasTitleParts
          adventCalendarBack
          hasAdventCalendarHero
          hasTotalCount
          hasCategorySubNavigation={false}
          variant="category"
          history={history}
          category={data.route.category}
          home={homeData.state === "LOADED" ? homeData.data : {}}
          productList={data.route.category.products}
          title={title}
          isUpdating={data.state === "UPDATING"}
          pageSize={PAGE_SIZE}
          StoreInfoContext={StoreInfoContext}
          uiContext={useUi}
          currentBreadcrumb={data.route.category.name}
          productsLink={location.pathname}
          sortIcon={<SortIcon style={{ height: "16px" }} />}
          filterIcon={<FilterIcon style={{ height: "20px" }} />}
          ProductCardComponent={ProductCard}
          sortButtonSide="right"
          pointsPriceId="sas_extrapoints"
          loadingText={t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_EMPTY")}
          onAdventCalendarPath={t("ADVENT_CALENDAR.CATEGORY_URL")}
        />
      );
    case "cms_page":
      return <CmsPageView cmsPage={data.route.cmsPage} />;
    case "redirect":
      return <Redirect to={data.route.url} />;
    default:
      return null;
  }
};

const Routes = ({ location, history }: RoutesProps) => {
  const t = useTranslate();
  const customerData = useData(CustomerData);
  const quoteData = useData(QuoteData);
  const adventCalendarEnabled = useAdventCalendarEnabled();

  return (
    <Switch>
      <App location={location} history={history}>
        <Switch>
          <Route
            exact path="/" render={() => (
              <HomeData.Provider>
                <HomeView />
              </HomeData.Provider>
            )} />

          <Route path="/account/buy-and-transfer-points" component={BuyAndTransferView} />

          <Route
            path="/account"
            render={({ history }) => {
              if (customerData.state === "ERROR") {
                history.push("/");
                return null;
              }

              if (!customerData.data || quoteData.data === null || quoteData.state === "LOADING") {
                history.push("/");
                return null;
              }

              return (
                <OrderHistoryData.Provider pageSize={ORDER_HISTORY_PAGE_SIZE} page={1}>
                  <WishlistData.Provider>
                    <Switch>
                      <Route
                        exact
                        path="/account"
                        render={() =>
                          <AccountView customer={customerData.data} quote={quoteData.data} />
                        } />
                      <Route exact path="/account/buy-and-transfer-points" component={BuyAndTransferView} />
                    </Switch>
                  </WishlistData.Provider>
                </OrderHistoryData.Provider>
              );
            }}
          />

          <Route
            path="/checkout/success"
            render={({ history }) => (
              <OrderData.Provider>
                <SuccessView history={history} />
              </OrderData.Provider>
            )} />

          <Route
            exact
            path="/checkout/:step?"
            render={props => (
              <CmsData.Provider url="terms">
                <CheckoutView {...props} />
              </CmsData.Provider>
            )} />

          <Route
            path="/brand/:brand"
            render={({ match, location: { search } }) => {
              const { page } = parseParams(search);

              return (
                <BrandData.Provider
                  location={locationWithDefaults(location)}
                  name={match.params.brand}
                  brand={decodeURIComponent(match.params.brand)}
                  page={page}
                >
                  <ProductListViewWrapper
                    hasPoints
                    hasTitleParts
                    variant="brands"
                    data={BrandData}
                    pageSize={PAGE_SIZE}
                    StoreInfoContext={StoreInfoContext}
                    uiContext={useUi}
                    sortIcon={<SortIcon style={{ height: "16px" }} />}
                    filterIcon={<FilterIcon style={{ height: "20px" }} />}
                    ProductCardComponent={ProductCard}
                    sortButtonSide="right"
                    pointsPriceId="sas_extrapoints"
                    loadingText={t("CATEGORY.PRODUCTS_IN_CATEGORY_TITLE_EMPTY")}
                  />
                </BrandData.Provider>
              );
            }} />

          <Route
            exact path="/popular-products"
            render={({ location: { search } }) => {
              const { page } = parseParams(search);

              return (
                <HomeData.Provider>
                  <PopularData.Provider
                    location={locationWithDefaults(location)}
                    page={page}
                  >
                    <ProductListViewWrapper
                      hasPoints
                      hasTitleParts
                      variant="popular"
                      data={PopularData}
                      pageSize={PAGE_SIZE}
                      StoreInfoContext={StoreInfoContext}
                      uiContext={useUi}
                      title={t("POPULAR.POPULAR_PRODUCTS")}
                      sortIcon={<SortIcon style={{ height: "16px" }} />}
                      filterIcon={<FilterIcon style={{ height: "20px" }} />}
                      ProductCardComponent={ProductCard}
                      sortButtonSide="right"
                      pointsPriceId="sas_extrapoints"
                    />
                  </PopularData.Provider>
                </HomeData.Provider>
              );
            }} />

          <Route
            exact path="/all-products"
            render={({ location: { search } }) => {
              const { page } = parseParams(search);

              return (
                <AllProductsData.Provider
                  location={locationWithDefaults(location)}
                  page={page}
                >
                  <ProductListViewWrapper
                    hasPoints
                    hasTitleParts
                    variant="allproducts"
                    data={AllProductsData}
                    pageSize={PAGE_SIZE}
                    StoreInfoContext={StoreInfoContext}
                    uiContext={useUi}
                    title={t("ALL_PRODUCTS.TITLE")}
                    sortIcon={<SortIcon style={{ height: "16px" }} />}
                    filterIcon={<FilterIcon style={{ height: "20px" }} />}
                    ProductCardComponent={ProductCard}
                    sortButtonSide="right"
                    pointsPriceId="sas_extrapoints"
                    loadingText={t("ALL_PRODUCTS.TITLE_PREFIX_LOADING")}
                  />
                </AllProductsData.Provider>
              );
            }} />

          {adventCalendarEnabled &&
            <Route
              exact
              path="/advent-calendar"
              render={({ location: { search } }) => {
                const { password, testDate } = parseParams(search);
                return (
                  <AdventCalendarData.Provider
                    testDate={typeof testDate === "string" ? testDate : null}
                    password={typeof password === "string" ? password : null}
                  >
                    <HomeData.Provider>
                      <AdventCalendarView
                        calendarData={AdventCalendarData}
                        homeData={HomeData}
                        LoadingView={LoadingView}
                        ProductListComponent={ProductList}
                        buttonVariantToday="secondary"
                        buttonVariantDoors="primary"
                      />
                    </HomeData.Provider>
                  </AdventCalendarData.Provider>
                );
              }} />
          }

          <Route
            path="/search/:query"
            render={({ match, location: { search } }) => {
              const { page } = parseParams(search);

              const query = decodeURIComponent(match.params.query);

              return (
                <SearchData.Provider
                  location={locationWithDefaults(location)}
                  page={page}
                  query={query}
                  name={`search_${match.params.query}`}
                >
                  <SearchView query={query} />
                </SearchData.Provider>
              );
            }}
          />

          <Route
            path="/(.+)"
            render={({ location: { pathname, search, state }, history }) => {
              const { page } = parseParams(search);

              return (
                <RouteData.Provider
                  location={locationWithDefaults(location)}
                  url={pathname}
                  name={`route_${pathname}`}
                  page={page}
                  hint={state && state.hint}
                >
                  <HomeData.Provider>
                    <RouteView location={location} history={history} />
                  </HomeData.Provider>
                </RouteData.Provider>
              );
            }} />
        </Switch>
      </App>
    </Switch>
  );
};

const AppRoutes = () => <Route component={Routes} />;

export default AppRoutes;
