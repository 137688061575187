/* @flow */

import { useContext } from "react";
import { StoreInfoContext } from "entrypoint/shared";

const useAdventCalendarEnabled = () => {
  const { info: { locale } } = useContext(StoreInfoContext);

  return locale !== "en_GB";
};

export default useAdventCalendarEnabled;
