/* @flow */

import type { Step } from "state/transfer";

import React from "react";
import { Link } from "react-router-dom";
import { useData } from "crustate/react";
import { useHistory } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { TransferData } from "data";
import cn from "classnames";
import { stepEnabled } from "state/transfer";
import Logo from "components/AppHeader/Logo";
import Wrapper from "components/Wrapper";
import Button from "components/Button";
import SystemMessages from "components/SystemMessages";
import PhoneIcon from "icons/phone.svg";
import ChevronIcon from "icons/chevron.svg";
import styles from "./styles.scss";

type Props = {
  steps: $ReadOnlyArray<Step>,
  step: Step,
  setStep: Step => void,
  purchaseFor: string,
  className?: string,
};

const BuyAndTransferHeader = ({ className, step, steps, setStep, purchaseFor }: Props) => {
  const t = useTranslate();
  const history = useHistory();
  const transfer = useData(TransferData);

  let prevStep = "";
  let stepIndex = 0;
  for (let i = 0; i < steps.length; i++) {
    if (steps[i] === step) {
      stepIndex = i;
      prevStep = steps[i - 1] || "";
      break;
    }
  }

  return (
    <>
      <SystemMessages />
      <div className={cn(className, styles.block)}>
        <Wrapper>
          <div className={styles.content}>
            <div className={styles.left}>
              <Link className={styles.logo} to="/">
                <Logo />
              </Link>

              <div className={styles.steps__desktop}>
                {steps.map((x, i) => {
                  const enabled = stepEnabled(transfer, x, purchaseFor);

                  return (
                    <span
                      key={x}
                      className={cn(
                        styles.step,
                        { [styles.disabled]: !enabled },
                        { [styles.current]: step === x }
                      )}
                      disabled={enabled}
                      onClick={() => Boolean(enabled) && setStep(x)}
                    >
                      {(i + 1) + ". " + t(`PURCHASE_POINTS.STEP_NAMES.${x}`)}
                    </span>
                  );
                })}
              </div>
            </div>

            <div className={styles.right}>
              <Link to="/customer-service">
                <PhoneIcon />
              </Link>
            </div>
          </div>

          <div className={styles.steps__mobile}>
            <div className={styles.step}>
              <div className={styles.counter}>
                {stepIndex + 1} {t("CART.OF")} {steps.length}
              </div>
              <div className={styles.title}>
                {t(`PURCHASE_POINTS.STEP_NAMES.${step}`)}
              </div>
            </div>

            <Button
              className={styles.back}
              onClick={() => stepIndex < 1 ? history.goBack() : setStep(prevStep)}
            >
              <ChevronIcon />
            </Button>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default BuyAndTransferHeader;
