/* @flow */

import type { ShippingAddressInput, BillingAddressInput } from "shop-state/types";
import type { Validator, ValidationError } from "@awardit/formaggio";

import styles from "./styles.scss";
import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { StoreInfoContext } from "entrypoint/shared";
import { useHistory } from "react-router";
import { useTranslate } from "@awardit/react-use-translate";
import { useSendMessage, useData } from "crustate/react";
import { QuoteData } from "data";
import useCustomer from "helpers/use-customer";
import { getQuoteData } from "state/quote";
import { setAddresses } from "@crossroads/shop-state/quote";
import Address from "components/Address";
import ContactInfo from "components/ContactInfo";
import Button from "components/Button";
import { shouldShowStep2 } from "components/CheckoutView/Step2";
import Container from "components/CheckoutView/Container";
import CustomerServiceLink from "components/CheckoutView/CustomerServiceLink";
import CountryModal from "components/CountryModal";
import CartSummary from "components/CartSummary";
import { focusInvalidField } from "helpers/utils";
import { Form, rules, nestedRule, conditional, isEmail, isPhone, isRequired,
} from "@awardit/formaggio";
import { CheckboxField } from "components/Field";

export type FormDataValue = boolean | string | number | FormData;
export type FormData = { [key: string]: FormDataValue };

export type FormState = {
  billing: BillingAddressInput,
  shipping: ShippingAddressInput,
  email: string,
  shipToSameAddress: boolean,
  checkDiscountCode: string,
};

type Props = {
  open: boolean,
  setOpen: boolean => void,
};

const usePrevious = value => {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
};

export const validateCountry = (f: string): Validator<FormData> =>
  (t: FormData): Array<ValidationError> => {
    if (!(typeof t === "object" && t && (t[f] || t[f] === 0))) {
      return [{ error: "REQUIRED", field: f }];
    }

    return [];
  };

const validationAddress = () => rules([
  isRequired("firstname"),
  isRequired("lastname"),
  isRequired("postcode"),
  isPhone("telephone"),
  isRequired("city"),
  nestedRule("street", rules([
    isRequired("0"),
  ])),
  validateCountry("countryCode"),
]);

const validation = rules([
  isEmail("email"),
  isRequired("billing"),
  nestedRule("billing", validationAddress()),

  conditional(s => Boolean(!s.shipToSameAddress), rules([
    isRequired("shipping"),
    nestedRule("shipping", validationAddress()),
  ])),
]);

const Step1 = ({ open, setOpen }: Props) => {
  const t = useTranslate();
  const history = useHistory();
  const sendMessage = useSendMessage();
  const quoteData = useData(QuoteData);
  const quote = getQuoteData(quoteData);
  const addresses = quote?.addresses || [];
  const quoteBilling = addresses.find(x => x.type === "billing");
  const quoteShipping = addresses.find(x => x.type === "shipping");
  const prevQuoteData = usePrevious(quoteData);
  const { customer } = useCustomer();
  const showStep2 = shouldShowStep2(quote, customer);
  const [countryOpen, setCountryOpen] = useState(false);
  const { info: { countries } } = useContext(StoreInfoContext);
  const customerBilling = customer ?
    customer.addresses.find(a => a.isDefaultBilling) :
    null;
  const customerShipping = customer ?
    customer.addresses.find(a => a.isDefaultShipping) :
    null;

  const validQuoteBillingCountry = useMemo(() => {
    return countries.find(c => c.code === quoteBilling?.country.code);
  }, [countries, quoteBilling]);

  const validCustomerBillingCountry = useMemo(() => {
    return countries.find(c => c.code === customerBilling?.country.code);
  }, [countries, customerBilling]);

  const validQuoteShippingCountry = useMemo(() => {
    return countries.find(c => c.code === quoteShipping?.country.code);
  }, [countries, quoteShipping]);

  const validCustomerShippingCountry = useMemo(() => {
    return countries.find(c => c.code === customerShipping?.country.code);
  }, [countries, customerShipping]);

  const [state, setState] = useState<FormState>(() => {
    if (!validQuoteBillingCountry &&
      !validCustomerBillingCountry &&
      !validCustomerShippingCountry) {
      return {
        email: "",
        telephone: "",
        billing: {
          firstname: "",
          lastname: "",
          city: "",
          countryCode: "",
          postcode: "",
          street: [""],
          telephone: "",
        },
        shipping: {
          firstname: "",
          lastname: "",
          city: "",
          countryCode: "",
          postcode: "",
          street: [""],
          telephone: "",
        },
        shipToSameAddress: true,
        checkDiscountCode: "",
      };
    }

    return {
      email: quote?.email || "",
      telephone: "",
      // TODO: Fix this type-error vs empty object above
      billing: {
        ...(quoteBilling: any),
        countryCode: validQuoteBillingCountry?.code || validCustomerBillingCountry?.code || "",
      },
      shipping: {
        ...(quoteShipping: any),
        countryCode: validQuoteShippingCountry?.code ||
          validCustomerShippingCountry?.code ||
          validQuoteBillingCountry?.code ||
          validCustomerBillingCountry?.code || "",
      },
      shipToSameAddress: quoteBilling?.type === "billing" ? quoteBilling.isUsedAsShipping : false,
      checkDiscountCode: "",
    };
  });

  // TODO: Maybe update formaggio types to be more accepting or become
  //       structured to accept a specific structure
  const errors = validation((state: any));

  // Close summary when this route renders
  useEffect(() => {
    setOpen(false);
  }, [setOpen]);

  // Proceed on submit address
  useEffect(() => {
    if ((prevQuoteData && prevQuoteData.state === "SETTING_ADDRESS") && quoteData.state === "LOADED") {
      history.push(showStep2 ? "/checkout/2" : "/checkout/3");
    }
  }, [prevQuoteData, quoteData, sendMessage]);

  const submit = (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const billing = {
      firstname: state.billing.firstname,
      lastname: state.billing.lastname,
      street: state.billing.street,
      postcode: state.billing.postcode,
      city: state.billing.city,
      countryCode: state.billing.countryCode,
      telephone: state.billing.telephone,
    };

    const shipping = {
      firstname: state.shipping.firstname,
      lastname: state.shipping.lastname,
      street: state.shipping.street,
      postcode: state.shipping.postcode,
      city: state.shipping.city,
      countryCode: state.shipping.countryCode,
      telephone: state.shipping.telephone,
    };

    sendMessage(setAddresses(state.email, billing, shipping, state.shipToSameAddress));
  };

  const onError = (e, errors) => {
    focusInvalidField(e, errors);
  };

  return (
    // TODO: Same as with validation, formaggio needs to be more accepting
    <>
      <Form
        value={(state: any)}
        errors={errors}
        onError={onError}
        onChange={(x: any) => {
          setState({ ...state, ...x });
        }}
        onSubmit={submit}
      >
        <Container
          right={
            <div>
              <CartSummary open={open} setOpen={setOpen}>
                <div className={styles.submitButtonContainer}>
                  <Button
                    className={styles.submitButton}
                    type="submit"
                    variant="primary"
                    loading={quoteData.state === "SETTING_ADDRESS"}
                  >
                    {t("CHECKOUT.TO_PAYMENT")}
                  </Button>
                </div>
              </CartSummary>

              <CustomerServiceLink />
            </div>
          }
        >
          <div className={styles.left}>
            <div className={styles.row}>
              <ContactInfo type="billing" />
              <Address
                type="billing"
                isInvalidCountry={!validCustomerBillingCountry && !validQuoteBillingCountry}
                openCountryModal={() => setCountryOpen(true)}
              />
            </div>

            <div className={styles.checkbox}>
              <CheckboxField className={styles.checkbox} name="shipToSameAddress" checked={state.shipToSameAddress}>
                {t("CHECKOUT.ADDRESS.SHIP_TO_SAME_ADDRESS")}
              </CheckboxField>
            </div>

            {!state.shipToSameAddress &&
              <>
                <h2 className={styles.heading}>{t("CHECKOUT.ADDRESS.SHIPPING")}</h2>
                <div className={styles.row}>
                  <ContactInfo type="shipping" />
                  <Address
                    type="shipping"
                    isInvalidCountry={!validQuoteShippingCountry &&
                      !validCustomerShippingCountry &&
                      !validQuoteBillingCountry &&
                      !validCustomerBillingCountry}
                    openCountryModal={() => setCountryOpen(true)}
                  />
                </div>
              </>
            }
          </div>
        </Container>

      </Form>

      <CountryModal open={countryOpen} setOpen={setCountryOpen} />
    </>
  );
};

export default Step1;
