/* @flow */

import { useData } from "crustate/react";
import { useLocation } from "react-router-dom";
import { useClient } from "entrypoint/shared";
import { CustomerData } from "data";
import { getSASLoginUrl } from "queries";

const useCustomer = () => {
  const customerData = useData(CustomerData);
  const location = useLocation();
  const client = useClient();
  const customer = customerData.data ? customerData.data : null;
  const loggedIn = customer !== null;

  const redirectToLogin = async (setLoading?: boolean => void) => {
    if (setLoading !== undefined && setLoading !== null) {
      setLoading(true);
    }

    // Remove login_error parameter before getSASLoginUrl call
    const params = new URLSearchParams(location.search);
    params.delete("login_error");

    const baseUrl = (location.pathname + params).slice(1);

    const response = await client(getSASLoginUrl, { redirect: baseUrl });

    if (response.getSASLoginUrl !== null && typeof window !== "undefined") {
      window.location.href = response.getSASLoginUrl;
    }
  };

  return {
    redirectToLogin,
    loggedIn,
    customer,
  };
};

export default useCustomer;
