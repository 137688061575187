/* @flow */

import React from "react";
import cn from "classnames";

import { Button } from "@crossroads/ui-components";
import Wrapper from "components/Wrapper";
import CloseIcon from "icons/close-big.svg";
import ChevronIcon from "icons/chevron.svg";

import styles from "./styles.scss";

type Props = {
  className?: string,
  onClose?: () => void,
  onBack?: () => void,
  heading: string,
  children?: React$Node,
};

const MobileNavHeader = ({ heading, children, onClose, onBack, className }: Props) => {
  if (!onBack || !onClose) {
    return null;
  }

  return (
    <>
      <Wrapper className={cn(styles.header, className)}>
        {onBack &&
          <Button className={styles.back} onClick={onBack}>
            <ChevronIcon />
          </Button>
        }
        <span className={styles.heading}>{heading}</span>
        <Button
          className={cn(styles.close, styles.actionButton)}
          onClick={onClose}
        >
          <CloseIcon />
        </Button>
      </Wrapper>

      {children}
    </>
  );
};

export default MobileNavHeader;
