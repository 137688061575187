/* @flow */

import type { TransferProduct, Recipient } from "state/transfer";

import React, { useState, useContext, useEffect } from "react";
import { useSendMessage, useData } from "crustate/react";
import { useHistory } from "react-router-dom";
import useFormat from "helpers/use-format";
import cn from "classnames";
import styles from "./styles.scss";
import { useTranslate } from "@awardit/react-use-translate";
import { syncCustomer } from "@crossroads/shop-state/customer";
import { StripeContext } from "@crossroads/stripe";
import { addMessage, addMessageTranslated } from "@crossroads/shop-state/messages";
import { logout } from "state/customer";
import { resetOTP } from "state/otp";
import { useClient, useTransferClient } from "entrypoint/shared";
import useCustomer from "helpers/use-customer";
import { OTPData, TransferData } from "data";
// Import useFormat from "helpers/use-format";
import Button from "components/Button";
import Box from "../../Box";
import LoadingView from "components/LoadingView";
import OTPModal from "components/OTPModal";
import { customer as customerQuery, placeTransferOrder, altCurrency as altCurrencyQuery } from "queries";
import PaymentMethods from "components/PaymentMethods";
import { Form, rules, isRequired } from "@awardit/formaggio";

type Props = {
  pointsProduct: TransferProduct,
  validationErrors: Array<string>,
};

const validation = rules([
  isRequired("terms"),
]);

const Traveler = ({ recipient }: { recipient: ?Recipient }) => {
  const t = useTranslate();

  return (
    <section className={cn(styles.recipient, styles.section)}>
      <h3 className={styles.heading}>{t("PURCHASE_POINTS.SUCCESS.TRAVELER")}</h3>
      <div className={styles.tr}>
        <span>{t("PURCHASE_POINTS.SUCCESS.EUROBONUS_NUMBER")}</span>
        <span>{recipient?.ebNo}</span>
      </div>
      <div className={styles.tr}>
        <span>{t("PURCHASE_POINTS.SUCCESS.LAST_NAME")}</span>
        <span>{recipient?.lastName}</span>
      </div>
    </section>
  );
};

const ToPay = ({ points, cash, altCurrency }: {
    points: number,
    cash: number,
    altCurrency: ?{
      code: string,
      rate: number,
    },
  }) => {
  const t = useTranslate();
  const { formatPoints, formatPriceLong } = useFormat();

  return (
    <section className={styles.section}>
      <h3 className={cn(styles.heading, styles.tr)}>
        <span>{t("PURCHASE_POINTS.PAYMENT_BOX.TO_PAY")}</span>
        {(cash === 0 && points > 0) &&
          <span>{formatPoints(points)}</span>
        }
        {(cash > 0 && points === 0) &&
          <span>{formatPriceLong(cash)}{altCurrency ? ` (${formatPriceLong(altCurrency.rate * cash, altCurrency.code)})` : null}</span>
        }
        {(cash > 0 && points > 0) &&
          <span>{formatPoints(points)}
            <span className={styles.cash}>+ {formatPriceLong(cash)}{altCurrency ? ` (${formatPriceLong(altCurrency.rate * cash, altCurrency.code)})` : null}</span>
          </span>
        }
      </h3>
    </section>
  );
};

const Summary = (
  {
    depositType,
    pointsPurchased,
  }: {
    depositType: "Basic Points" | "Extra Points",
    pointsPurchased: number,
  }
) => {
  const t = useTranslate();
  const { formatPoints, formatBasicPoints } = useFormat();
  const pointsFormat = depositType === "Basic Points" ? formatBasicPoints : formatPoints;

  return (
    <section className={styles.section}>
      <h3 className={styles.heading}>{t("PURCHASE_POINTS.PAYMENT_BOX.SUMMARY")}</h3>
      <div className={styles.tr}>
        <span>{t("PURCHASE_POINTS.PAYMENT_BOX.GIFTED_POINTS")}</span>
        <span>{pointsFormat(pointsPurchased)}</span>
      </div>
    </section>
  );
};

const TransactionFee = ({ points, cash }: { points: number, cash: number }) => {
  const t = useTranslate();
  const { formatPoints, formatPriceLong } = useFormat();

  return (
    <section className={styles.section}>
      <h3 className={styles.heading}>{t("PURCHASE_POINTS.PAYMENT_BOX.TRANSACTION_FEE")}</h3>
      <div className={styles.tr}>
        <span>{t("PURCHASE_POINTS.SUCCESS.CASH")}</span>
        <span>{formatPriceLong(cash)}</span>
      </div>
      <div className={styles.tr}>
        <span>{t("PURCHASE_POINTS.SUCCESS.POINTS")}</span>
        <span>{formatPoints(points)}</span>
      </div>
    </section>
  );
};

/* eslint-disable complexity */
const PaymentBox = ({ pointsProduct, validationErrors }: Props) => {
  const t = useTranslate();
  const sendMessage = useSendMessage();
  const { push } = useHistory();
  const {
    stripe, stripePaymentReq, paymentMode, setPaymentMode, payWithStripe, setEmail,
  } = useContext(StripeContext);
  const OTPState = useData(OTPData);
  const transfer = useData(TransferData);
  const [state, setState] = useState({ terms: false });
  const [OTPModalOpen, setOTPModalOpen] = useState(false);

  const client = useClient();
  const transferClient = useTransferClient();
  // Const [disabled, setDisabled] = useState(false);
  // const [loading, setLoading] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [altCurrency, setAltCurrency] = useState(null);
  const errors = validation((state: any));
  const { customer, redirectToLogin } = useCustomer();

  useEffect(() => {
    transferClient(altCurrencyQuery)
      .then(response => response && setAltCurrency(response.altCurrency));

    if (customer?.email) {
      setEmail(customer.email);
    }
  }, [customer]);

  if (transfer.state === "INIT" || transfer.state === "LOADING" || !transfer.data.selectedPointPayment) {
    return <LoadingView />;
  }

  const { item, selectedPointPayment } = transfer.data;

  const showOTPModal = transfer.data && (
    selectedPointPayment.points.selected.incVat > 0 ||
    (item?.product.sasPointsToWithdraw || 0) > 0
  );

  const points = (transfer.data.item?.qty || 0) * (pointsProduct.sasPointsToDeposit || 100);

  const submit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    setProcessing(true);

    try {
      if (transfer.data.payment?.code !== "free") {
        await payWithStripe();
      }

      const placeOrderData = await transferClient(placeTransferOrder);

      if (placeOrderData && placeOrderData.placeOrder.result !== "success") {
        sendMessage(addMessage(placeOrderData.placeOrder.result, "error"));

        // Sync quote and return
        // if (newQuote) {
        // sendMessage(syncQuote(newQuote));
        // }

        return;
      }

      // Sync new customer data to get the new point balance etc
      await client(customerQuery).then(({ customer }) => {
        sendMessage(syncCustomer(customer));
      });

      /*
      // Sync customer to get the new point balance
      sendMessage(syncQuote(newQuote));

      if (newQuote) {
        sendMessage(({
          tag: QUOTE_PLACE_ORDER_RESPONSE,
          data: newQuote,
        }: QuoteResponse));
      }
      */

      push("/account/buy-and-transfer-points/success");
    }
    catch (e_) {
      sendMessage(addMessageTranslated(e_.message, "error"));
      const error = e_.errors[0];

      if (error) {
        if (error.extensions.category === "sas") {
          switch (error.extensions.code) {
            case "invalid_otp":
              sendMessage(resetOTP());
              setOTPModalOpen(true);
              break;
            case "invalid_sso_ticket":
              sendMessage(logout("/"));
              redirectToLogin();
              break;
            default:
              break;
          }
        }
      }
    }
    finally {
      setProcessing(false);
    }
  };

  return (
    <Box className={styles.block}>
      <Form
        className={styles.body}
        value={(state: any)}
        errors={errors}
        onSubmit={submit}
        onChange={x => {
          setState({ ...state, ...(x: any) });
        }}
      >
        {paymentMode === "CARD" &&
          <div className={styles.header}>
            <h3 className={styles.heading}>{t("CHECKOUT.CASH")}</h3>
            <span
              className="link"
              onClick={() => setPaymentMode("DEFAULT")}
            >
              {t("CHECKOUT.EDIT")}
            </span>
          </div>
        }

        {(transfer.data.targetSasCustomer !== null && paymentMode !== "CARD") &&
          <Traveler recipient={transfer.recipient} />
        }

        {(typeof points === "number" && points > 0 && paymentMode !== "CARD" && transfer.data.item) &&
          <Summary
            depositType={pointsProduct.sasPointsToDepositType}
            pointsPurchased={transfer.data.item.product.sasPointsToDeposit * transfer.data.item.qty}
          />
        }

        {selectedPointPayment.currency.min.incVat > 0 &&
          <TransactionFee
            points={selectedPointPayment.points.selected.incVat}
            cash={selectedPointPayment.currency.min.incVat}
          />
        }

        <ToPay
          points={selectedPointPayment.points.selected.incVat || 0}
          cash={selectedPointPayment.currency.remaining.incVat}
          altCurrency={altCurrency}
        />

        {(showOTPModal && (OTPState.state === "PENDING" || (OTPState.state !== "SET" && OTPState.state !== "SETTING"))) &&
          <section className={styles.section}>
            {OTPState.state === "PENDING" &&
              <div className={styles.otp}>
                <Button
                  loading={transfer.state !== "LOADED"}
                  variant="primary"
                  onClick={e => {
                    e.preventDefault();
                    setOTPModalOpen(true);
                  }}
                >
                  {t("CHECKOUT.CHOOSE_PAYMENT_METHOD")}
                </Button>
              </div>
            }

            {OTPState.state !== "SET" && OTPState.state !== "SETTING" &&
              <OTPModal open={OTPModalOpen} setOpen={setOTPModalOpen} />
            }
          </section>
        }

        {(!showOTPModal || OTPState.state === "SET") &&
          <section className={cn(styles.section, styles.stripe)}>
            <PaymentMethods
              className={styles.paymentMethods}
              terms={state.terms}
              disabled={validationErrors.length > 0}
              paymentMethodCode={transfer.data.payment?.code || ""}
              loading={!stripe || !stripePaymentReq}
              processing={processing || transfer.state !== "LOADED"}
              showHeader={false}
              showAmexLogo={false} />
          </section>
        }
      </Form>
    </Box>
  );
};
/* eslint-enable complexity */

export default PaymentBox;
