/* @flow */

import type { Language } from "components/LanguageSwitcher";

import React, { useEffect, useState } from "react";
import Wrapper from "components/Wrapper";
import LanguageSwitcher, { languages } from "components/LanguageSwitcher";
import { useTranslate } from "@awardit/react-use-translate";
import styles from "./styles.scss";

type Props = {
  locale: string,
};

const CountrySelection = ({ locale }: Props) => {
  const language = languages.find(x => x.lang === locale.replace(/_/, "-"));
  const [currentLang, setCurrentLang] = useState<Language>(language || languages.find(x => x.code === "en") || languages[0]);
  const t = useTranslate();

  useEffect(() => {
    if (language && language !== currentLang) {
      setCurrentLang(language);
    }
  }, [locale]);

  const setLang = (lang: Language) => {
    setCurrentLang(lang);
  };

  return (
    <Wrapper>
      <div className={styles.block}>
        <h2>{t("GIFT_CARDS.HEADING")}</h2>
        <p>{t("GIFT_CARDS.DESCRIPTION")}</p>
        <LanguageSwitcher
          className={styles.languageSwitcher}
          currentLang={currentLang}
          setCurrentLang={setLang}
        />
      </div>
    </Wrapper>
  );
};

export default CountrySelection;
