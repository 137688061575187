/* @flow */

import type { Product, ProductCardProduct } from "shop-state/types";
import type { Option } from "@crossroads/ui-components";

import { useState, useEffect } from "react";
import { useData, useSendMessage } from "crustate/react";
import { addMessage } from "@crossroads/shop-state/messages";
import { addToWishlist, removeFromWishlist } from "state/wishlist-toggle";
import { WishlistToggleData } from "data";

type Props = {
  product: Product | ProductCardProduct,
  selected?: Option,
};

const getBuyRequest = (selected: Option) => {
  const keys = Object.keys(selected);

  for (const key of keys) {
    if (selected[key]) {
      return selected[key];
    }
  }

  return "";
};

const useWishlist = ({ product, selected }: Props) => {
  const wishlistData = useData(WishlistToggleData);
  const cache = wishlistData.data;
  const loading = wishlistData.state === "LOADING";
  const sendMessage = useSendMessage();
  const [inWishlist, setInWishlist] = useState(product.wishlist.selected);

  useEffect(() => {
    if (cache[product.sku]) {
      setInWishlist(true);
    }
    else if (cache[product.sku] === null) {
      setInWishlist(false);
    }
  }, []);

  const toggleWishlist = () => {
    if (loading) {
      return null;
    }

    // Is the product in the wishlist?, if so remove it
    if (inWishlist) {
      if (product.type === "configurable") {
        setInWishlist(false);
        sendMessage(removeFromWishlist(
          cache && cache[product.sku] ?
            cache[product.sku].itemId :
            product.wishlist.itemId,
          product.sku
        ));
      }
      else {
        setInWishlist(false);
        sendMessage(removeFromWishlist(
          cache && cache[product.sku] ?
            cache[product.sku].itemId :
            product.wishlist.itemId,
          product.sku
        ));
      }
    }
    else if (product.type === "configurable") {
      if (!selected || (selected && Object.keys(selected).length === 0)) {
        sendMessage(addMessage("WISHLIST_VARIANT", "error"));
        return null;
      }

      const buyRequest = getBuyRequest(selected);

      if (buyRequest) {
        setInWishlist(true);
        sendMessage(addToWishlist(buyRequest, product.sku));
      }
    }
    else if (product.buyRequest) {
      setInWishlist(true);
      sendMessage(addToWishlist(product.buyRequest, product.sku));
    }
  };

  return { toggleWishlist, inWishlist };
};

export default useWishlist;
