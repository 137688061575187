/* @flow */

import type { Product, ProductCardProduct, ProductCategory } from "shop-state/types";
import type { Option, BreadcrumbLink } from "@crossroads/ui-components";

import React, { useState, useEffect, useContext, useMemo } from "react";
import { useData } from "crustate/react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet-async";
import { AnalyticsContext } from "@crossroads/analytics";
import { useTranslate } from "@awardit/react-use-translate";
import { useGetProductMeta } from "helpers/get-meta";
import useCustomer from "helpers/use-customer";
import useWishlist from "helpers/use-wishlist";
import { QuoteData } from "data";
import styles from "./styles.scss";
import { Link } from "react-router-dom";
import Wrapper from "components/Wrapper";
import ProductList from "components/ProductList";
import Breadcrumbs from "components/Breadcrumbs";
import useBreadcrumbLinks from "helpers/use-breadcrumb-links";
import {
  ProductViewMedia,
  Button,
  AutoExpand,
  Foldable,
  AddToCart,
  productDefaults,
  inStock,
  getAttributesConfigurable,
  getSelectedConfigurable,
  getPrice,
} from "@crossroads/ui-components";
import { ColorSelect } from "components/ProductOptions";
import { getSelectedPointsPrice } from "./get-price";
import PriceSplit from "components/PriceSplit";
import Accrual from "components/Price/accrual";
import { pointsPriceByID, isPointsOnly } from "helpers/points";
import cn from "classnames";
import WishlistIcon from "components/WishlistIcon";
import ChevronIcon from "icons/chevron.svg";
import CloseIcon from "icons/close2.svg";
import { getAccrual } from "helpers/accrual";

type ProductViewProps = {
  product: Product,
  location: {
    state: ?{
      breadcrumbLink?: BreadcrumbLink,
      list?: string,
      position?: number,
    },
  },
};

type HintProductViewProps = {
  product: ProductCardProduct,
  location: {
    state: ?{
      breadcrumbLink?: BreadcrumbLink,
      image?: { x1: string, x2: string },
    },
  },
};

const CustomCloseIcon = () => {
  return <CloseIcon className={styles.customCloseIcon} />;
};

const Section = ({ title, children }: {
  title: string,
  children: React$Node,
}) => {
  const [open, setOpen] = useState(true);

  return (
    <section className={cn(styles.section, { [styles.section__open]: open })}>
      <header className={styles.sectionHeader} onClick={() => setOpen(!open)}>
        <h2 className={styles.descriptionHeading}>{title}</h2>
        <ChevronIcon />
      </header>
      <Foldable open={open}>
        <div className={styles.sectionBody}>
          {children}
        </div>
      </Foldable>
    </section>
  );
};

const AddToCartBtn = ({
  loading = false, outOfStock,
}: { loading?: boolean, outOfStock: boolean,
}) => {
  const t = useTranslate();
  const quoteState = useData(QuoteData);
  const addingToCart = quoteState.state === "ADDING_ITEM";

  return (
    <Button
      className={styles.addToCartButton}
      variant="primary"
      loading={addingToCart || loading}
      disabled={outOfStock}
      type="submit"
    >
      {outOfStock ? t("PRODUCT.OUT_OF_STOCK") : t("PRODUCT.ADD_TO_CART")}
    </Button>
  );
};

const mapProductCategories = (categories: $ReadOnlyArray<ProductCategory>) => {
  const structuredCategories: Array<{
      name: string,
      url: string,
      children: Array<{
        name: string,
        url: string,
      }>,
  }> = [];

  for (const category of categories) {
    if (category.parent) {
      let parent = structuredCategories.find(c => c.url === category.parent?.url);

      if (parent) {
        parent.children.push({
          name: category.name,
          url: category.url,
        });
      }
      else {
        parent = {
          name: category.parent?.name || "",
          url: category.parent?.url || "",
          children: [{
            name: category.name,
            url: category.url,
          }],
        };

        structuredCategories.push(parent);
      }
    }
    else {
      structuredCategories.push({
        name: category.name,
        url: category.url,
        children: [],
      });
    }
  }

  return structuredCategories;
};

// eslint-disable-next-line complexity
const ProductView = ({ product, location }: ProductViewProps) => {
  const gaContext = useContext(AnalyticsContext);
  const configAttributes = product.type === "configurable" ? getAttributesConfigurable(product) : {};
  const [selected, setSelected] = useState<Option>(productDefaults(product));
  const selectedItem = getSelectedConfigurable(selected, configAttributes);
  const { loggedIn, customer, redirectToLogin } = useCustomer();
  const { toggleWishlist, inWishlist } = useWishlist({ product, selected });
  const brand = product.attributes.manufacturer;
  const { manufacturer } = product.attrDescriptions;
  const t = useTranslate();
  const meta = useGetProductMeta(product, t);
  const outOfStock = !inStock(product, selected);
  const { smallImage, largeImage } = product.attributes;
  const { experienceUrl } = product.attributes;
  const categories = useMemo(() => mapProductCategories(product.categories), [product]);
  const breadcrumbLinks = useBreadcrumbLinks({ fallbackCategories: categories });
  const pointsOnly = isPointsOnly(pointsPriceByID(product.pointsPrices, "sas_extrapoints"));
  const brandTitle = (product.attributeSetName === "ExternalExperience" || product.attributeSetName === "Experience") ?
    t("PRODUCT.EXTERNAL_EXPERIENCE") :
    product.attributes.manufacturer;
  const brandURL = (product.attributeSetName === "ExternalExperience" || product.attributeSetName === "Experience") ?
    t("PRODUCT.EXTERNAL_EXPERIENCE_URL") :
    "/brand/" + encodeURIComponent(brand);

  const [currentImage, setCurrentImage] = useState({
    smallImage: smallImage ? { x1: smallImage.x1, x2: smallImage.x2 } : null,
    largeImage: largeImage ? { x1: largeImage.x1, x2: largeImage.x2 } : null,
  });

  const { pointsPrice, accrual } = getSelectedPointsPrice(product, selected);

  useEffect(() => {
    gaContext.registerProductDetailsView({
      sku: product.sku,
      name: product.name,
      price: product.price,
      qty: 1,
      attributes: {
        manufacturer: product.attributes.manufacturer,
      },
      categories: product.categories,
    },
    product.price.incVat,
    location.state?.list,
    location.state?.position
    );
  }, []);

  const onAdd = p => {
    if (selectedItem) {
      const incVat = getPrice(product, selected, "incVat");
      const exVat = getPrice(product, selected, "exVat");

      gaContext.registerModifyCart({
        ...selectedItem.product,
        sku: product.sku,
        qty: 1,
        price: { incVat, exVat, vat: incVat - exVat },
        categories: p.categories,
      }, "add_to_cart", incVat);
    }
    else {
      gaContext.registerModifyCart({
        ...p,
        qty: 1,
      }, "add_to_cart", p.price.incVat);
    }
  };

  // Change image when selecting option
  useEffect(() => {
    if (selectedItem) {
      const a = selectedItem.product.attributes;

      if (a) {
        setCurrentImage({
          smallImage: a.smallImage ? { x1: a.smallImage.x1, x2: a.smallImage.x2 } : null,
          largeImage: a.largeImage ? { x1: a.largeImage.x1, x2: a.largeImage.x2 } : null,
        });
      }
    }
  }, [selected]);

  useEffect(() => {
    setCurrentImage({
      smallImage,
      largeImage,
    });
  }, [smallImage, largeImage]);

  return (
    <Wrapper>
      <Helmet
        title={meta.title}
        meta={meta.data}
        link={meta.link}
      />

      <Breadcrumbs
        className={styles.breadcrumbs}
        links={breadcrumbLinks}
        current={product.name}
      />

      <header className={styles.header__outer}>
        <div className={styles.top}>
          <Link
            to={{
              pathname: brandURL,
              state: { hint: { type: "category", category: { name: brandTitle } } },
            }}
            className={styles.brand}
          >
            {brandTitle}
          </Link>
          {loggedIn && <WishlistIcon
            className={styles.wishlistIcon}
            inWishlist={inWishlist}
            onClick={() => toggleWishlist()} />}
        </div>
        <h1 className={styles.name}>{product.name}</h1>

        {pointsOnly &&
          <div className={styles.pointsOnly}>
            <span />
            <span>{t("PRODUCT.POINTS_ONLY")}</span>
          </div>
        }
      </header>
      <div className={styles.split}>
        <div className={styles.right}>
          <header className={styles.header__inner}>
            <div className={styles.top}>
              <Link
                to={{
                  pathname: brandURL,
                  state: { hint: { type: "category", category: { name: brandTitle } } },
                }}
                className={styles.brand}
              >
                {brandTitle}
              </Link>
              {loggedIn && <WishlistIcon
                className={styles.wishlistIcon}
                inWishlist={inWishlist}
                onClick={() => toggleWishlist()} />}
            </div>
            <h1 className={styles.name}>{product.name}</h1>
            {pointsOnly &&
              <div className={styles.pointsOnly}>
                <span />
                <span>{t("PRODUCT.POINTS_ONLY")}</span>
              </div>
            }
          </header>
          <section className={styles.addToCart}>

            <div className={styles.priceContainer}>
              <PriceSplit className={styles.price} pointsPrice={pointsPrice} />
              {!loggedIn && product.type === "configurable" ?
                <Accrual
                  loggedIn={loggedIn}
                  product={product}
                  className={styles.accrual}
                  accrual={getAccrual(product)}
                /> :
                <Accrual
                  loggedIn={loggedIn}
                  product={product}
                  className={styles.accrual}
                  accrual={accrual}
                />
              }
            </div>

            {loggedIn && pointsOnly &&
              <div className={styles.gutter} />
            }

            {loggedIn ?
              <>
                {experienceUrl &&
                  <a href={experienceUrl} target="_blank" rel="noopener noreferrer">
                    <Button
                      className={styles.login}
                      variant="primary"
                    >
                      {t("PRODUCT.EXPERIENCE_URL_BUTTON")}
                    </Button>
                  </a>
                }
                {!experienceUrl && customer?.sasRedemptionEnabled &&
                  <AddToCart
                    product={product}
                    selected={selected}
                    setSelected={setSelected}
                    qty={1}
                    templates={{
                      color: ColorSelect,
                    }}
                    onAdd={onAdd}
                  >
                    <AddToCartBtn outOfStock={outOfStock} />
                  </AddToCart>
                }
                {!experienceUrl && !customer?.sasRedemptionEnabled &&
                  <p className={styles.redemptionDisabled}>{t("PRODUCT.REDEMPTION_DISABLED")}</p>
                }
              </> : (
                <Button
                  variant="primary"
                  className={styles.login}
                  onClick={() => redirectToLogin()}
                >
                  {t("PRODUCT.LOGIN_TO_PURCHASE")}
                </Button>
              )}
          </section>

          <Section title={t("PRODUCT.DESCRIPTION")}>
            <div
              dangerouslySetInnerHTML={{ __html: product.attributes.description }}
              className={styles.description}
            />
          </Section>

          {manufacturer && manufacturer.icon && manufacturer.description &&
            <Section title={t("PRODUCT.ABOUT_BRAND", { brand: manufacturer.title })}>
              <div className={styles.manufacturer}>
                <Link
                  to={{
                    pathname: `/brand/${encodeURIComponent(brand)}`,
                    state: { hint: { category: { name: brand } } },
                  }}
                  className={styles.logo}
                >
                  <img
                    src={manufacturer ? manufacturer.icon : ""}
                    alt={`Logo ${brand}`}
                  />
                </Link>
                <p>{manufacturer ? manufacturer.description : ""}</p>
                <Link
                  to={{
                    pathname: `/brand/${encodeURIComponent(brand)}`,
                    state: { hint: { category: { name: brand } } },
                  }}
                  className={styles.goto}
                >
                  {t("PRODUCT.GO_TO_MANUFACTURER", { brand: manufacturer.title })}
                </Link>
              </div>
            </Section>
          }
        </div>

        <div className={styles.left}>
          <ProductViewMedia
            CloseIcon={CustomCloseIcon}
            alt={product.name}
            currentImage={currentImage}
            gallery={product.gallery}
            location={{ ...location, state: location.state ? location.state : {} }}
            galleryLocation="bottom"
          />
        </div>
      </div>

      <AutoExpand>
        &nbsp;
        <div className={styles.lists}>
          <div className={styles.relatedList}>
            {product.relatedProducts.items.length > 0 &&
              <ProductList
                heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
                products={product.relatedProducts.items}
                productsPerRow={2}
              />
            }
          </div>

          <div className={styles.historyList}>
            {product.crossSellProducts.items.length > 0 &&
              <ProductList
                heading={t("PRODUCT.OTHERS_ALSO_LIKED")}
                products={product.crossSellProducts.items}
                productsPerRow={2}
              />
            }
          </div>
        </div>
      </AutoExpand>
    </Wrapper>
  );
};

export const Hint = ({ product, location }: HintProductViewProps) => {
  const t = useTranslate();
  const brand = product.attributes.manufacturer;
  const image = product.attributes.largeImage ||
  product.attributes.smallImage ||
  location.state?.image || null;
  const breadcrumbLinks = useBreadcrumbLinks();
  const brandTitle = product.attributeSetName === "ExternalExperience" ?
    t("PRODUCT.EXTERNAL_EXPERIENCE") :
    product.attributes.manufacturer;
  const brandURL = product.attributeSetName === "ExternalExperience" ?
    t("PRODUCT.EXTERNAL_EXPERIENCE_URL") :
    "/brand/" + encodeURIComponent(brand);

  return (
    <Wrapper className={styles.hintWrapper}>
      <Breadcrumbs
        className={styles.breadcrumbs}
        links={breadcrumbLinks}
        current={product.name}
      />

      <header className={styles.header__outer}>
        <Link
          to={{
            pathname: brandURL,
            state: { hint: { category: { name: brandTitle } } },
          }}
          className={styles.brand}
        >
          {brandTitle}
        </Link>
        <h1 className={styles.name}>{product.name}</h1>
      </header>

      <div className={styles.split}>

        <div className={styles.right}>
          <header className={styles.header__inner}>
            <Link
              to={{
                pathname: brandURL,
                state: { hint: { category: { name: brandTitle } } },
              }}
              className={styles.brand}
            >
              {brandTitle}
            </Link>
            <h1 className={styles.name}>{product.name}</h1>
          </header>

          <div className={styles.dummyContent}>
            <div className={styles.top} />
            <div className={styles.middle} />
            <div className={styles.bottom} />
          </div>
        </div>

        <div className={styles.left}>
          <ProductViewMedia
            alt={product.name}
            currentImage={{
              largeImage: image ? image : { x1: "", x2: "" },
              smallImage: image ? image : { x1: "", x2: "" },
            }}
            gallery={[]}
            location={{ ...location, state: location.state ? location.state : {} }}
            galleryLocation="bottom"
          />
        </div>
      </div>
    </Wrapper>
  );
};

export const HintProductView = withRouter(Hint);

export default withRouter(ProductView);
