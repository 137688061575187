/* @flow */

import React, { useState } from "react";
import cn from "classnames";
import styles from "./styles.scss";

import HeartFilledAnimateIcon from "icons/heart_filled_animate.svg";
import HeartFilledIcon from "icons/heart_filled.svg";
import HeartEmptyIcon from "icons/heart.svg";

type Props = {
  className?: string,
  size?: string,
  inWishlist: boolean,
  onClick?: (value: boolean) => void | null | typeof undefined,
};

const WishlistIcon = ({ className, size, inWishlist, onClick }: Props) => {
  const [animate, setAnimate] = useState<boolean>(false);

  const toggleWishlist = event => {
    if (typeof onClick === "function") {
      event.preventDefault();

      if (inWishlist) {
        setAnimate(false);
        onClick(false);
      }
      else {
        setAnimate(true);
        onClick(true);
      }
    }
  };

  return (
    <div
      className={cn(
        styles.block,
        className,
        { [styles.small]: size === "small" }
      )}
      onClick={toggleWishlist}
    >
      {inWishlist && animate &&
        <HeartFilledAnimateIcon />
      }
      {inWishlist && !animate &&
        <HeartFilledIcon />
      }
      {!inWishlist &&
        <HeartEmptyIcon />
      }
    </div>
  );
};

export default WishlistIcon;
