/* @flow */

import React from "react";
import cn from "classnames";
import { useTranslate } from "@awardit/react-use-translate";
import { Foldable } from "@crossroads/ui-components";

import styles from "./styles.scss";

type Props = {
  title: string,
  subTitle?: string | React$Node,
  open: boolean,
  setOpen: () => void,
  enabled: boolean,
  TooltipSlot?: React$Node,
  children: React$Node,
};

const Step = ({ enabled = true, open, setOpen, title, subTitle, TooltipSlot, children }: Props) => {
  const t = useTranslate();

  return (
    <>
      <header className={styles.header}>
        <h3 className={styles.title}>{title}</h3>
        {(open && TooltipSlot !== undefined) && (
          <div className={styles.tooltip}>
            {TooltipSlot}
          </div>
        )}
        {!open &&
          <span
            className={cn({ [styles.disabled]: !enabled }, styles.edit)}
            onClick={() => enabled && setOpen()}
          >
            {t("PURCHASE_POINTS.EDIT")}
          </span>
        }
      </header>

      {(open && subTitle !== null) &&
        <div className={styles.userTierInfo}>
          {typeof subTitle === "string" && <p className={styles.subTitle}>{subTitle}</p>}
          {typeof subTitle !== "string" && subTitle}
        </div>
      }

      <Foldable open={open}>
        <div className={styles.body}>
          {children}
        </div>
      </Foldable>
    </>
  );
};

export default Step;
